import React from 'react';
import { Button, Container, styled, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import MainAppBar from '../../../components/common/MainAppBar';
import GuestDrawer from '../../../components/common/Drawer/GuestDrawer';
import { EMAIL } from '../../../const/constFooter';

const GuestMainPageContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(0),
  backgroundColor: 'rgba(250, 242, 242, 0.151)',
}));

function Contact(): JSX.Element {
  const formik = useFormik({
    initialValues: {
      email: '',
      telephone: '',
      comment: '',
    },
    onSubmit: (values) => {
      const subject = 'Formularz kontaktowy';
      const body = `${values.comment} \n Nr tel: ${values.telephone}`;
      window.open(`mailto:${EMAIL}?subject=${subject}&body=${body}`);
    },
  });

  return (
    <>
      <MainAppBar>
        <GuestDrawer />
      </MainAppBar>

      <GuestMainPageContainer data-testid="GuestMainPage__container">
        <Container sx={{ paddingTop: '16px', paddingBottom: '16px' }}>
          <Typography variant="h2" component="h1">
            Kontakt
          </Typography>
        </Container>
        <Container>
          <form onSubmit={ formik.handleSubmit }>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="E-Mail"
              type="email"
              required
              value={ formik.values.email }
              onChange={ formik.handleChange }
              sx={{ marginBottom: '12px' }}
            />
            <TextField
              fullWidth
              id="telephone"
              type="tel"
              name="telephone"
              label="Nr Telefonu"
              value={ formik.values.telephone }
              onChange={ formik.handleChange }
              sx={{ marginBottom: '12px' }}
            />
            <TextField
              fullWidth
              id="comment"
              name="comment"
              label="Komentarz"
              type="text"
              multiline
              rows={5}
              value={ formik.values.comment }
              onChange={ formik.handleChange }
              sx={{ marginBottom: '12px' }}
            />
            <Button
              color="primary"
              variant="contained"
              type="submit"
            >
              Wyślij
            </Button>
          </form>
        </Container>
      </GuestMainPageContainer>
    </>
  );
}

export default Contact;
