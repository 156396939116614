import { routes } from './routes';
import { getResources } from './axios';

interface Account {
  id: number,
  email: string,
  phone: string,
  // user modules
  employee: string,
  restaurants: number[],
  companies: number[]
}

export const getAccounts = getResources<Account>(routes.ACCOUNTS);

const requestRepositoryAccount = {
  getAccounts,
};

export type { Account };
export default requestRepositoryAccount;
