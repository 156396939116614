import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

import DishCard from '../../../components/common/DishCard';
import SearchInput from '../../../components/common/SearchInput';
import { RoutesVars } from '../../../const/constRoutes';
import { Dish } from '../../../interfaces';
import MainContainer from '../../../components/common/MainContainer';
import { getStatus } from '../../../selectors';
import useTags from '../../../hooks/useTags';
import { QueryKeys } from '../../../api/services/api/queryKeys';
import { deleteRestaurantDishesNEW, getRestaurantDishesNEW, updateRestaurantDishNEW } from '../../../api/services/api/apiRestaurantDishes';
import ErrorPage from '../../Error/ErrorPage';

interface DialogProps {
  dishName: string;
  open: boolean;
  handleAgree: () => void;
  handleRefuse: () => void;
}

type HideDialogProps = DialogProps & { isHidden: boolean };

const DeleteAlertDialog: FC<DialogProps> = ({ dishName, open, handleAgree, handleRefuse }) => (
  <Dialog
    open={open}
    onClose={handleRefuse}
    aria-labelledby="delete-alert-dialog-title"
    aria-describedby="delete-alert-dialog-description"
  >
    <DialogTitle id="delete-alert-dialog-title">Usuń danie</DialogTitle>
    <DialogContent>
      <DialogContentText id="delete-alert-dialog-description">
        {`Czy na pewno chcesz usunąć ${dishName} z listy dań?`}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button color="secondary" onClick={handleRefuse} autoFocus>Powrót</Button>
      <Button color="error" onClick={handleAgree}>Usuń</Button>
    </DialogActions>
  </Dialog>
);

const HideAlertDialog: FC<HideDialogProps> = ({ dishName, open, handleAgree, handleRefuse, isHidden }) => (
  <Dialog
    open={open}
    onClose={handleRefuse}
    aria-labelledby="hide-alert-dialog-title"
    aria-describedby="hide-alert-dialog-description"
  >
    <DialogTitle id="hide-alert-dialog-title">
      {isHidden ? 'Pokaż danie w menu' : 'Ukryj danie z menu'}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="hide-alert-dialog-description">
        {isHidden ? `Czy na pewno chcesz pokazać ${dishName} w menu?` : `Czy na pewno chcesz ukryć ${dishName} z listy dań?`}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button color="secondary" onClick={handleRefuse} autoFocus>Powrót</Button>
      <Button color="error" onClick={handleAgree}>
        {isHidden ? 'Pokaż' : 'Ukryj'}
      </Button>
    </DialogActions>
  </Dialog>
);

const RestaurantMenuPage: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const queryCache = useQueryClient();

  const [currentDish, setCurrentDish] = useState<Dish>();
  const [searchResult, setSearchResult] = useState<Dish[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [hideDialogOpen, setHideDialogOpen] = useState(false);
  const restaurantId = params.id as unknown as string;

  const { ingredients, cuisines, allergens, statusTags } = useTags();

  const { data: dishes, status: dishStatus } = useQuery({
    queryKey: [QueryKeys.DISH_INDEX, `restaurant-${restaurantId}`],
    queryFn: () => getRestaurantDishesNEW(restaurantId),
    meta: { errorMessage: 'Błąd podczas ładowania informacji o posiłkach.' },
  });

  useEffect(() => {
    if (dishes) { setSearchResult(dishes); }
  }, [dishes]);

  const deleteMutation = useMutation({
    mutationFn: ({ id }: { id: string }) => deleteRestaurantDishesNEW(id),
  });

  const editMutation = useMutation({
    mutationFn: ({ id }: { id: string }) => updateRestaurantDishNEW(id, { isHidden: !currentDish?.isHidden }),
  });

  useEffect(() => {
    if (deleteMutation.isSuccess) {
      setDeleteDialogOpen(false);
      queryCache.invalidateQueries([QueryKeys.DISH_INDEX, `restaurant-${restaurantId}`]);
      toast.success('Poprawnie usunięto posiłek.');
    }
    if (deleteMutation.isError) { toast.error('Błąd podczas usuwania posiłku.'); }
  }, [deleteMutation.isError, deleteMutation.isSuccess, queryCache, restaurantId]);

  useEffect(() => {
    if (editMutation.isSuccess) {
      setHideDialogOpen(false);
      queryCache.invalidateQueries([QueryKeys.DISH_INDEX, `restaurant-${restaurantId}`]);
      toast.success('Poprawnie ukryto posiłek.');
    }
    if (editMutation.isError) { toast.error('Błąd podczas ukrywania posiłku.'); }
  }, [editMutation.isError, editMutation.isSuccess, queryCache, restaurantId]);

  const handleNewDishBtnClick = () => navigate(RoutesVars.NEW_DISH(restaurantId));
  const handleEditDishButtonClick = (dish: Dish) => () => navigate(RoutesVars.EDIT_DISH(restaurantId, dish.id));

  const processDelete = useCallback(
    (dish: Dish) => () => {
      setCurrentDish(() => dish);
      setDeleteDialogOpen(true);
    }, [],
  );
  const processHide = useCallback(
    (dish: Dish) => () => {
      setCurrentDish(() => dish);
      setHideDialogOpen(true);
    }, [],
  );
  const handleDelete = (dish: Dish | undefined) => () => { if (dish) deleteMutation.mutate({ id: `${dish.id}` }); };
  const handleHide = (dish: Dish | undefined) => () => { if (dish) editMutation.mutate({ id: `${dish.id}` }); };

  const searchKeys = useMemo(() => ['name'], []);
  const searchCallback = useCallback(
    (results: Dish[]) => setSearchResult(results),
    [],
  );

  const status = useMemo(() => getStatus(dishStatus, statusTags), [dishStatus, statusTags]);

  return (
    <MainContainer data-testid="restaurant-menu-page__container-testid">
      <Container>
        {status === "error" && <Container sx={{ mt: 8 }}><ErrorPage /></Container>}
        {status === 'loading' && <LinearProgress test-id="restaurant-menu-page_loading" />}
        {status === 'success' && (
          <>
            <Container sx={{ pb: '30px' }}>
              <Typography variant="h2" component="h1">Dania</Typography>
            </Container>
            <Container sx={{ pb: '36px' }}>
              <Button
                variant="contained"
                aria-label="Dodaj nowe danie"
                startIcon={<AddIcon />}
                sx={{ borderRadius: '18px' }}
                onClick={handleNewDishBtnClick}
              >
                Dodaj danie
              </Button>
            </Container>

            {dishes && (
              <Container>
                <SearchInput list={dishes} keys={searchKeys} callback={searchCallback} />
              </Container>
            )}

            {searchResult.map((dish) => (
              <DishCard
                key={dish.id}
                dish={dish}
                deleteCallback={processDelete(dish)}
                hideCallback={processHide(dish)}
                editCallback={handleEditDishButtonClick}
                cuisinesList={cuisines}
                ingredientsList={ingredients}
                allergensList={allergens}
              />
            ))}
            {/* TODO: empty state z wyszukiwania + jesli wgl brak dań */}
          </>
        )}
      </Container>
      <DeleteAlertDialog
        open={deleteDialogOpen}
        handleAgree={handleDelete(currentDish)}
        handleRefuse={() => setDeleteDialogOpen(false)}
        dishName={currentDish?.name || ''}
      />
      <HideAlertDialog
        open={hideDialogOpen}
        handleAgree={handleHide(currentDish)}
        handleRefuse={() => setHideDialogOpen(false)}
        dishName={currentDish?.name || ''}
        isHidden={!!currentDish?.isHidden}
      />
    </MainContainer>
  );
};

export default RestaurantMenuPage;
