import { PostTokenResponse } from '../../api/apiLogin';
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  EXPIRES_AT,
  TOKEN_EXPIRED_MESSAGE,
} from '../../const/constValues';

const isTokenExpired = (): boolean => {
  const expiresAt = sessionStorage.getItem(EXPIRES_AT) as unknown as string;

  if (!expiresAt) return true;

  const currentTime = new Date().getTime();
  const isExpired = currentTime > parseInt(expiresAt, 10);

  return isExpired;
};
const getToken = (): string | null => sessionStorage.getItem(ACCESS_TOKEN);
const getRefreshToken = (): string | null => sessionStorage.getItem(REFRESH_TOKEN);
const isAuthenticated = (): boolean => !!getToken();
const setTokenExpirationTime = (expiresIn: number):void => {
  const currentTime = new Date().getTime();
  const expirationTime = new Date(currentTime + expiresIn).getTime();

  sessionStorage.setItem(EXPIRES_AT, expirationTime.toString());
};
const setToken = (token: string): void => {
  sessionStorage.setItem(ACCESS_TOKEN, token);
};
const setRefreshToken = (token: string): void => {
  sessionStorage.setItem(REFRESH_TOKEN, token);
};

const refreshToken = (tokenInfo: PostTokenResponse): void => {
  setToken(tokenInfo.accessToken);
  setRefreshToken(tokenInfo.refreshToken);
  setTokenExpirationTime(tokenInfo.expiresIn);
};

const deleteToken = (): void => {
  sessionStorage.removeItem(REFRESH_TOKEN);
  sessionStorage.removeItem(EXPIRES_AT);
  sessionStorage.removeItem(ACCESS_TOKEN);
};

export {
  isTokenExpired,
  isAuthenticated,
  setTokenExpirationTime,
  setToken,
  setRefreshToken,
  getToken,
  getRefreshToken,
  deleteToken,
  refreshToken,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  EXPIRES_AT,
  TOKEN_EXPIRED_MESSAGE,
};
