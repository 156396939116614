import React, { useState } from 'react';
import { Collapse, List, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { OrderApiResponse } from '../../../../../interfaces';
import WrapperOrderItemDishes from '../WrapperOrderItemDishes';
import { backgroundColors } from '../../../../../thema/AppThema';

interface Props {
  order: OrderApiResponse;
}

const OrderItem: React.FC<Props> = ({ order }) => {
  const [open, setOpen] = useState(false);

  return (
    <List
      sx={{ width: '100%', p: '13px', my: '12px', bgcolor: backgroundColors.card, border: '1px solid rgba(139, 138, 138, 0.295)' }}
      component="div"
      aria-labelledby="orders-list"
    >
      <ListItemButton onClick={() => setOpen(!open)} sx={{ p: 0 }}>
        <ListItemText>
          <Stack direction="column">
            <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
              {`Zamówienie nr ${order.id}`}
            </Typography>
            <Typography sx={{ fontSize: 16 }}>{order.deliveryDate}</Typography>
          </Stack>
        </ListItemText>

        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit sx={{ mt: '10px' }}>
        <WrapperOrderItemDishes order={order} />
      </Collapse>
    </List>
  );
};

export default OrderItem;
