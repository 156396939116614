import * as React from 'react';
import { Typography, CardContent, Card, styled, IconButtonProps, IconButton, Collapse, CardHeader, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { HistoryOrder } from '../../../interfaces';

export type SectionProps = {
  title: string;
  children?: React.ReactElement | string | React.ReactElement[];
};

const Section: React.FC<SectionProps> = ({ title, children }) => (
  <Box sx={{ mb: 1 }}>
    <Typography sx={{ fontSize: 15, m: 0, fontWeight: 400 }} color="text.secondary" gutterBottom>
      {title}
    </Typography>
    {children}
  </Box>
);

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})((props) => ({
  transform: !props.expand ? 'rotate(0deg)' : 'rotate(180deg)',
  margin: 'auto',
  transition: props.theme.transitions.create('transform', { duration: props.theme.transitions.duration.shortest }),
}));

interface Props {
  order: HistoryOrder;
  forceExpand?: boolean;
}

export default function HistoryOrderCard({ order, forceExpand = false }: Props): React.ReactElement {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = (): void => { setExpanded(!expanded); };

  const shouldDisplayCommentsPlaceholder = !order.dishes.filter((dish) => dish.comment !== "").length;

  return (
    <Card variant="outlined" sx={{ position: 'relative', mb: '6px' }}>
      <CardHeader
        action={(
          <ExpandMore
            expand={expanded || forceExpand}
            aria-label="show more"
            onClick={handleExpandClick}
            data-testid={`expand-more-${order.id}`}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        )}
        title={order.id}
        subheader="Numer zamówienia"
        sx={{
          '& .MuiCardHeader-content': { display: 'flex', flexDirection: 'column-reverse' },
          '& .MuiCardHeader-action': { mt: 0 },
          '& .MuiCardHeader-subheader': { fontSize: 15, fontWeight: 500 },
          '& .MuiCardHeader-title': { fontWeight: 'bold' },
        }}
      />
      <Collapse in={expanded || forceExpand} timeout="auto" unmountOnExit>
        <CardContent>
          <Section title="Termin dostawy">
            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
              {order.deliveryDate}
            </Typography>
          </Section>
          <Section title="Posiłki">
            {order.dishes.map((dish) => (
              <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                {`${dish.quantity}x ${dish.dishName}`}
              </Typography>
            ))}
          </Section>
          <Section title="Cena zamówienia">
            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
              {`${order.dueAmount} zł`}
            </Typography>
          </Section>
          <Section title="Data utworzenia zamówienia">
            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
              {order.dateCreated}
            </Typography>
          </Section>
          <Section title="Firma zamawiająca">
            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
              {order.companyName}
            </Typography>
          </Section>
          <Section title="Miejsce">
            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
              {order.locationName}
            </Typography>
          </Section>
          <Section title="Komentarz">
            <>
              {order.dishes.map((dish) => (
                dish.comment ? (
                  <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                    {`${dish.dishName}: ${dish.comment}`}
                  </Typography>
                ) : null
              ))}
              {shouldDisplayCommentsPlaceholder && (
                <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                  Brak komentarza.
                </Typography>
              )}
            </>
          </Section>
          <Section title="Status">
            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
              {order.status}
            </Typography>
          </Section>
        </CardContent>
      </Collapse>
    </Card>
  );
}
