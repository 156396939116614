import React from 'react';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';

import { AppTheme } from '../../../thema/AppThema';
import Footer from '../../../components/common/Footer';
import MainAppBar from '../../../components/common/MainAppBar';
import GuestDrawer from '../../../components/common/Drawer/GuestDrawer';
import MobileHeader from './components/MobileHeader/MobileHeader';
import DesktopHeader from './components/DesktopHeader/DesktopHeader';
import HowWeWork from './components/HowWeWork/HowWeWork';
import OurOfferMobile from './components/OurOfferMobile/OurOfferMobile';
import OurOfferDesktop from './components/OurOfferDesktop/OurOfferDesktop';
import OurArtifacts from './components/OurArtifacts/OurArtifacts';
import introMobile from '../../../assets/introMobile.png';
import introDesktop from '../../../assets/introDesktop.png';
import introDesktop4K from '../../../assets/introDesktop4K.png';

const GuestMainPageContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(0),
  backgroundColor: 'rgba(250, 242, 242, 0.151)',
}));

const MobileHeaderContainer = styled(Stack)(() => ({
  [AppTheme.breakpoints.up('sm')]: {
    display: 'none',
  },
  backgroundImage: `url(${introMobile})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  minHeight: '420px',
}));

const OurOfferDesktopContainer = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const DesktopHeaderContainer = styled(Stack)(() => ({
  [AppTheme.breakpoints.down('sm')]: {
    display: 'none',
  },
  minHeight: '420px',
  backgroundImage: `url(${introDesktop})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  [AppTheme.breakpoints.up('md')]: {
    minHeight: '820px',
  },

  [AppTheme.breakpoints.up('lg')]: {
    minHeight: '1120px',
  },

  [AppTheme.breakpoints.up('xl')]: {
    backgroundImage: `url(${introDesktop4K})`,
    minHeight: '1250px',
  },
}));

const MobileHowWeWorkContainer = styled(Stack)(() => ({
  [AppTheme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

export const MAIN_PAGE_BUTTONS_BG_COLOR = '#A1C181';
export const MAIN_PAGE_BUTTONS_COLOR = '#3B3B53';

const GuestMainPage: React.FunctionComponent = () => (
  <>
    <MainAppBar>
      <GuestDrawer />
    </MainAppBar>

    <GuestMainPageContainer data-testid="GuestMainPage__container">
      <MobileHeaderContainer>
        <MobileHeader />
      </MobileHeaderContainer>

      <DesktopHeaderContainer>
        <DesktopHeader />
        <HowWeWork />
      </DesktopHeaderContainer>

      <MobileHowWeWorkContainer>
        <HowWeWork />
      </MobileHowWeWorkContainer>

      <OurArtifacts />

      <OurOfferDesktopContainer>
        <OurOfferMobile />
        <OurOfferDesktop />
      </OurOfferDesktopContainer>

    </GuestMainPageContainer>
    <Footer />
  </>
);

export default GuestMainPage;
