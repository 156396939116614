import React, { useEffect } from 'react';
import { Container, LinearProgress, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import DishForm from '../components/DishForm';
import { RawDish } from '../../../interfaces';
import { RoutesVars } from '../../../const/constRoutes';
import MainContainer from '../../../components/common/MainContainer';
import useTags from '../../../hooks/useTags';
import ErrorPage from '../../Error/ErrorPage';
import { createRestaurantDishNEW } from '../../../api/services/api/apiRestaurantDishes';
import { QueryKeys } from '../../../api/services/api/queryKeys';

const RestaurantMenuPage: React.FunctionComponent = () => {
  const queryCache = useQueryClient();
  const params = useParams();
  const navigate = useNavigate();
  const restaurantId = Number(params.id as unknown as string);

  const { ingredients, allergens, statusTags } = useTags();

  const createMutation = useMutation({
    mutationFn: ({ dish }: { dish: RawDish }) => createRestaurantDishNEW(dish),
  });

  useEffect(() => {
    if (createMutation.isSuccess) {
      queryCache.invalidateQueries([QueryKeys.DISH_INDEX, `restaurant-${restaurantId}`]);
      toast.success('Poprawnie dodano posiłek.');
      navigate(RoutesVars.RESTAURANT_MENU(restaurantId));
    }
    if (createMutation.isError) { toast.error('Błąd podczas dodawania posiłku.'); }
  }, [createMutation.isSuccess, queryCache, restaurantId, createMutation.isError, navigate]);

  const formSubmit = (dish: RawDish) => { createMutation.mutate({ dish }); };

  const dataReady = !!ingredients.length && !!allergens.length;

  return (
    <MainContainer data-testid="restaurant-menu-page__container-testid">
      {statusTags === "error" && <Container sx={{ mt: 8 }}><ErrorPage /></Container>}
      {statusTags === 'loading' && <LinearProgress test-id="restaurant-add-dish-page_loading" />}
      {statusTags === 'success' && dataReady && (
        <>
          <Container sx={{ paddingBottom: '30px' }}>
            <Typography variant="h2" component="h1">
              Nowe danie
            </Typography>
          </Container>
          <Container>
            <DishForm {...{ restaurantId, allergens, ingredients, formSubmit, submitText: 'Dodaj' }} />
          </Container>
        </>
      )}

    </MainContainer>
  );
};

export default RestaurantMenuPage;
