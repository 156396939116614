import React, { ReactElement, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { CardMedia, Container, Stack } from '@mui/material';

import CartNavBar from '../Cart/CartNavBar';
import { UserContext } from '../../../contexts/userContext/userContext';
import logoMojPosilek from '../../../assets/logoMojPosilek.png';

type MainAppBarProps = {
  children: ReactElement;
  currentPath?: string;
};

const MainAppBar: React.FC<MainAppBarProps> = ({ children, currentPath }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (event?: React.KeyboardEvent | React.MouseEvent): undefined => {
    if (event && event.type === 'keydown') {
      const kEvent = event as React.KeyboardEvent;
      if (kEvent.key === 'Tab' || kEvent.key === 'Shift') return undefined;
    }

    setDrawerOpen(!drawerOpen);
    return undefined;
  };

  const { state: { user } } = React.useContext(UserContext);
  const showMenuIcon = process.env.NODE_ENV !== 'production' || user;

  return (
    <AppBar position="static" sx={{ marginBottom: 0 }}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Button color="inherit" sx={{ textTransform: 'none', p: 0 }}>
            <CardMedia
              component="img"
              src={logoMojPosilek}
              sx={{
                height: '39px',
                width: '39px',
              }}
            />
          </Button>
          {
            showMenuIcon && (
              <Stack direction="row">
                <CartNavBar />
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer}
                >
                  <MenuIcon fontSize="large" />
                </IconButton>
              </Stack>
            )
          }
        </Stack>
      </Container>
      {React.cloneElement(children, { isOpen: drawerOpen, onCloseCallback: toggleDrawer, currentPath })}
    </AppBar>
  );
};

MainAppBar.defaultProps = {
  currentPath: '',
};

export default MainAppBar;
