import React from 'react';
import { Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { fontColors } from '../../../thema/AppThema';

const CopyrightElementContainer = styled(Typography)(() => ({
  fontSize: '1.4rem',
  padding: 1,
  color: fontColors.gray,
}));

const CopyrightElement = (): React.ReactElement => (
  <CopyrightElementContainer>
    Copyright
    {' '}
    {new Date().getFullYear()}
    {' '}
    <Link
      color="inherit"
      href="/"
      sx={{ textDecoration: 'none' }}
      aria-label="Przejdź do strony głownej"
    >
      MójPosiłek
    </Link>
  </CopyrightElementContainer>
);

export default CopyrightElement;
