import React, { FC, useEffect, useMemo } from 'react';
import { Container, LinearProgress, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import DishForm from '../components/DishForm';
import { RawDish } from '../../../interfaces';
import MainContainer from '../../../components/common/MainContainer';
import useTags from '../../../hooks/useTags';
import { QueryKeys } from '../../../api/services/api/queryKeys';
import { getRestaurantDishNEW, updateRestaurantDishNEW } from '../../../api/services/api/apiRestaurantDishes';
import { getStatus } from '../../../selectors';
import ErrorPage from '../../Error/ErrorPage';
import { RoutesVars } from '../../../const/constRoutes';

const RestaurantEditDishPage: FC = () => {
  const queryCache = useQueryClient();
  const params = useParams();
  const navigate = useNavigate();

  const restaurantId = Number(params.id as unknown as string);
  const dishId = params.dishId as unknown as string;

  const { ingredients, allergens, statusTags } = useTags();

  const { data: dish, status: statusDish } = useQuery({
    queryKey: [QueryKeys.DISH_EDIT, dishId],
    queryFn: () => getRestaurantDishNEW(dishId),
    meta: { errorMessage: 'Błąd podczas ładowania informacji o posiłku.' },
  });

  const updateMutation = useMutation({
    mutationFn: ({ dish }: { dish: RawDish }) => updateRestaurantDishNEW(dishId, dish),
  });

  useEffect(() => {
    if (updateMutation.isSuccess) {
      queryCache.invalidateQueries([QueryKeys.DISH_EDIT, dishId]);
      queryCache.invalidateQueries([QueryKeys.DISH_INDEX, `restaurant-${restaurantId}`]);
      toast.success('Poprawnie edytowano posiłek.');
      navigate(RoutesVars.RESTAURANT_MENU(restaurantId));
    }
    if (updateMutation.isError) { toast.error('Błąd podczas edycji posiłku.'); }
  }, [updateMutation.isSuccess, dishId, restaurantId, updateMutation.isError, queryCache, navigate]);

  const formSubmit = (dish: RawDish) => { updateMutation.mutate({ dish }); };

  const status = useMemo(() => getStatus(statusDish, statusTags), [statusDish, statusTags]);
  const dataReady = dish && !!ingredients.length && !!allergens.length;

  return (
    <MainContainer data-testid="restaurant-edit-dish-page__container-testid">
      <Container>
        {status === "error" && <Container sx={{ mt: 8 }}><ErrorPage /></Container>}
        {status === 'loading' && <LinearProgress test-id="restaurant-edit-dish-page_loading" />}
        {status === 'success' && dataReady && (
          <>
            <Container sx={{ paddingBottom: '30px' }}>
              <Typography variant="h2" component="h1">
                {dish.name}
              </Typography>
            </Container>
            <Container>
              <DishForm {...{ restaurantId, allergens, ingredients, dish, formSubmit, submitText: 'Edytuj' }} />
            </Container>
          </>
        )}
      </Container>

    </MainContainer>
  );
};

export default RestaurantEditDishPage;
