/* eslint-disable max-len */
export type routesId = string | number;

export const RoutesVars = {
  MAIN: '/',
  ABOUT_US: '/about-us',
  LOGIN: '/login',
  ERROR: '/error',
  FOR_COMPANY: '/for-company',
  FOR_RESTAURANT: '/for-restaurant',
  BENEFIT: '/benefit',
  CONTACT: '/contact',
  PASSWORD_RESET: '/password-reset',
  ACCOUNT: '/account',
  ACCOUNT_SETTINGS: '/account/settings',
  RESTAURANT: (id: routesId): string => `/restaurants/${id}/`,
  RESTAURANT_ORDERS_HISTORY: (id: routesId): string => `/restaurants/${id}/orders-history`,
  RESTAURANT_CURRENT_ORDERS: (id: routesId): string => `/restaurants/${id}/current-orders`,
  RESTAURANT_MENU: (id: routesId): string => `/restaurants/${id}/menu`,
  NEW_DISH: (id: routesId): string => `/restaurants/${id}/dish/new`,
  NEW_EMPLOYEE: (id: routesId): string => `/companies/${id}/employees/new`,
  EDIT_DISH: (id: routesId, dishId: routesId): string => `/restaurants/${id}/dish/${dishId}/edit`,
  COMPANY: (id: routesId): string => `/companies/${id}`,
  COMPANY_EMPLOYEES: (id: routesId): string => `/companies/${id}/employees`,
  COMPANY_ORDERS: (id: routesId): string => `/companies/${id}/orders`,
  COMPANY_ORDERS_SUMMARY: (id: routesId): string => `/companies/${id}/orders-summary`,
  COMPANY_INVOICES: (id: routesId): string => `/companies/${id}/invoices`,
  EMPLOYEE: '/employees/id', // needs changing
  EMPLOYEE_RESTAURANTS: (userId: routesId): string => `/employees/${userId}/restaurants`,
  EMPLOYEE_SELECTED_RESTAURANT: (userId: routesId, restaurantId: routesId = 1): string => `/employees/${userId}/restaurants/${restaurantId}`,
  EMPLOYEE_RESTAURANT_SELECTED_DISH: (userId: routesId, restaurantId: routesId, dishId: routesId): string => `/employees/${userId}/restaurants/${restaurantId}/dish/${dishId}`,
  EMPLOYEE_ORDER_SUMMARY: (userId: routesId): string => `/employees/${userId}/restaurants/order-summary`,
  MY_POFILE: (id: routesId): string => `/employees/${id}/my-profile`,
  EMPLOYEE_ORDERS: (id: routesId): string => `/employees/${id}/orders`,
  EMPLOYEE_ORDER_SUCCESS: '/employees/orders/success',
};
