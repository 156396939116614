import { ThemeOptions, createTheme } from '@mui/material';

export const colors = {
  primary: '#A1C181',
  secondary: '#1c1d1c',
  background: '#ebe9e9e8',
  mobileMenu: '#F5F5F5',
  basicHover: '#bce0fd5b',
  basicBGC: '#b4c7cb53',
  white: '#FFFFFF',
};

export const fontColors = {
  lightDark: '#1C1D1C',
  gray: '#A2A2A2',
  lightGray: '#F3F3F3',
  dimGray: '#616161',
};

export const backgroundColors = {
  lightDark: '#1C1D1C',
  white: '#E6E6E6',
  basicGray: '#b4c7cb53',
  main: '#FCFCFC',
  card: '#FFFFFF',
};

export const theme = createTheme();

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          [theme.breakpoints.down('sm')]: {
            width: '80%',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.white,
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1920,
    },
  },
  spacing: 10,
};

export const AppTheme = createTheme(themeOptions);
