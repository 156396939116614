import { OrderApiResponse } from "../../../interfaces";
import { axiosApi } from "../../axios";
import { routes } from "../../routes";

const getOrdersNEW = async (params: unknown = {}): Promise<OrderApiResponse[]> => {
  const response = await axiosApi.get<OrderApiResponse[]>(routes.ORDERS(), { params });
  return response.data;
};

// const getRestaurantDishes = async (id: string): Promise<Dish[]> => {
//   const response = await axiosApi.get<Dish[]>(routes.RESTAURANT_DISHES_LIST(id));
//   return response.data;
// };

// const deleteRestaurantDishes = async (id: string): Promise<unknown> => {
//   const response = await axiosApi.delete(routes.DISHES(id));
//   return response.data;
// };

// const updateOrder =
//   (id: string | number, body: Partial<OrderApiResponse>): UpdateOrderPromise => patchResource<OrderApiResponse>(routes.ORDERS(id), body);

const updateOrderNEW = async (id: string, body: Partial<OrderApiResponse>): Promise<OrderApiResponse> => {
  const response = await axiosApi.patch<OrderApiResponse>(routes.ORDERS(id), body);
  return response.data;
};

// const createRestaurantDish = async (body: RawDish): Promise<RawDish> => {
//   const response = await axiosApi.post<RawDish>(routes.DISH_INDEX, body);
//   return response.data;
// };

export {
  getOrdersNEW,
  updateOrderNEW,
  // deleteRestaurantDishes,
  // updateRestaurantDish,
  // createRestaurantDish,
};
