import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Tag } from '../../../interfaces';

interface TagSelectorProps {
  title: string;
  selectedValues: string[];
  tags: Tag[];
  handleChange: (e: SelectChangeEvent<string[]>) => void;
  id: string;
}

const TagSelector: React.FC<TagSelectorProps> = ({ title, selectedValues, tags, handleChange, id }) => (
  <FormControl fullWidth>
    <InputLabel id={`${id}-select-label`}>{title}</InputLabel>
    <Select
      labelId={`${id}-select-label`}
      id={id}
      name={id}
      label={title}
      multiple
      value={selectedValues}
      onChange={handleChange}
      sx={{ marginBottom: '12px' }}
    >
      {
        tags.map((tag) => (
          <MenuItem key={tag.internalName} value={tag.internalName}>
            {tag.name}
          </MenuItem>
        ))
      }
    </Select>
  </FormControl>
);

export default TagSelector;
