import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../../components/Typography';

import productCurvyLines from '../assets/productCurvyLines.png';
import productHowItWorks1 from '../assets/productHowItWorks1.svg';
import productHowItWorks2 from '../assets/productHowItWorks2.svg';
import productHowItWorks3 from '../assets/productHowItWorks3.svg';

const item: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 2,
  mb: { xs: 6, md: 8 },
};

const title: SxProps<Theme> = {
  mb: 1,
  textAlign: 'center',
};

const image = {
  height: 55,
  mb: { xs: 3, md: 4 },
};

function ProductHowItWorks(): JSX.Element {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', bgcolor: '#f3eeef', overflow: 'hidden' }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          src={productCurvyLines}
          alt="curvy lines"
          sx={{
            pointerEvents: 'none',
            position: 'absolute',
            top: -180,
            opacity: 0.7,
          }}
        />
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 5, textAlign: 'center' }}>
          Dlaczego warto wybrać benefit MójPosiłek
        </Typography>
        <Typography variant="body1" sx={{ mb: 14, px: 10, textAlign: 'center', fontSize: 20, maxWidth: 900 }}>
          Zastanawiasz się nad wprowadzeniem benefitu do swojej firmy? MojPosiłek to innowacyjne
          rozwiązanie jakiego szukasz.
        </Typography>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box
                  component="img"
                  src={productHowItWorks1}
                  alt="suitcase"
                  sx={image}
                />
                <Typography variant="h6" sx={title}>
                  Benefit w miejscu pracy
                </Typography>
                <Typography variant="h5" align="center">
                  Budowanie relacji i społeczności w firmie. Zwieksza przepływ informacji dzięki wspólnym posiłkom
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box
                  component="img"
                  src={productHowItWorks2}
                  alt="graph"
                  sx={image}
                />
                <Typography variant="h6" sx={title}>
                  Większy komfort pracy
                </Typography>
                <Typography variant="h5" align="center">
                  Jedzenie dostarczane prosto do biura, gotowe so spożycia
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box
                  component="img"
                  src={productHowItWorks3}
                  alt="clock"
                  sx={image}
                />
                <Typography variant="h6" sx={title}>
                  Podgląd wydatków
                </Typography>
                <Typography variant="h5" align="center">
                  Kontroluj wydatki i przegldaj wykorzystane dofinansowania w firmie
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box
                  component="img"
                  src={productHowItWorks1}
                  alt="suitcase"
                  sx={image}
                />
                <Typography variant="h6" sx={title}>
                  Wizerunek pracodawcy
                </Typography>
                <Typography variant="h5" align="center">
                  Pokaż się jako dbający i nowoczesny pracodawca
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box
                  component="img"
                  src={productHowItWorks2}
                  alt="graph"
                  sx={image}
                />
                <Typography variant="h6" sx={title}>
                  Wydajność pracowników
                </Typography>
                <Typography variant="h5" align="center">
                  Dzięki wpólnym posiłkom i rozmowom zwiększasz wydajność pracowników
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box
                  component="img"
                  src={productHowItWorks3}
                  alt="clock"
                  sx={image}
                />
                <Typography variant="h6" sx={title}>
                  Lojalność i zaangażowanie
                </Typography>
                <Typography variant="h5" align="center">
                  Pozytywnie wpływa na relacje w firmie
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Box>
  );
}

export default ProductHowItWorks;
