import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CardActionArea, CardMedia, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useCart } from 'react-use-cart';

import { RoutesVars } from '../../../../../const/constRoutes';
import { DishOperation } from '../../../../../const/constShared';
import { CartItem, Dish, Tag } from '../../../../../interfaces';
import useMetadata from '../../../../../shared/employeeShared/hooks/useMetadata';
import { Metadata } from '../../../../../const/constEmployee';
import useMetadataValidation from '../../../../../shared/employeeShared/hooks/useMetadataValidation';
import { AppTheme } from '../../../../../thema/AppThema';
import { priceToGrosze } from '../../../../../selectors/cartSelectors';
import AlertClearCartItems from '../../../../../components/common/AlertClearCartItems/AlertClearCartItems';

interface Props {
  dish: Dish;
  ingredientsList: Tag[];
}

const RestaurantDishListItemContainer = styled('div')(() => ({
  padding: '18px 0',
  borderBottom: '1px solid rgba(139, 138, 138, 0.178)',
  minHeight: '120px',
}));

const RestaurantDishListItem: React.FC<Props> = ({ dish: dishData, ingredientsList }) => {
  const { userId, restaurantId } = useParams();
  const navigate = useNavigate();
  const { getItem, updateItemQuantity, addItem, setItems } = useCart();
  const { isDeliveryMetadataValid, isResturantMetadataValid } = useMetadataValidation();
  const { getMetadata, updateMetadata } = useMetadata();
  const { img: imgSrc, id, ingredients, name, price } = dishData;
  const isMoblie = useMediaQuery(AppTheme.breakpoints.down('sm'));

  const dish = getItem(`${id}`);

  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);

  const ingredientsToDisplay = ingredientsList.filter((ingredient) => ingredients.includes(ingredient.internalName));
  const ingredientsToCart = ingredientsToDisplay.map((ingredient) => ingredient.name);

  const dishToAddToCart: CartItem = {
    id: `${id}`,
    ingredients: ingredientsToCart,
    name,
    quantity: 1,
    price: priceToGrosze(price),
    restaurantId: restaurantId as string,
    locationId: getMetadata(Metadata.LOCATION_ID) as string,
    deliveryDate: getMetadata(Metadata.DATE) as string,
    imgSrc: imgSrc || 'https://cdn.pixabay.com/photo/2017/12/10/14/47/pizza-3010062_960_720.jpg',
  };

  useEffect(() => {
    if (!restaurantId && userId) {
      navigate(RoutesVars.EMPLOYEE_RESTAURANTS(userId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurantId, userId]);

  const handleChangeQuantity = (id: number, type: string, isCalledFromModal?: boolean) => {
    if (dish === undefined) {
      // We have items from other resturant, agree to delete them and add new dish from current selected resturant
      if (isCalledFromModal) {
        updateMetadata(Metadata.RESTAURANT_ID, restaurantId);
        isDeliveryMetadataValid && addItem(dishToAddToCart, 1);
        return;
      }

      // The cart is empty, so we add dish to it
      if (!isResturantMetadataValid) {
        updateMetadata(Metadata.RESTAURANT_ID, restaurantId);
        isDeliveryMetadataValid && addItem(dishToAddToCart, 1);
        return;
      }

      // We have items in cart
      // 1. Cart items are from current selected resturant
      // 2. Cart items are from different restaurant, so we display "Clear Cart Items" modal for user
      if (isResturantMetadataValid && (getMetadata(Metadata.RESTAURANT_ID) === restaurantId)) {
        isDeliveryMetadataValid && addItem(dishToAddToCart, 1);
      } else {
        setIsAlertDialogOpen(true);
      }
    } else {
      if (type === DishOperation.ADD) {
        updateItemQuantity(`${id}`, dish.quantity + 1);
      }
      if (type === DishOperation.DELETE) {
        updateItemQuantity(`${id}`, dish.quantity - 1);
      }
    }
  };

  const handleCardClick = () => {
    userId && restaurantId &&
      navigate(RoutesVars.EMPLOYEE_RESTAURANT_SELECTED_DISH(userId, restaurantId, id));
  };

  const handleAgree = () => {
    setItems([]);
    handleChangeQuantity(id, DishOperation.ADD, true);
    setIsAlertDialogOpen(false);
  };

  return (
    <RestaurantDishListItemContainer data-testid={`Restaurant-Dish-List-Item-${id}`}>
      <Grid container>
        <Grid
          item
          container
          alignItems="center"
          xs={5}
          sx={{ maxWidth: '120px' }}
        >
          <CardActionArea onClick={handleCardClick}>
            <CardMedia
              component="img"
              src={imgSrc || 'https://cdn.pixabay.com/photo/2017/12/10/14/47/pizza-3010062_960_720.jpg'}
              // TODO: usunąć przykładowy obrazek - dodac jakiś placeholder
              sx={{
                borderRadius: '18px',
                height: '110px',
                width: '110px',
                backgroundColor: 'rgba(139, 138, 138, 0.158)',
              }}
            />
          </CardActionArea>
        </Grid>

        <Grid
          item
          container
          direction="column"
          xs
        >
          <Grid item alignItems="flex-start">
            <CardActionArea onClick={handleCardClick}>
              <Typography
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  fontSize: 18,
                  [AppTheme.breakpoints.up('md')]: {
                    fontSize: 20,
                  },
                }}
              >
                {name}
              </Typography>
            </CardActionArea>
          </Grid>

          <Grid item>
            <CardActionArea onClick={handleCardClick}>
              <Typography
                component="div"
                gutterBottom
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                {ingredientsToDisplay.length ? ingredientsToDisplay.map(((item, index) => (
                  <Typography
                    component="span"
                    key={item.internalName}
                    sx={{
                      px: '2px',
                      fontSize: 12,
                      [AppTheme.breakpoints.up('md')]: {
                        fontSize: 15,
                      },
                    }}
                  >
                    {`${item.name}${index === ingredientsToDisplay.length - 1 ? '' : ','}`}
                  </Typography>
                )
                )) : (
                  <Typography
                    component="span"
                    sx={{
                      px: '2px',
                      fontSize: 12,
                      [AppTheme.breakpoints.up('md')]: {
                        fontSize: 15,
                      },
                    }}
                  >
                    Nie dodano listy składników.
                  </Typography>
                )}
              </Typography>
            </CardActionArea>
          </Grid>

          <Grid item container direction="row" alignItems="center">
            <Grid
              item
              container
              direction={isMoblie ? 'column' : 'row'}
              xs={isMoblie ? 4 : 7}
              sx={{ maxWidth: '170px' }}
            >
              <Grid item xs>
                <CardActionArea onClick={handleCardClick}>
                  <Typography
                    component="div"
                    sx={{
                      px: isMoblie ? '0' : '10px',
                      fontSize: isMoblie ? 15 : 18,
                      fontWeight: 'bold',
                    }}
                  >
                    {`${price} zł`}
                  </Typography>
                </CardActionArea>
              </Grid>
            </Grid>

            <Grid
              item
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              xs
            >
              <IconButton
                aria-label={`Usuń jeden ${name} z koszyka`}
                data-testid={`remove-from-card-item-id-${id}`}
                color="secondary"
                disabled={(Number(dish?.quantity) || 0) < 1}
                onClick={() => handleChangeQuantity(id, DishOperation.DELETE)}
              >
                <RemoveCircleIcon sx={{ fontSize: isMoblie ? 22 : 25 }} />
              </IconButton>
              <Typography
                aria-label={`Posiadasz ${Number(dish?.quantity) || 0} sztuk w koszyku`}
                data-testid={`quantity-in-card-item-id-${id}`}
                component="div"
                sx={{
                  fontSize: isMoblie ? 20 : 25,
                  fontWeight: 'bold',
                }}
              >
                {`${Number(dish?.quantity) || 0}`}
              </Typography>
              {/* ? //TODO: <Tooltip title="Nie wybrano szczegółów dostawy"></Tooltip> */}
              <IconButton
                aria-label={`Dodaj jeden ${name} do koszyka`}
                data-testid={`add-to-card-item-id-${id}`}
                color="secondary"
                onClick={() => handleChangeQuantity(id, DishOperation.ADD)}
                disabled={!isDeliveryMetadataValid}
              >
                <AddCircleIcon sx={{ fontSize: isMoblie ? 22 : 25 }} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <AlertClearCartItems
        open={isAlertDialogOpen}
        handleAgree={handleAgree}
        handleRefuse={() => { setIsAlertDialogOpen(false); }}
      />
    </RestaurantDishListItemContainer>
  );
};

export default RestaurantDishListItem;
