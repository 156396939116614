export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';

export class User {
  id: string;

  restaurants: number[];

  companies: number[];

  employeeId: string;

  constructor(id: string, restaurants: number[], companies: number[], employeeId: string | null | undefined) {
    this.id = id;
    this.restaurants = restaurants;
    this.companies = companies;
    this.employeeId = employeeId || '';
  }

  get isEmployee(): boolean {
    return !!this.employeeId;
  }

  get isCompanyOwner(): boolean {
    return !!this.companies[0];
  }

  get isRestaurantOwner(): boolean {
    return !!this.restaurants[0];
  }
}

export type ReducerAction = {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
};

export type State = {
  user: User | null;
};

export const initialState = {
  user: null,
};

export const reducer = (state: State, action: ReducerAction): State => {
  switch (action.type) {
    case FETCH_USER: {
      const resource = action.payload;
      return {
        ...state,
        user: new User(
          resource.id,
          resource.restaurants,
          resource.companies,
          resource.employee,
        ),
      };
    }
    case LOGOUT_USER: {
      return { ...state, user: null };
    }
    default:
      return state;
  }
};
