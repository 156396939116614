import { ENDPOINT_KEY, ERROR_MESSAGES, REQUEST_TYPE } from "../../const/constError";

// Error code structure:
//
// MP-statusCode-endpointKey-requestType
// MP-403-1-1
// MP(Mój Posiłek) + Forbidden(403) + location endpoint + GET method

export const translateErrorMessage = (status: string): string => ERROR_MESSAGES[status] || ERROR_MESSAGES.DEFAULT;
export const translateErrorEnpointKey = (endpoint: string): string => ENDPOINT_KEY[endpoint] || ENDPOINT_KEY.DEFAULT;
export const translateErrorRequestType = (requestType: string): string => REQUEST_TYPE[requestType] || REQUEST_TYPE.DEFAULT;

export const getErrorCode = (message: string): string => {
  if (message === 'Request failed with status code 400') return '400';
  if (message === 'Request failed with status code 401') return '401';
  if (message === 'Request failed with status code 403') return '403';
  if (message === 'Request failed with status code 404') return '404';
  if (message === 'Request failed with status code 500') return '500';
  return 'DEFAULT';
};
