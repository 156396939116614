/* eslint-disable max-len */

import { routes } from './routes';
import { getResources } from './axios';
import { DeliveryDates } from '../interfaces';

type DatePromise = () => Promise<DeliveryDates[]>;

const getDeliveryDates = ({ params }: { params?: unknown} = {}): DatePromise => getResources<DeliveryDates>(routes.RESTAURANT_DELIVERY_DATES(), params);

const requestRepository = {
  getDeliveryDates,
};

export default requestRepository;
