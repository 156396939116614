import React, { useEffect, useMemo, useState, FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Container, LinearProgress, Typography } from '@mui/material';
import PrintJs from 'print-js';

import requests from '../../../api/apiOrders';
import { getLocations } from '../../../api/apiLocations';
import { getEmployees } from '../../../api/apiEmployees';
import { CompanyOrder, companyOrdersSelector } from '../../Restaurant/selectors';
import { getStatus } from '../../../selectors';
import MainContainer from '../../../components/common/MainContainer';
import OrdersSummaryTable from './OrdersSummaryTable/OrdersSummaryTable';
import { createRowData } from './OrdersSummaryTable/utils';
import PrintableOrdersSummary from './PrintableOrdersSummary';
import useHandleError from '../../Error/useHandleError';

const CompanyOrdersSummaryPage: FC = () => {
  const params = useParams();
  const companyId = Number(params.id as unknown as string);
  const { handleError } = useHandleError();

  const [allOrders, setAllOrders] = useState<CompanyOrder[]>([]);
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [printMode, setPrintMode] = useState(false);

  const { data: orders, status: orderStatus } = useQuery(['orderIndex'], requests.getOrders({ params: { company: companyId } }), {
    onError: (error: Error) => { handleError(error.message, 'ORDERS', 'GET'); },
  });

  const { data: locations, status: locationStatus } = useQuery(['locationsIndex'], getLocations, {
    onError: (error: Error) => { handleError(error.message, 'LOCATIONS', 'GET'); },
  });

  const { data: employees, status: employeeStatus } = useQuery(['employeesIndex'], getEmployees({ params: { company: companyId } }), {
    onError: (error: Error) => { handleError(error.message, 'EMPLOYEES_INDEX', 'GET'); },
  });

  const status = getStatus(orderStatus, locationStatus, employeeStatus);

  useEffect(
    () => {
      if (orders && locations && employees) {
        const historyOrders = companyOrdersSelector(orders, locations, employees);
        setAllOrders(() => historyOrders);
      }
    },
    [orders, locations, employees],
  );

  useEffect(
    () => {
      if (printMode) PrintJs({ type: "html", printable: "orders-summary" });
      setPrintMode(false);
    },
    [printMode],
  );

  const rows = useMemo(() => allOrders.map((order) => createRowData(
    order.user,
    order.employeeName,
    order.id,
    order.dateCreated,
    order.deliveryDate,
    order.locationName,
    order.dueAmount,
  )), [allOrders]);

  // TODO: usunąć mocked data
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const mockedRows = useMemo(() => [
    createRowData('1', 'Jan Kowal', '12', '2022-05-02', '2022-05-02', 'Biuro', '15,00'),
    createRowData('2', 'Marysia Koza', '44', '2042-05-02', '2022-05-02', 'Kantyna', '17,00'),
    createRowData('3', 'Dawid Wsiadło', '2', '2022-12-02', '2022-05-02', 'Biuro', '45.99'),
    createRowData('4', 'Lollipop Exp', '523', '2022-05-02', '2022-05-02', 'Office', '66,23'),
    createRowData('5', 'KitKat Easter', '1025', '2022-01-02', '2022-05-02', 'Biuro', '78,88'),
    createRowData('6', 'Oreo Nowak', '3', '2024-05-02', '19-07-2022', 'Stanowisko 6', '25,85'),
  ], []);

  const printStuff = () => setPrintMode(() => true);

  if (status === 'loading') {
    return <LinearProgress sx={{ mt: '10px' }} />;
  }

  return (
    <MainContainer>
      {status === 'success' && (
        <>
          <Container sx={{ paddingBottom: '30px' }}>
            <Typography variant="h2" component="h1">Podsumowanie zamówień pracowników</Typography>
          </Container>
          <Container id="orders-summary">
            {!printMode && (
              <OrdersSummaryTable
                rows={rows}
                printStuff={printStuff}
                setSelected={setSelected}
                selected={selected}
              />
            )}
            {printMode && rows.map((order) => {
              if (selected.includes(order.id)) {
                return (
                  <div key={order.id}>
                    <PrintableOrdersSummary order={order} />
                    <p>------------------------------------------------</p>
                  </div>
                );
              }
              return null;
            })}
          </Container>
        </>
      )}
    </MainContainer>
  );
};

export default CompanyOrdersSummaryPage;

