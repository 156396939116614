import { routes } from './routes';
import { getResources } from './axios';
import { Employee } from '../interfaces';

export const getEmployees = ({ id, params }: { id?: string | number, params?: unknown } = {}): () => Promise<Employee[]> => (
  getResources<Employee>(routes.EMPLOYEES_INDEX(id), params)
);

const requestRepository = {
  getEmployees,
};

export default requestRepository;
