import React, { useMemo } from 'react';
import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { Dish, RawDish, Tag } from '../../../interfaces';
import TagSelector from './TagSelector';
import selectors from '../selectors';

interface DishFormProps {
  ingredients: Tag[];
  allergens: Tag[];
  restaurantId: number;
  formSubmit: (dish: Partial<Dish & { tags: string[] }>) => void;
  submitText: string;
  dish?: Dish;
}

const defaultDish = {
  name: '',
  allergens: [],
  ingredients: [],
  price: '',
};

const DishForm: React.FC<DishFormProps> = ({ restaurantId, ingredients, allergens, dish = defaultDish, formSubmit, submitText }) => {
  const initialIngredients = useMemo(() => selectors.ingredientsSelector(dish.ingredients, ingredients), [dish, ingredients]);
  const initialAllergens = useMemo(() => selectors.ingredientsSelector(dish.allergens, allergens), [allergens, dish]);
  const formik = useFormik({
    initialValues: {
      name: dish.name,
      restaurantId,
      price: dish.price,
      ingredients: initialIngredients,
      allergens: initialAllergens,
    },
    onSubmit: (values) => {
      const dish: RawDish = {
        restaurant: Number(values.restaurantId),
        price: String(values.price),
        name: values.name,
        tags: values.ingredients.concat(values.allergens),
      };
      formSubmit(dish);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        id="name"
        name="name"
        label="Nazwa"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        sx={{ marginBottom: '12px' }}
      />
      <TextField
        fullWidth
        id="price"
        name="price"
        label="Cena"
        type="number"
        inputProps={{
          min: '1',
          step: 'any',
        }}
        value={formik.values.price}
        onChange={formik.handleChange}
        error={formik.touched.price && Boolean(formik.errors.price)}
        helperText={formik.touched.price && formik.errors.price}
        sx={{ marginBottom: '12px' }}
      />
      <TagSelector
        title="Składniki"
        id="ingredients"
        tags={ingredients}
        selectedValues={formik.values.ingredients}
        handleChange={formik.handleChange}
      />
      <TagSelector
        title="Alergeny"
        id="allergens"
        tags={allergens}
        selectedValues={formik.values.allergens}
        handleChange={formik.handleChange}
      />
      <Button
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
        disabled={!restaurantId}
      >
        {submitText}
      </Button>
    </form>
  );
};

DishForm.defaultProps = {
  dish: {
    name: '',
    allergens: [],
    cuisine: [],
    ingredients: [],
    isHidden: false,
    price: '',
    description: '',
    id: 0,
    img: '',
    restaurant: 0,
  },
};

export default DishForm;
