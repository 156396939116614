import { NO_IMAGE_PLACEHOLDER_SVG, TagCategoryMap } from "../../../const/constShared";
import { RawTag, Tag } from "../../../interfaces";
import { axiosApi } from "../../axios";
import { routes } from "../../routes";

const getRawTags = async (): Promise<Tag[]> => {
  const response = await axiosApi.get<RawTag[]>(routes.TAGS_INDEX);

  const tags: Tag[] = [];

  response.data.forEach((tag) => {
    const category = TagCategoryMap[tag.tagCategory] as keyof typeof TagCategoryMap;

    tags.push({
      img: tag.img || NO_IMAGE_PLACEHOLDER_SVG,
      internalName: tag.internalName,
      name: tag.name,
      category,
    });
  });

  return Promise.resolve(tags);
};

export {
  getRawTags,
};
