import { routes } from "./routes";
import { axiosApi, GetResource } from "./axios";
import { Payment } from "../interfaces";

const createPayment = async (body: Partial<Payment>): Promise<Payment> => {
  const response = await axiosApi.post(
    routes.PAYMENTS,
    {
      amount: body.amount,
      orderId: body.order,
    },
  );

  const resourceResponse = new GetResource<Payment>(response);
  return resourceResponse.resource;
};

export default {
  createPayment,
};
