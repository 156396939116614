import React from 'react';
import { Button, Link, Typography, styled, Stack } from '@mui/material';

import { fontColors, AppTheme } from '../../../../../thema/AppThema';
import { MAIN_PAGE_BUTTONS_COLOR, MAIN_PAGE_BUTTONS_BG_COLOR } from '../../GuestMainPage';

const IntroductionContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(15, 4, 5),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',

  [AppTheme.breakpoints.up('md')]: {
    padding: theme.spacing(20, 5, 15),

  },
  [AppTheme.breakpoints.up('lg')]: {
    padding: theme.spacing(25, 12, 20),

  },

  [AppTheme.breakpoints.up('xl')]: {
    padding: theme.spacing(30, 25, 25),
  },
}));

const DesktopHeader: React.FunctionComponent = () => {
  const headerDesktopData =
  {
    title: 'Catering dla twoich pracowników',
    description: 'Oferuj swoim pracownikom wygodny dostęp do posiłków z ich ulubionych restauracji bez wychodzenia z biura.',
    buttonText: 'Poznaj nas',
  };

  return (
    <IntroductionContainer>
      <Stack display="flex" flexDirection="column" alignItems="flex-start">
        <Typography
          component="header"
          sx={{
            fontSize: '3.4rem',
            fontWeight: 'bold',
            color: fontColors.lightDark,
          }}
        >
          {headerDesktopData.title}
        </Typography>
        <Typography
          component="p"
          sx={{
            fontSize: '1.6rem',
            mt: '15px',
            maxWidth: '425px',
          }}
        >
          {headerDesktopData.description}
        </Typography>
        <Link
          href="/"
          sx={{
            textDecoration: 'none',
          }}
          data-testid="Poznaj-nas-desktop-test-id"
        >
          <Button
            variant="contained"
            color="primary"
            sx={{
              borderRadius: '18px',
              fontSize: '1.4rem',
              textTransform: 'none',
              backgroundColor: MAIN_PAGE_BUTTONS_BG_COLOR,
              color: MAIN_PAGE_BUTTONS_COLOR,
              mt: '40px',
            }}
          >
            {headerDesktopData.buttonText}
          </Button>
        </Link>
      </Stack>
    </IntroductionContainer>

  );
};

export default DesktopHeader;

