import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';

import { CssBaseline, ThemeProvider } from '@mui/material';

import { Toaster } from 'react-hot-toast';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppTheme } from './thema/AppThema';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <CssBaseline enableColorScheme />
    <ThemeProvider theme={AppTheme}>
      <App />
      <Toaster toastOptions={{ duration: 3000, position: 'bottom-right' }} />
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
