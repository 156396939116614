import React, { useEffect, useReducer } from 'react';
import {
  Button, TextField, FormControlLabel, Checkbox,
  Link, Stack, Box, Typography, Container, Fade, Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import { useNavigate, useLocation } from 'react-router-dom';
import { LoginResult, loginUser } from '../../api/apiLogin';
import CopyrightComponent from '../../components/common/CopyrightComponent';
import { RoutesVars } from '../../const/constRoutes';
import reducer, { initialStateLogin } from './reducer';
import { isError } from '../../api/axios';
import MainAppBar from '../../components/common/MainAppBar';
import GuestDrawer from '../../components/common/Drawer/GuestDrawer';
import { useAuthContext } from '../../contexts/AuthContext/AuthContext';
import { UserContext } from '../../contexts/userContext/userContext';
import { User } from '../../contexts/userContext/reducer';

const SubmitButton = styled(Button)(() => ({
  borderRadius: 40,
  height: 36,
}));

const afterLoginPath = (user: User) => {
  if (user.isEmployee) {
    return RoutesVars.EMPLOYEE_ORDERS(user.employeeId);
  }

  if (user.isCompanyOwner) {
    return RoutesVars.COMPANY(user.companies[0]);
  }

  if (user.isRestaurantOwner) {
    return RoutesVars.RESTAURANT(user.restaurants[0]);
  }

  return RoutesVars.ACCOUNT;
};

const LoginPage: React.FunctionComponent = () => {
  const [state, dispatch] = useReducer(reducer, initialStateLogin);
  const navigate = useNavigate();
  // that's a trick to make typescript not treat location.state as unknown
  const { state: locationState } = useLocation() as { state: { from: { pathname: string } } };
  const { setToken, setTokenExpirationTime, setRefreshToken } = useAuthContext();
  const { state: { user }, actions } = React.useContext(UserContext);

  useEffect(() => {
    const payload = !(state.username.trim() && state.password.trim());
    dispatch({ type: 'setIsButtonDisabled', payload });
  }, [state.username, state.password]);

  useEffect(() => {
    if (user) {
      const defaultPath = afterLoginPath(user);
      const path = locationState?.from ? locationState.from.pathname : defaultPath;
      navigate(path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();

    const result = await loginUser(state.username, state.password);

    if (LoginResult.isSuccess(result)) {
      const tokenInfo = result.tokenInfo();

      setToken(tokenInfo.accessToken);
      setTokenExpirationTime(tokenInfo.expiresIn);
      setRefreshToken(tokenInfo.refreshToken);
      actions.fetchUser();
    } else if (isError(result)) {
      dispatch({ type: 'setIsError', payload: true });
    }
  };

  const onInputChange = (type: 'setUsername' | 'setPassword'): React.ChangeEventHandler<HTMLInputElement> => (
    (event) => dispatch({ type, payload: event.target.value })
  );

  return (
    <div data-testid="account-page__container-testid">
      <MainAppBar>
        <GuestDrawer />
      </MainAppBar>
      <Container component="main" maxWidth="xs">
        <Grid columns={1} alignItems="flex-start" marginTop={7}>
          <Typography component="div" variant="h2" sx={{ fontWeight: 'bold' }}>
            Zaloguj się
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Fade in={state.isError}>
              <Alert severity="error">Podany email lub hasło są nieprawidłowe</Alert>
            </Fade>
          </Box>
          <form noValidate onSubmit={handleSubmit}>
            <TextField
              color="primary"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-mail"
              name="email"
              autoComplete="email"
              autoFocus
              error={state.isError}
              onChange={onInputChange('setUsername')}
              inputProps={{ 'data-testid': 'email-input' }}
            />
            <TextField
              color="primary"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Hasło"
              type="password"
              id="password"
              error={state.isError}
              autoComplete="current-password"
              helperText={state.helperText}
              onChange={onInputChange('setPassword')}
              inputProps={{ 'data-testid': 'password-input' }}
            />
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <FormControlLabel
                control={<Checkbox value="remember" color="secondary" />}
                label="Zapamiętaj mnie"
                disabled
              />
              <Link href={RoutesVars.PASSWORD_RESET} variant="body2" color="secondary">
                Nie pamiętasz hasła?
              </Link>
            </Stack>
            <SubmitButton
              type="submit"
              name="submit-button"
              fullWidth
              variant="contained"
              color="primary"
              disabled={state.isButtonDisabled}
              data-testid="submit-input"
            >
              Zaloguj się
            </SubmitButton>
          </form>
        </Grid>
        <Box mt={8}>
          <CopyrightComponent />
        </Box>
      </Container>
    </div>
  );
};

export default LoginPage;
