import { TableData } from "./CompanyInvoicesTable";

export function createRowData(
  invoinceId: number,
  restaurantName: string,
  summaryOrdersCount: number,
  summaryAmount: string,
  ordersDateRange: string,
  paymentDueDate: string,
  paymentStatus: string,
): TableData {
  return {
    invoinceId,
    restaurantName,
    summaryOrdersCount,
    summaryAmount,
    ordersDateRange,
    paymentDueDate,
    paymentStatus,
  };
}
