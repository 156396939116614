import { Tag, OrderApiResponse, HistoryOrder, Location, Company, Employee } from "../../../interfaces";

// const getDishNames = (dishes: Dish[], order: OrderApiResponse) => {
//   const dishIds = order.dishes.map((dishOrder) => dishOrder.dishId);

//   return dishes.reduce(
//     (acc: string[], dish: Dish): string[] => {
//       if (dishIds.includes(dish.id)) acc.push(dish.name);
//       return acc;
//     },
//     [],
//   );
// };

const getLocationName = (locations: Location[], order: OrderApiResponse): string => (
  locations.find((location) => location.id === `${order.locationId}`)?.name || 'Brak nazwy'
);

const getCompanyName = (companies: Company[], order: OrderApiResponse): string => (
  companies.find((company) => company.id === order.company)?.name || 'Brak nazwy'
);

const fullName = (employee: Employee) => `${employee.name} ${employee.surname}`;

const getEmployeeName = (employees: Employee[], order: OrderApiResponse): string => {
  const employee = employees.find((employee) => employee.account === order.user);

  if (employee) return fullName(employee);

  return "Brak imienia";
};

export const ingredientsSelector = (tagNames: string[], tags: Tag[]): string[] => (
  tags.filter((tag) => tagNames.includes(tag.internalName))
    .map((tag) => tag.internalName)
);

export const historyOrdersSelector = (orders: OrderApiResponse[], locations: Location[], companies: Company[]): HistoryOrder[] => {
  const historyOrders = orders.reduce(
    (acc: HistoryOrder[], order: OrderApiResponse): HistoryOrder[] => {
      const historyOrder: HistoryOrder = {
        ...order,
        locationName: getLocationName(locations, order),
        companyName: getCompanyName(companies, order),
        deliveryDate: order.deliveryDate || '---',
      };

      return [...acc, historyOrder];
    },
    [],
  );

  return historyOrders;
};

export type CompanyOrder = (OrderApiResponse & { employeeName: string, locationName: string });

export const companyOrdersSelector = (orders: OrderApiResponse[], locations: Location[], employees: Employee[]): CompanyOrder[] => {
  const companyOrders = orders.reduce(
    (acc: CompanyOrder[], order: OrderApiResponse): CompanyOrder[] => {
      const companyOrder = {
        ...order,
        employeeName: getEmployeeName(employees, order),
        locationName: getLocationName(locations, order),
      };

      return [...acc, companyOrder];
    },
    [],
  );

  return companyOrders;
};

export const filterHistoryOrders = (
  orders: HistoryOrder[], statuses: string[], company = -1, location = -1, dateFrom?: Date, dateTo?: Date,
): HistoryOrder[] => {
  const filtered = orders.filter(
    (order) => {
      let include = true;
      if (statuses.length > 0) {
        include = statuses.includes(order.status);
      }
      if (include && company !== -1) {
        include = company === order.company;
      }
      if (include && location !== -1) {
        include = location === order.locationId;
      }
      if (include && dateFrom) {
        include = new Date(Date.parse(order.dateCreated)) > dateFrom;
      }
      if (include && dateTo) {
        include = new Date(Date.parse(order.dateCreated)) < dateTo;
      }

      return include;
    },
  );

  return filtered;
};

export default {
  ingredientsSelector,
  historyOrdersSelector,
  filterHistoryOrders,
};
