import { routes } from './routes';
import { getResources } from './axios';
import { Tag } from '../interfaces';
import { NO_IMAGE_PLACEHOLDER_SVG, TagCategoryMap } from '../const/constShared';
import { RawTag } from '../interfaces/Tag';

const getTags = async (): Promise<Tag[]> => {
  const rawTags = await getResources<RawTag>(routes.TAGS_INDEX)();

  const tags: Tag[] = [];

  rawTags.forEach((tag) => {
    const category = TagCategoryMap[tag.tagCategory] as keyof typeof TagCategoryMap;

    tags.push({
      img: tag.img || NO_IMAGE_PLACEHOLDER_SVG,
      // TODO: dodac placeholder obrazka dla tagów
      internalName: tag.internalName,
      name: tag.name,
      category,
    });
  });
  return Promise.resolve(tags);
};

const requestRepository = {
  getTags,
};

export default requestRepository;
