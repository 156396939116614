export type Status = "error" | "idle" | "loading" | "success";

export const getStatus = (...statuses: string[]): Status => {
  let success = false;
  let loading = false;
  let error = false;

  statuses.forEach((status) => {
    if (status === 'success') success = true;
    if (status === 'loading') loading = true;
    if (status === 'error') error = true;
  });

  if (error) return 'error';
  if (loading) return 'loading';
  if (success) return 'success';

  return 'idle';
};

export default {
  getStatus,
};

