import React from 'react';
import { Button } from '@mui/material';
import { useCart } from "react-use-cart";

import { Metadata } from '../../../../../const/constEmployee';
import useMetadata from '../../../../../shared/employeeShared/hooks/useMetadata';
import { Location } from '../../../../../interfaces';
import useDrag from '../../../../../components/common/HorizontalScrollingMenu/useDrag';

export interface LocationFilterProps {
  location: Location;
  itemId: string;
  setIsAlertDialogOpen: (state: boolean) => void,
}

const LocationFilter: React.FunctionComponent<LocationFilterProps> = ({ location, setIsAlertDialogOpen }) => {
  const { getMetadata, updateMetadata } = useMetadata();
  const { dragging } = useDrag();
  const { isEmpty } = useCart();

  const handleChangeDate = (): void => {
    !isEmpty && getMetadata(Metadata.LOCATION) !== location.name && setIsAlertDialogOpen(true);

    if (!dragging && isEmpty) {
      updateMetadata(Metadata.LOCATION, location.name);
      updateMetadata(Metadata.TIME, location.time);
      updateMetadata(Metadata.LOCATION_ID, `${location.id}`);
      updateMetadata(Metadata.DATE, '');
    }
  };

  return (
    <Button
      variant={getMetadata(Metadata.LOCATION_ID) === location.id ? 'contained' : 'outlined'}
      color="primary"
      aria-label="lokalizacja dostawy"
      onClick={handleChangeDate}
      sx={{
        margin: '5px',
        borderRadius: 18,
        fontWeight: 'bold',
        width: '90px',
        bgcolor: 'white',
      }}
    >
      {`${location.name} ${location.time}`}
    </Button>
  );
};
export default LocationFilter;
