import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container, Stack, Typography, styled, CardMedia, LinearProgress, CircularProgress, Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';

import { useCart } from 'react-use-cart';
import RestaurantDishListItem from './components/RestaurantDishListItem/RestaurantDishListItem';
import { RoutesVars } from '../../../const/constRoutes';
import { Dish, Restaurant, Tag } from '../../../interfaces';
import requestRepositoryRestaurant from '../../../api/apiRestaurants';
import SearchInput from '../../../components/common/SearchInput';
import BreadcrumbsComponent from '../../../components/common/BreadcrumbsComponent/BreadcrumbsComponent';
import tagRequests from '../../../api/apiTags';
import { TagCategories } from '../../../const/constShared';
import { getStatus } from '../../../selectors';
import CategoriesScrollingMenu from './components/CategoriesScrollingMenu/CategoriesScrollingMenu';
import requestRepository from '../../../api/apiDishes';
import CartBanner from '../../../components/common/Cart/CartBanner';
import useHandleError from '../../Error/useHandleError';

const EmployeeRestaurantsPageContainer = styled(Container)(() => ({ padding: 0, minHeight: '100vh' }));
const EmployeeRestaurantsPageContent = styled(Container)(() => ({ padding: 20 }));
const CategoryTagsContainer = styled(Container)(() => ({ padding: '15px 0 10px' }));
const SelectedDishContainer = styled('div')(() => ({ padding: 0, margin: '15px 0' }));

const EmployeeSelectedRestaurantPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { userId, restaurantId } = useParams();
  const { totalItems } = useCart();
  const { handleError } = useHandleError();

  const [dishList, setDishList] = useState<Dish[]>([]);
  const [searchResult, setSearchResult] = useState<Dish[]>([]);
  const [restaurant, setRestaurant] = useState<Restaurant>();
  const [cuisines, setCuisines] = useState<Tag[]>([]);
  const [selectedCuisines, setSelectedCuisines] = useState<string[]>([]);
  const [ingredients, setIngredients] = useState<Tag[]>([]);

  const searchKeys = useMemo(() => ['name'], []);
  const selectedCuisinesID = useMemo(() => selectedCuisines.map((cuisine) => cuisine).join(), [selectedCuisines]);

  useEffect(() => {
    if (!restaurantId && userId) {
      navigate(RoutesVars.EMPLOYEE_RESTAURANTS(userId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurantId, userId]);

  const { status: dishesStatus, refetch: dishesRefetch, isRefetching } = useQuery(['dishes-rest', restaurantId],
    requestRepository.getDishes({
      params: {
        restaurant: restaurantId,
        tag: selectedCuisinesID.length ? `[${selectedCuisinesID}]` : '',
      },
    }),
    {
      onSuccess: (dishesData: Dish[]) => {
        setDishList(dishesData);
        setSearchResult(() => dishesData);
      },
      onError: (error: Error) => { handleError(error.message, 'DISHES', 'GET'); },
    });

  const { status: restaurantStatus } = useQuery(['restaurantData'], requestRepositoryRestaurant.getRestaurant(restaurantId as string), {
    onSuccess: (restaurantData: Restaurant) => { setRestaurant(restaurantData); },
    onError: (error: Error) => { handleError(error.message, 'RESTAURANTS', 'GET'); },
  });

  const { status: tagsStatus } = useQuery(['tagsIndex'], tagRequests.getTags, {
    onSuccess: (data) => {
      data.forEach((tag) => {
        if (tag.category === TagCategories.INGREDIENT) {
          if (!ingredients.includes(tag)) {
            setIngredients((state) => [...state, tag]);
          }
        } else if (tag.category === TagCategories.CUISINE) {
          if (!cuisines.includes(tag)) {
            setCuisines((state) => [...state, tag]);
          }
        }
      });
    },
    onError: (error: Error) => { handleError(error.message, 'TAGS_INDEX', 'GET'); },
  });

  const statusDishesList = useMemo(() => getStatus(dishesStatus), [dishesStatus]);
  const status = useMemo(() => getStatus(restaurantStatus, tagsStatus), [restaurantStatus, tagsStatus]);
  const statusTags = useMemo(() => getStatus(tagsStatus), [tagsStatus]);

  const searchCallback = useCallback(
    (results: Dish[]) => {
      setSearchResult(results);
    }, [],
  );

  useEffect(() => {
    dishesRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCuisinesID]);

  return (
    <EmployeeRestaurantsPageContainer disableGutters maxWidth="md" data-testid="Employee-Selected-Restaurant-Page__container-testid">
      {status === 'loading' && (<LinearProgress data-testid="Employee-Selected-Restaurant-Page-loading-progressbar__testid" />)}
      {status === 'success' && restaurant && cuisines && ingredients && searchResult && (
        <>
          <Container sx={{ pl: '16px', pt: '19px', pb: '10px' }}>
            <BreadcrumbsComponent
              ariaLabel="breadcrumb-selected-restaurant-page"
              breadcrumbContents={[{
                name: 'Restauracje',
                href: `${RoutesVars.EMPLOYEE_RESTAURANTS(userId as string)}`,
                testId: 'Breadcrumbs-powrót-do-listy-resturacji',
              }]}
              breadcrumbCurrentPage={{
                name: `${restaurant.name || '---'}`,
                testId: 'Breadcrumbs-wybrana-resturacja',
              }}
            />
          </Container>
          <CardMedia
            component="img"
            src={restaurant.img || "https://cdn.pixabay.com/photo/2017/12/10/14/47/pizza-3010062_960_720.jpg"}
            // TODO: Wprowadzić src obrazka resturacji -- placeholder jakis jak nie bedzie obrazka
            sx={{
              height: '212px',
              width: '100%',
              backgroundColor: 'rgba(139, 138, 138, 0.158)',
            }}
          />

          <EmployeeRestaurantsPageContent>
            <Typography component="div" sx={{ fontSize: 32, fontWeight: 'bold' }}>{restaurant.name}</Typography>

            <CategoryTagsContainer disableGutters>
              <Stack flexDirection="row" justifyContent="space-between">
                <Typography component="div" sx={{ fontSize: 20, fontWeight: 'bold', p: '5px 0' }}>Kategorie</Typography>
                {!!selectedCuisines.length && (
                  <Button
                    onClick={() => setSelectedCuisines([])}
                    sx={{ fontSize: 14, mr: '10px' }}
                    endIcon={<ClearIcon />}
                    color="error"
                  >
                    Wyczyść filtry
                  </Button>
                )}
              </Stack>

              {statusTags === 'success' && !!cuisines.length && (
                <CategoriesScrollingMenu
                  cuisines={cuisines}
                  selectedCuisines={selectedCuisines}
                  setSelectedCuisines={setSelectedCuisines}
                />
              )}

              {statusTags === 'success' && !cuisines.length && (
                <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>Ooops brak dostępnych kategorii do wyświetlenia...</Typography>
              )}
            </CategoryTagsContainer>

            <Stack flexDirection="row" alignItems="center">
              {statusDishesList === 'success' && dishList && (
                <SearchInput
                  list={dishList}
                  keys={searchKeys}
                  callback={searchCallback}
                  label="Wyszukaj w dostępnych daniach..."
                  isDisabled={isRefetching}
                />
              )}
            </Stack>

            <Typography sx={{ pt: '20px', fontSize: 20, fontWeight: 'bold' }}>Dostępne dania</Typography>

            {(statusDishesList === 'loading' || isRefetching) && (
              <Stack flexDirection="row" justifyContent="center" sx={{ pt: '20px' }}>
                <CircularProgress data-testid="Selected-Dish-loading-progressbar__testid" />
              </Stack>
            )}

            <SelectedDishContainer data-testid="Selected-Dish-Container__container-testid">
              {statusDishesList === 'success' && dishList && searchResult && !isRefetching && (
                searchResult.map((dish) => (
                  <RestaurantDishListItem
                    data-testid="Restaurant-Dish-List-Item__testid"
                    key={dish.id}
                    dish={dish}
                    ingredientsList={ingredients}
                  />
                ))
              )}

              {statusDishesList === 'success' && dishList && !searchResult.length && !isRefetching && (
                <Typography sx={{ fontSize: 14, fontWeight: 'bold', p: '10px' }}>Ooops brak dań dla wybranego filtrowania...</Typography>
              )}
            </SelectedDishContainer>

            {!!totalItems && <CartBanner />}
          </EmployeeRestaurantsPageContent>
        </>
      )}
    </EmployeeRestaurantsPageContainer>
  );
};

export default EmployeeSelectedRestaurantPage;
