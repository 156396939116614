import React from 'react';
import { Container, Stack, Typography, styled, CardMedia, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import noActiveOrders from '../../../../../assets/noActiveOrders.png';
import { RoutesVars } from '../../../../../const/constRoutes';
import { backgroundColors } from '../../../../../thema/AppThema';

const MakeOrdersConatiner = styled(Container)(() => ({ padding: 0 }));

interface Props {
  hasActiveOrders: boolean;
  userId: string;
}

const MakeOrders: React.FC<Props> = ({ hasActiveOrders, userId }) => {
  const navigate = useNavigate();
  const handleRedirectRestaurantsList = () => {
    userId && navigate(RoutesVars.EMPLOYEE_RESTAURANTS(userId));
  };

  return (
    <MakeOrdersConatiner data-testid="Make-Orders__container-testid">
      <Stack
        direction="column"
        alignItems="center"
        sx={{ p: '28px 20px', mb: '30px', border: '1px solid rgba(139, 138, 138, 0.295)', bgcolor: backgroundColors.card }}
      >
        <CardMedia
          component="img"
          src={noActiveOrders}
          sx={{ height: '110px', width: '85px', mb: '10px' }}
        />
        {/* // TODO: dodać uśmiechniętą minkę do koszyka */}

        {!hasActiveOrders && (
          <Typography sx={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>
            Nie masz aktywnych zamówień
          </Typography>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={handleRedirectRestaurantsList}
          sx={{
            p: '8px 40px',
            mt: '10px',
            maxWidth: '280px',
            borderRadius: '30px',
            fontSize: 15,
            fontWeight: 'bold',
            textTransform: 'none',
          }}
          data-testid="test-id-create-new-order"
        >
          Złóż nowe zamówienie
        </Button>
      </Stack>

    </MakeOrdersConatiner>
  );
};
export default MakeOrders;
