import * as React from 'react';
import { Typography, CardContent, Card as MUICard, styled, IconButtonProps, IconButton, Collapse, CardHeader } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export type SectionProps = {
  title: string;
  children: React.ReactNode;
};

export const Section: React.FC<SectionProps> = ({ title, children }) => (
  <>
    <Typography sx={{ fontSize: '0.9rem' }} color="text.secondary" gutterBottom>
      {title}
    </Typography>
    {children}
  </>
);

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})((props) => ({
  transform: !props.expand ? 'rotate(0deg)' : 'rotate(180deg)',
  margin: 'auto',
  transition: props.theme.transitions.create('transform', {
    duration: props.theme.transitions.duration.shortest,
  }),
}));

interface Props {
  title: string;
  subheader: string;
  id: string | number;
  children: React.ReactNode;
}

const Card: React.FC<Props> = ({ title, subheader, id, children }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = (): void => { setExpanded(!expanded); };

  return (
    <MUICard variant="outlined" sx={{ position: 'relative', marginBottom: '6px' }}>
      <CardHeader
        action={(
          <ExpandMore
            expand={expanded}
            aria-label="show more"
            onClick={handleExpandClick}
            data-testid={`expand-more-${id}`}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        )}
        title={title}
        subheader={subheader}
        sx={{
          '& .MuiCardHeader-content': {
            display: 'flex',
            flexDirection: 'column-reverse',
          },
        }}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {children}
        </CardContent>
      </Collapse>
    </MUICard>
  );
};

export default Card;
