import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../../components/Typography';

import productCurvyLines from '../assets/productCurvyLines.png';
import productHowItWorks1 from '../assets/productHowItWorks1.svg';
import productHowItWorks2 from '../assets/productHowItWorks2.svg';
import productHowItWorks3 from '../assets/productHowItWorks3.svg';

const item: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 2,
  mb: { xs: 6, md: 8 },
};

const title: SxProps<Theme> = {
  mb: 1,
  textAlign: 'center',
};

const image = {
  height: 55,
  mb: { xs: 3, md: 4 },
};

function ProductHowItWorks(): JSX.Element {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', bgcolor: '#f3eeef', overflow: 'hidden' }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          src={productCurvyLines}
          alt="curvy lines"
          sx={{
            pointerEvents: 'none',
            position: 'absolute',
            top: -180,
            opacity: 0.7,
          }}
        />
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 14, textAlign: 'center' }}>
          Dostępne usługi
        </Typography>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box
                  component="img"
                  src={productHowItWorks1}
                  alt="suitcase"
                  sx={image}
                />
                <Typography variant="h6" sx={title}>
                  Moduł administracyjny
                </Typography>
                <Typography variant="h5" align="center">
                  Wygodny i intuicyjny moduł do zarządzania benefitami
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box
                  component="img"
                  src={productHowItWorks2}
                  alt="graph"
                  sx={image}
                />
                <Typography variant="h6" sx={title}>
                  Typy użytkowników
                </Typography>
                <Typography variant="h5" align="center">
                  Indiwidualne uprawnienia uzytkowników serwisu np. pracownik, administrator
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box
                  component="img"
                  src={productHowItWorks3}
                  alt="clock"
                  sx={image}
                />
                <Typography variant="h6" sx={title}>
                  Przejrzyste zamawianie
                </Typography>
                <Typography variant="h5" align="center">
                  Łatwy wybór miejsca, godziny i posiłku, dzięki praktycznym filtrom
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box
                  component="img"
                  src={productHowItWorks1}
                  alt="suitcase"
                  sx={image}
                />
                <Typography variant="h6" sx={title}>
                  Przyszłe zamówienia
                </Typography>
                <Typography variant="h5" align="center">
                  Możliwość planowania zamówień z wyprzedzeniem
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box
                  component="img"
                  src={productHowItWorks2}
                  alt="graph"
                  sx={image}
                />
                <Typography variant="h6" sx={title}>
                  Historia zamówień
                </Typography>
                <Typography variant="h5" align="center">
                  Zestawienie historii zamówień możliwością podglądu szczegółów
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box
                  component="img"
                  src={productHowItWorks3}
                  alt="clock"
                  sx={image}
                />
                <Typography variant="h6" sx={title}>
                  Wiele punktów odbioru
                </Typography>
                <Typography variant="h5" align="center">
                  Dostawa do wielu lokalizacji, w różnych godzinach
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box
                  component="img"
                  src={productHowItWorks1}
                  alt="suitcase"
                  sx={image}
                />
                <Typography variant="h6" sx={title}>
                  Indywidualne konta pracowników
                </Typography>
                <Typography variant="h5" align="center">
                  Każdy z pracowników sam składa zamówienia
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box
                  component="img"
                  src={productHowItWorks2}
                  alt="graph"
                  sx={image}
                />
                <Typography variant="h6" sx={title}>
                  Elastyczne rozliczenia
                </Typography>
                <Typography variant="h5" align="center">
                  Wygodne bezgotówkowe płatności, czytelny panel oraz gotowe wydruki dla księgowych
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box
                  component="img"
                  src={productHowItWorks3}
                  alt="clock"
                  sx={image}
                />
                <Typography variant="h6" sx={title}>
                  Oszczędność pieniędzy
                </Typography>
                <Typography variant="h5" align="center">
                  Płatność tylko i wyłącznie za wykorzystane dofinansowanie posiłków
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Box>
  );
}

export default ProductHowItWorks;
