import React, { useState, FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Divider, Grid, LinearProgress, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { RestaurantInfo } from '../../../interfaces';
import { GridItem } from '../../Company/CompanyMainPage/CompanyMainPage';
import { fontColors } from '../../../thema/AppThema';
import { getRestaurantInfo } from '../../../api/services/api/apiRestaurantInfo';
import strings from '../../../assets/strings/restaurantsStrings.json';
import ErrorPage from '../../Error/ErrorPage';

const RestaurantMainPage: FC = () => {
  const params = useParams();

  const restaurantId = params.id as unknown as string;

  const [restaurantInfo, setRestaurantInfo] = useState<RestaurantInfo>();

  const query = useQuery({
    queryKey: ['getRestaurantInfo', restaurantId],
    queryFn: () => getRestaurantInfo(restaurantId),
    meta: { errorMessage: 'Błąd podczas ładowania informacji o resturacji.' },
    retry: false,
  });

  useEffect(() => {
    if (query.data) { setRestaurantInfo(query.data); }
  }, [query.data]);

  const items = [
    { title: strings.mainPage.cartItem1, value: restaurantInfo?.upcoming },
    { title: strings.mainPage.cartItem2, value: `${restaurantInfo?.payForDeliveredOrders} zł` },
    { title: strings.mainPage.cartItem3, value: `${restaurantInfo?.payForTomorrowOrders} zł` },
    { title: strings.mainPage.cartItem4, value: restaurantInfo?.placedOrdersCount },
  ];

  return (
    <Container disableGutters sx={{ px: 2 }}>
      {query.isError && <Container sx={{ mt: 8 }}><ErrorPage /></Container>}
      {query.isLoading && <LinearProgress />}
      {query.isSuccess && restaurantInfo && (
        <>
          <Typography variant="h2" component="h1" sx={{ pb: 3, pt: 4, fontWeight: 'bold' }}>{restaurantInfo.name}</Typography>
          <Box display="flex" justifyContent="center" sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              {items.map((item) => (
                <Grid item xs={4} sm={4} md={3} key={item.title}>
                  <GridItem sx={{ height: { xs: 120, sm: 155, md: 160, lg: 155 } }}>
                    <Typography sx={{ fontSize: 22, flexGrow: 1, color: fontColors.lightDark }}>
                      {item.title}
                    </Typography>
                    <Typography sx={{
                      fontSize: 28,
                      fontWeight: 'bold',
                      flexGrow: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    >
                      {item.value}
                    </Typography>
                  </GridItem>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box sx={{ my: 5 }}>
            <Typography sx={{ fontSize: 25, fontWeight: 'bold' }}>
              {strings.mainPage.deliveryHoursTile.replace('{{restuarantName}}', restaurantInfo.name)}
            </Typography>
            <Typography sx={{ fontSize: 16 }}>{strings.mainPage.deliveryHoursText}</Typography>

            <Box sx={{ pt: 1, pl: 2 }}>
              {restaurantInfo.locations.map((location, index) => (
                <Box key={location.id}>
                  <Typography variant="h3" sx={{ fontSize: 20, fontWeight: 'bold', mt: '10px' }}>
                    {location.time}
                  </Typography>
                  <Typography component="li" sx={{ listStyle: 'none', fontSize: 16, pt: '2px' }}>
                    {`${location.name}, ${location.address.street} `}
                  </Typography>
                  <Typography component="li" sx={{ listStyle: 'none', fontSize: 16, fontStyle: 'italic' }}>
                    {`${location.address.city}, ${location.address.postcode}`}
                  </Typography>
                  {index !== (restaurantInfo.locations.length - 1) && <Divider sx={{ m: '10px 0 15px' }} />}
                </Box>
              ))}
            </Box>
          </Box>
        </>
      )}
    </Container>
  );
};

export default RestaurantMainPage;
