import React from 'react';
import { Button, CircularProgress, Container, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import AddIcon from '@mui/icons-material/Add';
import { getEmployees } from "../../../api/apiEmployees";
import Card, { Section } from '../../../components/common/Card';
import { RoutesVars } from '../../../const/constRoutes';
import BreadcrumbsComponent from '../../../components/common/BreadcrumbsComponent/BreadcrumbsComponent';
import SearchInput from '../../../components/common/SearchInput';
import { Employee } from '../../../interfaces';
import MainContainer from '../../../components/common/MainContainer';
import useHandleError from '../../Error/useHandleError';

const CompanyEmployeesPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { handleError } = useHandleError();
  const { id: companyId } = useParams();

  const [searchResult, setSearchResult] = React.useState<Employee[]>([]);

  const { data, status } = useQuery(['employeesIndex'], getEmployees({ params: { company: companyId } }), {
    onSuccess: (data: Employee[]) => setSearchResult(() => data),
    onError: (error: Error) => { handleError(error.message, 'EMPLOYEES_INDEX', 'GET'); },
  });

  const searchKeys = React.useMemo(() => ['name', 'surname'], []);
  const searchCallback = React.useCallback(
    (results: Employee[]) => setSearchResult(results),
    [],
  );

  if (!companyId) return null;

  const handleAddEmployeeClick = () => { navigate(RoutesVars.NEW_EMPLOYEE(companyId)); };

  return (
    <MainContainer>
      <Container sx={{ alignSelf: 'flex-start', pt: '19px', pb: '21px' }}>
        <BreadcrumbsComponent
          ariaLabel="breadcrumb-company-employees-page"
          breadcrumbContents={[
            {
              name: "Strona główna",
              href: `${RoutesVars.MAIN}`,
              testId: 'breadcrumb-main-page',
            },
            {
              name: "Moja firma",
              href: `${RoutesVars.COMPANY(companyId)}`,
              testId: 'breadcrumb-company-page',
            },
          ]}
          breadcrumbCurrentPage={{
            name: "Pracownicy",
            testId: 'breadcrumb-1',
          }}
        />
      </Container>
      <Container>
        <Typography
          variant="h2"
          component="h1"
          sx={{ fontWeight: 'bold', pb: '34px' }}
        >
          Pracownicy
        </Typography>
      </Container>
      <Container sx={{ pb: '36px' }}>
        <Button
          variant="contained"
          aria-label="Dodaj pracownika"
          startIcon={<AddIcon />}
          sx={{ borderRadius: '18px' }}
          onClick={handleAddEmployeeClick}
        >
          Dodaj pracownika
        </Button>
      </Container>
      {
        status === 'loading' && <CircularProgress />
      }
      {
        status === 'success' && (
          <>
            <Container sx={{ pb: '36px' }}>
              {data && <SearchInput list={data} keys={searchKeys} callback={searchCallback} />}
            </Container>
            <Container sx={{ paddingBottom: '36px' }}>
              {
                status === 'success' &&
                searchResult.map((employee) => (
                  <Card
                    key={employee.account}
                    id={employee.account}
                    title={`${employee.name} ${employee.surname}`}
                    subheader="Imię, nazwisko"
                  >
                    <Section title="Adres e-mail">
                      {employee.name}
                    </Section>
                    <Section title="Nr telefonu">
                      {employee.name}
                    </Section>
                  </Card>
                ))
              }
            </Container>
          </>
        )
      }
    </MainContainer>
  );
};

export default CompanyEmployeesPage;
