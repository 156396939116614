import { OrdersSummaryTableData } from "./OrdersSummaryTable";

export function createRowData(
  user: string,
  employeeName: string,
  id: string,
  dateCreated: string,
  deliveryDate: string,
  locationName: string,
  dueAmount: string,
): OrdersSummaryTableData {
  return {
    user,
    employeeName,
    id,
    dateCreated,
    deliveryDate,
    locationName,
    dueAmount,
  };
}
