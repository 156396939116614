import React, { FC, useMemo } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import './DateScrollingMenu.css';

import { Alert } from "@mui/material";
import { LeftArrow, RightArrow } from "../../../../../components/common/HorizontalScrollingMenu/arrows";
import useDrag from "../../../../../components/common/HorizontalScrollingMenu/useDrag";
import usePreventBodyScroll from "../../../../../components/common/HorizontalScrollingMenu/usePreventBodyScroll";
import DateFilter from "./DateFilter";
import useMetadata from "../../../../../shared/employeeShared/hooks/useMetadata";
import { Metadata } from "../../../../../const/constEmployee";
import { AvailableDates } from "../../../../../interfaces";

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

interface Props {
  deliveryDates: AvailableDates,
  hasLeftArrow?: boolean,
  hasRightArrow?: boolean,
  setIsAlertDialogOpen: (state: boolean) => void,
}

const DateScrollingMenu: FC<Props> = ({ deliveryDates, hasLeftArrow = true, hasRightArrow = true, setIsAlertDialogOpen }) => {
  const { dragStart, dragStop, dragMove } = useDrag();
  const { disableScroll, enableScroll } = usePreventBodyScroll();
  const { getMetadata } = useMetadata();

  const location = getMetadata(Metadata.LOCATION_ID) as string;

  const datesAndLocations = Object.entries(deliveryDates).filter((dateAndLocations) => dateAndLocations[1].includes(Number(location)));
  const items = useMemo((() => [...datesAndLocations.map((entity) => entity[0])]), [datesAndLocations]);

  const handleDrag = ({ scrollContainer }: scrollVisibilityApiType) => (
    ev: React.MouseEvent,
  ) => dragMove(ev, (posDiff) => {
    if (scrollContainer.current) {
      // eslint-disable-next-line no-param-reassign
      scrollContainer.current.scrollLeft += posDiff;
    }
  });

  if (!items.length && location) {
    return <Alert severity="warning" sx={{ fontSize: 12, mt: 1 }}>Brak dostepnych dat</Alert>;
  }

  return (
    <div onMouseEnter={disableScroll} onMouseLeave={() => { enableScroll(); dragStop(); }}>
      <ScrollMenu
        LeftArrow={hasLeftArrow ? LeftArrow : null}
        RightArrow={hasRightArrow ? RightArrow : null}
        onWheel={onWheel}
        onMouseDown={() => dragStart}
        onMouseUp={() => dragStop}
        onMouseMove={handleDrag}
        scrollContainerClassName="date-scrolling-menu-items-container"
      >
        {items.map((date) => (
          <DateFilter
            itemId={date}
            key={date}
            date={date}
            setIsAlertDialogOpen={setIsAlertDialogOpen}
          />
        ))}
      </ScrollMenu>
    </div>
  );
};
export default DateScrollingMenu;

