import React from 'react';
import { Container } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Footer from '../../components/common/Footer';
import LayoutContainer from '../components/AppBarComponent/LayoutContainer';
import MainAppBar from '../../components/common/MainAppBar';
import RestaurantDrawer from '../../components/common/Drawer/RestaurantDrawer';
import { backgroundColors } from '../../thema/AppThema';

const RestaurantLayout: React.FunctionComponent = () => (
  <LayoutContainer>
    <MainAppBar>
      <RestaurantDrawer />
    </MainAppBar>
    <Container
      sx={{ padding: 0, flexGrow: '1', bgcolor: backgroundColors.main }}
    >
      <Outlet />
    </Container>
    <Footer />
  </LayoutContainer>
);

export default RestaurantLayout;
