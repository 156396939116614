import React from 'react';
import { CardActionArea } from '@mui/material';

import RestaurantItem from './components/RestaurantItem/RestaurantItem';
import { Restaurant, Tag } from '../../../interfaces';

interface Props {
  availableRestaurants: Restaurant[],
  handleCardClick: (restaurantId: string) => () => void
  cuisinesList: Tag[];
}

const AvailableRestaurants: React.FC<Props> = ({ availableRestaurants, handleCardClick, cuisinesList }) => (
  <>
    {availableRestaurants.map((restaurant) => (
      <CardActionArea
        onClick={handleCardClick(`${restaurant.id}`)}
        key={restaurant.id}
        data-testid={`test-id-resturant-cart-${restaurant.id}`}
      >
        <RestaurantItem
          name={restaurant.name}
          imgSrc={restaurant.img}
          availableCuisines={restaurant.categories}
          cuisinesList={cuisinesList}
        />
      </CardActionArea>
    ))}

  </>
);
export default AvailableRestaurants;
