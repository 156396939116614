import React from 'react';
import IconButton from '@mui/material/IconButton';
import { Stack, Typography } from '@mui/material';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { useCart } from 'react-use-cart';
import { useNavigate } from 'react-router-dom';

import { RoutesVars } from '../../../const/constRoutes';
import { UserContext } from '../../../contexts/userContext/userContext';

const CartNavBar: React.FC = () => {
  const { state: { user } } = React.useContext(UserContext);
  const { totalItems } = useCart();
  const navigate = useNavigate();

  if (!user) return null;
  if (!user.isEmployee) return null;

  const handleNavigate = () => (totalItems > 0 ? navigate(RoutesVars.EMPLOYEE_ORDER_SUMMARY(user.employeeId)) : null);

  return (
    <Stack direction="row" sx={{ position: 'relative' }}>
      <IconButton
        color="inherit"
        aria-label="Koszyk z zamowieniem"
        onClick={handleNavigate}
        sx={{ mr: '10px' }}
      >
        <ShoppingBagOutlinedIcon sx={{ height: '23px', width: '23px' }} />
      </IconButton>
      <Stack
        sx={{
          position: 'absolute',
          right: '13px',
          bottom: '6px',
          zIndex: '1',
          backgroundColor: '#A1C181',
          width: '16px',
          height: '16px',
          borderRadius: '10px',
        }}
      >
        <Typography
          data-testid="total-unique-items-in-cart-navbar-cart-button"
          sx={{
            position: 'absolute',
            top: '53%',
            left: '46%',
            transform: 'translate(-50%, -50%)',
            color: 'rgba(255, 255, 255, 0.858)',
            fontWeight: 'bold',
          }}
        >
          {totalItems}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default CartNavBar;
