import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';

import { DishOrder } from '../../../../../interfaces';

interface Props {
  dish: DishOrder;
}

const OrderItemDishes: FC<Props> = ({ dish }) => {
  const { quantity, comment, dishName } = dish;
  const shouldDisplayComment = comment.length !== 0;

  return (
    <Stack>
      <Stack direction="row">
        <Typography sx={{ fontSize: 16, mr: '8px' }}>{`${quantity}x`}</Typography>
        <Typography sx={{ fontSize: 16 }}>{dishName}</Typography>
      </Stack>

      {shouldDisplayComment && <Typography sx={{ fontSize: 10, ml: '5px' }}>{`Komentarz: ${comment}`}</Typography>}
    </Stack>
  );
};
export default OrderItemDishes;
