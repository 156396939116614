import React, { FC, useState } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { Location } from '../../../../../interfaces';
import './LocationScrollingMenu.css';

import { LeftArrow, RightArrow } from "../../../../../components/common/HorizontalScrollingMenu/arrows";
import useDrag from "../../../../../components/common/HorizontalScrollingMenu/useDrag";
import usePreventBodyScroll from "../../../../../components/common/HorizontalScrollingMenu/usePreventBodyScroll";
import LocationFilter from "./LocationFilter";

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

interface Props {
  deliveryLocations: Location[],
  hasLeftArrow?: boolean,
  hasRightArrow?: boolean,
  setIsAlertDialogOpen: (state: boolean) => void,
}

const LocationScrollingMenu: FC<Props> = ({
  deliveryLocations,
  hasLeftArrow = true,
  hasRightArrow = true,
  setIsAlertDialogOpen,
}) => {
  const { dragStart, dragStop, dragMove } = useDrag();
  const { disableScroll, enableScroll } = usePreventBodyScroll();

  const [items] = useState(deliveryLocations);

  const handleDrag = ({ scrollContainer }: scrollVisibilityApiType) => (
    ev: React.MouseEvent,
  ) => dragMove(ev, (posDiff) => {
    if (scrollContainer.current) {
      // eslint-disable-next-line no-param-reassign
      scrollContainer.current.scrollLeft += posDiff;
    }
  });

  return (
    <div onMouseEnter={disableScroll} onMouseLeave={() => { enableScroll(); dragStop(); }}>
      <ScrollMenu
        LeftArrow={hasLeftArrow ? LeftArrow : null}
        RightArrow={hasRightArrow ? RightArrow : null}
        onWheel={onWheel}
        onMouseDown={() => dragStart}
        onMouseUp={() => dragStop}
        onMouseMove={handleDrag}
        scrollContainerClassName="location-scrolling-menu-items-container"
      >
        {items.map((location) => (
          <LocationFilter
            itemId={`${location.id}`}
            key={location.id}
            location={location}
            setIsAlertDialogOpen={setIsAlertDialogOpen}
          />
        ))}
      </ScrollMenu>
    </div>
  );
};
export default LocationScrollingMenu;

