import React from 'react';
import { Alert, CircularProgress, Container, Divider, Stack, styled, Typography } from '@mui/material';

import { AvailableDates, Location } from '../../../interfaces';
import LocationScrollingMenu from './components/LocationScrollingMenu/LocationScrollingMenu';
import DateScrollingMenu from './components/DateScrollingMenu/DateScrollingMenu';
import { Status } from '../../../selectors';
import useMetadata from '../../../shared/employeeShared/hooks/useMetadata';
import { Metadata } from '../../../const/constEmployee';

const DeliveryDetailsContainer = styled(Container)(() => ({
  padding: 0,
  overflow: 'hidden',
}));

interface Props {
  deliveryLocations: Location[],
  deliveryDates: AvailableDates,
  status: Status,
  statusDates: Status,
  setIsAlertDialogOpen: (state: boolean) => void,
}

const DeliveryDetails: React.FC<Props> = ({ deliveryLocations, deliveryDates, status, statusDates, setIsAlertDialogOpen }) => {
  const { getMetadata } = useMetadata();
  const location = getMetadata(Metadata.LOCATION_ID) as string;
  const date = getMetadata(Metadata.DATE) as string;

  const isDeliveryDataError = status !== 'loading' && !date && !location;

  return (
    <DeliveryDetailsContainer disableGutters>
      <Typography component="h2" sx={{ mt: 1, ml: '5px', fontSize: 20, fontWeight: 'bold' }}>Dostawa</Typography>
      <Typography component="h2" sx={{ ml: '5px', fontSize: 15, mb: '17px' }}>
        Określ szczegóły dostawy aby wyświetlić restauracje.
      </Typography>

      <Typography id="Miejsce-i-godzina-dostawy" component="h2" sx={{ mt: 1, ml: '5px', fontSize: 16 }}>
        1. Miejsce i godzina dostawy
      </Typography>

      <LocationScrollingMenu deliveryLocations={deliveryLocations} setIsAlertDialogOpen={setIsAlertDialogOpen} />

      <Typography id="Dzień-dostawy" component="h2" sx={{ mt: 1, ml: '5px', fontSize: 16 }}>
        2. Dzień dostawy
      </Typography>

      {statusDates === 'loading' && (
        <Stack flexDirection="row" justifyContent="center">
          <CircularProgress data-testid="test-id-delivery-date-loader" />
        </Stack>
      )}

      {statusDates === 'success' && deliveryDates && location && (
        <DateScrollingMenu deliveryDates={deliveryDates} setIsAlertDialogOpen={setIsAlertDialogOpen} />
      )}

      {isDeliveryDataError && (
        <Alert severity="info" sx={{ fontSize: 12, mt: 1 }}>Wybierz miejsce dostawy i godzinę, aby wyświetlić dostępne dni dostawy</Alert>
      )}

      <Divider sx={{ m: '13px 0' }} />
    </DeliveryDetailsContainer>
  );
};

export default DeliveryDetails;
