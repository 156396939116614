import { CompanyInfo } from "../../../interfaces";
import { axiosApi } from "../../axios";
import { routes } from "../../routes";

const getCompanyInfo = async (id: string): Promise<CompanyInfo> => {
  const response = await axiosApi.get<CompanyInfo>(routes.COMPANIES_INFO(id));
  return response.data;
};

export {
  getCompanyInfo,
};
