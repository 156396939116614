import React, { FC } from 'react';
import { Container, Typography } from '@mui/material';

import MainContainer from '../../../components/common/MainContainer';
import CompanyInvoicesTable from './CompanyInvoicesTable/CompanyInvoicesTable';
import { createRowData } from './CompanyInvoicesTable/utils';

const CompanyOrdersSummaryPage: FC = () => {
  const mockedRows = [
    createRowData(1, 'Bistro Szpak', 12, '705,98 zł', '2022-05-02 | 2022-06-02', '2022-07-02', 'Oczekujące'),
    createRowData(2, 'Smaczne Obiady', 44, '2800,00 zł', '2022-05-03 | 2022-06-03', '2022-07-03', 'Zapłacono'),
    createRowData(3, 'Catering na szybko', 23, '1542,78 zł', '2022-05-01 | 2022-06-01', '2022-07-01', 'Oczekujące'),
  ];

  return (
    <MainContainer>
      <Container sx={{ paddingBottom: '30px' }}>
        <Typography variant="h2" component="h1">Faktury rozliczeniowe</Typography>
      </Container>
      <Container>
        <CompanyInvoicesTable rows={mockedRows} />
      </Container>
    </MainContainer>
  );
};

export default CompanyOrdersSummaryPage;

