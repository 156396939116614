import React from 'react';
import { List, ListItem, Link, Typography, Button } from '@mui/material';
import { styled } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { AppTheme } from '../../../thema/AppThema';
import { RoutesVars } from '../../../const/constRoutes';
import { UserContext } from '../../../contexts/userContext/userContext';
import BaseDrawer, { DrawerLink } from './BaseDrawer';

const DrawerListItem = styled(ListItem)(() => ({
  marginRight: 2,
  [AppTheme.breakpoints.up('md')]: {
    marginRight: 20,
  },
}));

type UserDrawerProps = {
  isOpen?: boolean;
  onCloseCallback?: () => undefined;
};

const UserDrawer: React.FC<UserDrawerProps> = ({ isOpen, onCloseCallback }) => {
  const { state: { user }, actions } = React.useContext(UserContext);
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    actions.logoutUser();
    navigate(RoutesVars.LOGIN);
  };

  return (
    <BaseDrawer isOpen={isOpen} onCloseCallback={onCloseCallback}>
      <List>
        {
          user && user.isEmployee && (
            <>
              <DrawerListItem divider key="1">
                <DrawerLink path={RoutesVars.MY_POFILE(user.employeeId)} onClick={onCloseCallback}>
                  <Typography fontSize={27}>
                    Mój profil
                  </Typography>
                </DrawerLink>
              </DrawerListItem>
              <DrawerListItem divider key="2">
                <DrawerLink path={RoutesVars.EMPLOYEE_ORDERS(user.employeeId)} onClick={onCloseCallback}>
                  <Typography fontSize={27}>
                    Moje zamówienia
                  </Typography>
                </DrawerLink>
              </DrawerListItem>
              <DrawerListItem divider key="3">
                <DrawerLink path={RoutesVars.EMPLOYEE_RESTAURANTS(user.employeeId)} onClick={onCloseCallback}>
                  <Typography fontSize={27}>
                    Restauracje
                  </Typography>
                </DrawerLink>
              </DrawerListItem>
              <DrawerListItem divider key="4">
                <DrawerLink path={RoutesVars.EMPLOYEE_ORDER_SUMMARY(user.employeeId)} onClick={onCloseCallback}>
                  <Typography fontSize={27}>
                    Koszyk
                  </Typography>
                </DrawerLink>
              </DrawerListItem>
            </>
          )
        }
        <DrawerListItem divider key="5">
          <Link href="#top" underline="none" color="secondary" onClick={onCloseCallback}>
            <Typography fontSize={27}>
              Pomoc
            </Typography>
          </Link>
        </DrawerListItem>
      </List>
      <Button
        variant="contained"
        onClick={handleLogoutClick}
        sx={{ alignSelf: 'flex-end', borderRadius: 40, mr: 2 }}
      >
        Wyloguj się
      </Button>
    </BaseDrawer>
  );
};

UserDrawer.defaultProps = {
  isOpen: false,
  onCloseCallback: () => undefined,
};

export default UserDrawer;
