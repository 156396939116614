// 400 The request verb is invalid.
// 401 Unauthorized
// 403 Forbidden
// 404 Not found

// Error code structure:
//
// MP-statusCode-endpointKey-requestType
// MP-403-1-1
// MP(Mój Posiłek) + Forbidden(403) + location endpoint + GET method

export const ERROR_MESSAGES: Record<string, string> = {
  ERROR_MESSAGES_DEFAULT: 'MP-0',
  ERROR_MESSAGES_400: 'MP-400',
  ERROR_MESSAGES_401: 'MP-401',
  ERROR_MESSAGES_403: 'MP-403',
  ERROR_MESSAGES_404: 'MP-404',
  ERROR_MESSAGES_500: 'MP-500',
};

export const ENDPOINT_KEY: Record<string, string> = {
  DEFAULT: '0',
  LOCATIONS: '1',
  RESTAURANT_DELIVERY_DATES: '2',
  RESTAURANTS: '3',
  DISHES: '4',
  DISH: '5',
  TAGS_INDEX: '6',
  ORDERS: '7',
  RESTAURANT_DISHES_LIST: '8',
  EMPLOYEES_INDEX: '9',
};

export const REQUEST_TYPE: Record<string, string> = {
  DEFAULT: '0',
  GET: '1',
  POST: '2',
  DELETE: '3',
  PUT: '4',
  PATCH: '5',
};

