import { Result, ErrorResult } from './axios';

const sendPasswordReset = async (): Promise<Result | ErrorResult> => {
  try {
    // waiting for api to be implemented
    return Promise.resolve({ success: true });
  } catch (error) {
    return new ErrorResult(error);
  }
};

const requestRepository = {
  sendPasswordReset,
};

export default requestRepository;
export { sendPasswordReset };
