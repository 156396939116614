import React, { FC, useCallback, useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Button, Typography } from '@mui/material';

import FilterDialog from './FilterDialog';
import { Company, HistoryOrder, Location } from '../../../../interfaces';

interface Props {
  orders: HistoryOrder[],
  companies: Company[],
  locations: Location[],
  setVisibleOrders: (orders: HistoryOrder[]) => void;
}

const OrdersFilterer: FC<Props> = ({ orders, companies, locations, setVisibleOrders }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleFilterReset = useCallback(() => {
    setVisibleOrders(orders);
    setDialogOpen(false);
  }, [orders, setVisibleOrders]);

  const handleClose = useCallback(() => setDialogOpen(false), []);

  const handleFilter = useCallback(
    (orders: HistoryOrder[]) => {
      setVisibleOrders(orders);
      setDialogOpen(false);
    },
    [setVisibleOrders],
  );

  return (
    <>
      <Button
        onClick={() => setDialogOpen(true)}
        color="secondary"
        variant="text"
        aria-label="filter"
        sx={{ mt: 0.4, textTransform: 'none', px: 0.4, mx: 0.5 }}
      >
        <FilterListIcon sx={{ width: 20, height: 25, mr: 0.4 }} />
        <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
          Filtruj
        </Typography>
      </Button>
      <FilterDialog
        items={orders}
        companies={companies}
        locations={locations}
        open={dialogOpen}
        handleClose={handleClose}
        handleFilter={handleFilter}
        handleReset={handleFilterReset}
      />
    </>

  );
};

export default OrdersFilterer;
