import React from 'react';
import {
  isTokenExpired,
  isAuthenticated,
  setRefreshToken,
  setToken,
  setTokenExpirationTime,
  getToken,
} from './utils';

type AuthContextProps = {
  userToken: undefined | null | string;
  isTokenExpired: () => boolean;
  setToken: (token: string) => void;
  setRefreshToken: (token: string) => void;
  isAuthenticated: () => boolean;
  setTokenExpirationTime: (expiresIn: number) => void;
};

const AuthContext = React.createContext<AuthContextProps>({
  userToken: getToken(),
  isAuthenticated,
  isTokenExpired,
  setToken,
  setTokenExpirationTime,
  setRefreshToken,
});

const useAuthContext = (): AuthContextProps => React.useContext(AuthContext);

AuthContext.displayName = 'AuthContext';

export default AuthContext;

export {
  useAuthContext,
  getToken,
  setToken,
};
