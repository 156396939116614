import { routes } from './routes';
import { createResource, deleteResource, getResources, Result } from './axios';
import { Company } from '../interfaces';

export const getCompanies = getResources<Company>(routes.COMPANIES_INDEX);
export const createCompany = createResource<Company>(routes.COMPANIES());
export const deleteCompany = (id: string | number): Promise<Result> => deleteResource(routes.COMPANIES(id));

const requestRepository = {
  getCompanies,
  createCompany,
  deleteCompany,
};

export default requestRepository;
