/* eslint-disable max-len */
import { routes } from './routes';
import { getResource, getResources } from './axios';
import { Restaurant } from '../interfaces';

type GetRestaurantPromise = () => Promise<Restaurant>;
type GetRestaurantsPromise = () => Promise<Restaurant[]>;
const getRestaurant = (id: string | number): GetRestaurantPromise => getResource<Restaurant>(routes.RESTAURANTS(id));
const getRestaurants = ({ id, params }: { id?: string | number, params?: unknown } = {}): GetRestaurantsPromise => getResources<Restaurant>(routes.RESTAURANTS(id), params);

const requestRepositoryRestaurant = {
  getRestaurant,
  getRestaurants,
};

export default requestRepositoryRestaurant;
