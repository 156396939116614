import { Company } from "../../../interfaces";
import { axiosApi } from "../../axios";
import { routes } from "../../routes";

const getCompaniesNEW = async (): Promise<Company[]> => {
  const response = await axiosApi.get<Company[]>(routes.COMPANIES_INDEX);
  return response.data;
};

export {
  getCompaniesNEW,
};
