import React, { FC, useState } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { Tag } from '../../../../../interfaces';
import './CategoriesScrollingMenu.css';

import { LeftArrow, RightArrow } from "../../../../../components/common/HorizontalScrollingMenu/arrows";
import useDrag from "../../../../../components/common/HorizontalScrollingMenu/useDrag";
import usePreventBodyScroll from "../../../../../components/common/HorizontalScrollingMenu/usePreventBodyScroll";
import CategoriesFilter from "./CategoriesFilter";

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

interface Props {
  cuisines: Tag[],
  hasLeftArrow?: boolean,
  hasRightArrow?: boolean,
  selectedCuisines: string[],
  setSelectedCuisines: (value: React.SetStateAction<string[]>) => void
}

const CategoriesScrollingMenu: FC<Props> = ({
  cuisines,
  hasLeftArrow = true,
  hasRightArrow = true,
  selectedCuisines,
  setSelectedCuisines,
}) => {
  const { dragStart, dragStop, dragMove } = useDrag();
  const { disableScroll, enableScroll } = usePreventBodyScroll();

  const [items] = useState(cuisines);

  const handleDrag = ({ scrollContainer }: scrollVisibilityApiType) => (ev: React.MouseEvent) => dragMove(ev, (posDiff) => {
    if (scrollContainer.current) {
      // eslint-disable-next-line no-param-reassign
      scrollContainer.current.scrollLeft += posDiff;
    }
  });

  const isItemSelected = (id: string): boolean => !!selectedCuisines.find((el) => el === id);

  const handleItemClick = (itemId: string) => () => {
    const itemSelected = isItemSelected(itemId);

    setSelectedCuisines((currentSelected: string[]) => (itemSelected ?
      currentSelected.filter((el) => el !== itemId) :
      currentSelected.concat(itemId)));
  };

  return (
    <div onMouseEnter={disableScroll} onMouseLeave={() => { enableScroll(); dragStop(); }}>
      <ScrollMenu
        LeftArrow={hasLeftArrow ? LeftArrow : null}
        RightArrow={hasRightArrow ? RightArrow : null}
        onWheel={onWheel}
        onMouseDown={() => dragStart}
        onMouseUp={() => dragStop}
        onMouseMove={handleDrag}
        scrollContainerClassName="location-scrolling-menu-items-container"
      >
        {items.map((cuisin) => (
          <CategoriesFilter
            itemId={cuisin.internalName}
            key={cuisin.internalName}
            cuisin={cuisin}
            handleItemClick={handleItemClick(cuisin.internalName)}
            selected={isItemSelected(cuisin.internalName)}
          />
        ))}
      </ScrollMenu>
    </div>
  );
};
export default CategoriesScrollingMenu;

