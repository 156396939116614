import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, Container, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import PrintJs from 'print-js';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import PrintIcon from '@mui/icons-material/Print';

import { HistoryOrder } from '../../../interfaces';
import { historyOrdersSelector } from '../selectors';
import { getStatus } from '../../../selectors';
import { OrderApiResponse, OrderStatus, currentOrderStatuses } from '../../../interfaces/Order';
import OrdersFilterer from '../components/OrdersFilterer';
import CurrentOrderCard from '../components/CurrentOrderCard';
import PrintableOrderCard from '../../../components/common/Card/PrintableOrderCard';
import MainContainer from '../../../components/common/MainContainer';
import { getOrdersNEW, updateOrderNEW } from '../../../api/services/api/apiOrders';
import { QueryKeys } from '../../../api/services/api/queryKeys';
import SearchInput from '../../../components/common/SearchInput';
import ErrorPage from '../../Error/ErrorPage';
import { getLocationsNEW } from '../../../api/services/api/apiLocations';
import { getCompaniesNEW } from '../../../api/services/api/apiCompany';

const RestaurantCurrentOrdersPage: FC = () => {
  const queryCache = useQueryClient();
  const params = useParams();
  const restaurantId = params.id;

  const [searchResult, setSearchResult] = useState<HistoryOrder[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<HistoryOrder[]>([]);
  const [allOrders, setAllOrders] = useState<HistoryOrder[]>([]);
  const [printMode, setPrintMode] = useState(false);

  const { data: orders, status: orderStatus } = useQuery({
    queryKey: [QueryKeys.ORDER_INDEX_CURRENT, `restaurant-${restaurantId}`],
    queryFn: () => getOrdersNEW({ status__in: currentOrderStatuses.join(',') }),
    meta: { errorMessage: 'Błąd podczas ładowania aktualnych zamówień' },
    retry: 2,
  });

  const { data: locations, status: locationStatus } = useQuery({
    queryKey: [QueryKeys.LOCATIONS_INDEX, `restaurant-${restaurantId}`],
    queryFn: () => getLocationsNEW(),
    meta: { errorMessage: 'Błąd podczas ładowania lokalizacji' },
    retry: 2,
  });

  const { data: companies, status: companyStatus } = useQuery({
    queryKey: [QueryKeys.COMPANY_INDEX, `restaurant-${restaurantId}`],
    queryFn: () => getCompaniesNEW(),
    meta: { errorMessage: 'Błąd podczas ładowania firm' },
    retry: 2,
  });

  useEffect(() => {
    if (orders && locations && companies) {
      const filteredOrders = orders.filter((order) => (order.status !== OrderStatus.DELIVERED && order.status !== OrderStatus.REJECTED));
      const historyOrders = historyOrdersSelector(filteredOrders, locations, companies);

      setFilteredOrders(historyOrders);
      setAllOrders(historyOrders);
    }
  }, [orders, locations, companies]);

  const updateMutation = useMutation({
    mutationFn: ({ id, data }: { id: string, data: Partial<OrderApiResponse> }) => updateOrderNEW(id, data),
  });

  useEffect(() => {
    if (updateMutation.isSuccess) {
      queryCache.invalidateQueries([QueryKeys.ORDER_INDEX_CURRENT]);
      toast.success('Poprawnie edytowano status zamówienia.');
    }
    if (updateMutation.isError) { toast.error('Błąd podczas edycji posiłku.'); }
  }, [queryCache, updateMutation.isError, updateMutation.isSuccess]);

  const setVisibleOrders = (orders: HistoryOrder[]) => {
    if (locations && companies) {
      const historyOrders = historyOrdersSelector(orders, locations, companies);
      setFilteredOrders(historyOrders);
    }
  };

  useEffect(() => {
    if (printMode) PrintJs({ type: "html", printable: "current-orders" });
    setPrintMode(false);
  }, [printMode]);

  const printStuff = () => setPrintMode(() => true);

  const searchKeys = useMemo(() => ['id'], []);
  const searchCallback = useCallback(
    (results: HistoryOrder[]) => {
      setSearchResult(results);
    }, [],
  );

  const status = getStatus(orderStatus, locationStatus, companyStatus);
  const dataReady = orders && companies && locations;

  return (
    <MainContainer data-testid="restaurant-menu-page__container-testid">
      {status === "error" && <Container sx={{ mt: 8 }}><ErrorPage /></Container>}
      {status === 'loading' && <CircularProgress />}
      {status === 'success' && dataReady && (
        <>
          <Container sx={{ pb: 2.5 }}>
            <Typography component="h1" sx={{ fontSize: 32, fontWeight: 500 }}>
              Aktualne zamówienia
            </Typography>
          </Container>
          <Container sx={{ display: "flex", justifyConten: "space-between", width: "100%", pb: 1 }}>
            <Box sx={{ minWidth: 100, flexGrow: '3' }}>
              <SearchInput
                list={filteredOrders}
                keys={searchKeys}
                callback={searchCallback}
                label="Wyszukaj..."
                isDisabled={status !== 'success'}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: 'center' }}>
              <OrdersFilterer
                orders={allOrders}
                locations={locations}
                companies={companies}
                setVisibleOrders={setVisibleOrders}
              />
              <Button
                onClick={printStuff}
                color="secondary"
                variant="text"
                sx={{ mt: 0.4, px: 0, minWidth: 30 }}
              >
                <PrintIcon sx={{ width: 20, height: 25, mx: 0.4 }} />
              </Button>
            </Box>
          </Container>
          <Container id="current-orders">
            {searchResult.map((order) => {
              if (printMode) {
                return (
                  <>
                    <PrintableOrderCard key={order.id} order={order} />
                    <p>------------------------------------------------</p>
                  </>
                );
              }

              return <CurrentOrderCard key={order.id} order={order} action={updateMutation.mutate} />;
            })}
          </Container>
        </>
      )}
    </MainContainer>
  );
};

export default RestaurantCurrentOrdersPage;
