import { Location } from "../../../interfaces";
import { axiosApi } from "../../axios";
import { routes } from "../../routes";

const getLocationsNEW = async (): Promise<Location[]> => {
  const response = await axiosApi.get<Location[]>(routes.LOCATIONS());
  return response.data;
};

export {
  getLocationsNEW,
};
