/* eslint-disable max-len */
import React from 'react';
import { Typography, Link, CardMedia, styled, Button, Stack } from '@mui/material';

import { fontColors, AppTheme } from '../../../../../thema/AppThema';
import oferta1 from '../../../../../assets/oferta1.png';
import oferta2 from '../../../../../assets/oferta2.png';
import { MAIN_PAGE_BUTTONS_BG_COLOR, MAIN_PAGE_BUTTONS_COLOR } from '../../GuestMainPage';

const OurOfferContainer = styled('div')(({ theme }) => ({
  minHeight: '450px',
  padding: theme.spacing(0, 3),
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(7),
  maxWidth: '1200px',

  [AppTheme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const OurOfferElement = styled(Stack)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

const OurOfferDesktop: React.FunctionComponent = () => (
  <OurOfferContainer>
    <Typography
      component="header"
      sx={{
        textAlign: 'center',
        fontSize: '2.8rem',
        fontWeight: 'bold',
        color: fontColors.lightDark,
        mb: '20px',
      }}
    >
      Poznaj naszą ofertę
    </Typography>

    <OurOfferElement display="flex" flexDirection="row-reverse" justifyContent="space-between">
      <CardMedia
        component="img"
        src={oferta1}
        sx={{
          maxWidth: '350px',
          [AppTheme.breakpoints.up('md')]: {
            maxWidth: '576px',
          },
        }}
      />

      <Stack
        display="flex"
        flexDirection="column"
        justifyContent="center"
        sx={{
          p: AppTheme.spacing(0, 2, 0, 0),
          maxWidth: '300px',
          [AppTheme.breakpoints.up('md')]: {
            p: AppTheme.spacing(0, 12, 0, 7),
            maxWidth: 'none',
          },
        }}
      >
        <Typography
          component="header"
          sx={{
            fontSize: '2rem',
            fontWeight: 'bold',
            color: fontColors.lightDark,
            textTransform: 'uppercase',
            padding: AppTheme.spacing(1.875, 2, 0, 0),
          }}
        >
          DLA RESTAURACJI
        </Typography>
        <Typography
          component="p"
          sx={{
            fontSize: '1.6rem',
            color: fontColors.lightDark,
            padding: AppTheme.spacing(0, 2, 0, 0),
          }}
        >
          Oferujemy Ci narzędzie do obsługi zamówień od firm z twojej okolicy.
          Zyskujesz możliwość poszerzenia działalności o nowych klientów dzięki
          serwisowi MójPosiłek.
        </Typography>
        <Link
          href="/"
          sx={{
            textDecoration: 'none',
            padding: AppTheme.spacing(1.875, 2, 0, 0),
          }}
          data-testid="Dowiedz-się-więcej-pracownik-desktop-test-id"
        >
          <Button
            variant="contained"
            color="primary"
            sx={{
              borderRadius: '18px',
              fontSize: '1rem',
              textTransform: 'none',
              backgroundColor: MAIN_PAGE_BUTTONS_BG_COLOR,
              color: MAIN_PAGE_BUTTONS_COLOR,
              mt: '15px',
            }}
          >
            Dowiedz się więcej
          </Button>
        </Link>
      </Stack>
    </OurOfferElement>

    <OurOfferElement display="flex" flexDirection="row">
      <CardMedia
        component="img"
        src={oferta2}
        sx={{
          maxWidth: '350px',
          [AppTheme.breakpoints.up('md')]: {
            maxWidth: '576px',
          },
        }}
      />
      <Stack
        display="flex"
        flexDirection="column"
        justifyContent="center"
        sx={{
          p: AppTheme.spacing(0, 0, 0, 1),
          maxWidth: '300px',
          [AppTheme.breakpoints.up('md')]: {
            p: AppTheme.spacing(0, 12, 0, 7),
            maxWidth: 'none',
          },
        }}
      >
        <Typography
          component="header"
          sx={{
            fontSize: '2rem',
            fontWeight: 'bold',
            color: fontColors.lightDark,
            textTransform: 'uppercase',
            padding: AppTheme.spacing(0.2, 2.5, 1.875),
          }}
        >
          DLA FIRM I PRACOWNIKÓW
        </Typography>
        <Typography
          component="p"
          sx={{
            fontSize: '1.6rem',
            color: fontColors.lightDark,
            padding: AppTheme.spacing(0, 2.5),
          }}
        >
          Jako firma płać mniej za posiłki i jednocześnie dbaj o swoich pracowników
          oferując im wygodny dostęp do ich ulubionych lokali.
          Oferujemy Ci szeroki wybór lokali z których możesz zamówić jedzenie.
          To Ty decydujesz co i skąd zamawiasz.
        </Typography>
        <Link
          href="/"
          sx={{
            textDecoration: 'none',
            padding: AppTheme.spacing(1.875, 2.5),
          }}
          data-testid="Dowiedz-się-więcej-pracodawca-desktop-test-id"
        >
          <Button
            variant="contained"
            color="primary"
            sx={{
              borderRadius: '18px',
              fontSize: '1rem',
              textTransform: 'none',
              backgroundColor: MAIN_PAGE_BUTTONS_BG_COLOR,
              color: MAIN_PAGE_BUTTONS_COLOR,
              mt: '15px',
            }}
          >
            Dowiedz się więcej
          </Button>
        </Link>
      </Stack>
    </OurOfferElement>
  </OurOfferContainer>

);

export default OurOfferDesktop;
