import React, { useEffect, useMemo, useState } from 'react';
import { CircularProgress, Container, Typography, styled, Alert, Stack, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useCart } from 'react-use-cart';

import requestRestaurants from '../../../api/apiRestaurants';
import requestsDates from '../../../api/apiDeliveryDates';
import requestLocation from '../../../api/apiLocations';
import { RoutesVars } from '../../../const/constRoutes';
import useMetadataValidation from '../../../shared/employeeShared/hooks/useMetadataValidation';
import { Restaurant, Location, DeliveryDates, Tag, AvailableDates } from '../../../interfaces';
import AvailableRestaurants from './AvailableRestaurants';
import useMetadata from '../../../shared/employeeShared/hooks/useMetadata';
import { Metadata } from '../../../const/constEmployee';
import DeliveryDetails from './DeliveryDetails';
import AlertChangeDeliveryDetails from './components/AlertChangeDeliveryDetails';
import { getStatus } from '../../../selectors';
import EmptyResturantList from './components/EmptyResturantList';
import useHandleError from '../../Error/useHandleError';
import tagRequests from '../../../api/apiTags';
import { TagCategories } from '../../../const/constShared';

const EmployeeRestaurantsPageContainer = styled(Container)(() => ({ padding: 20, minHeight: '80vh' }));
const RestaurantsListContainer = styled(Container)(() => ({ padding: 0, mb: '15px' }));

const EmployeeRestaurantsPage: React.FunctionComponent = () => {
  const { userId } = useParams();
  const { emptyCart } = useCart();

  const navigate = useNavigate();
  const { isDeliveryMetadataValid } = useMetadataValidation();
  const { getMetadata, updateMetadata } = useMetadata();
  const { handleError } = useHandleError();

  const [deliveryDates, setDeliveryDates] = useState<AvailableDates>();
  const [availableRestaurants, setAvailableRestaurants] = useState<Restaurant[]>([]);
  const [deliveryLocations, setDeliveryLocations] = useState<Location[]>([]);
  const [cuisines, setCuisines] = useState<Tag[]>([]);

  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);

  useEffect(() => {
    if (!isDeliveryMetadataValid) {
      updateMetadata(Metadata.LOCATION, '');
      updateMetadata(Metadata.TIME, '');
      updateMetadata(Metadata.LOCATION_ID, '');
      updateMetadata(Metadata.DATE, '');
      updateMetadata(Metadata.RESTAURANT_ID, '');
    }
    // Here we create initial state for cart metadata
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const selectedDate = getMetadata(Metadata.DATE);
    const nowDate = new Date();
    if (selectedDate) {
      const selectedDateToDate = new Date(selectedDate);

      if (
        nowDate.getDate() > selectedDateToDate.getDate() &&
        nowDate.getMonth() === selectedDateToDate.getMonth() &&
        nowDate.getFullYear() === selectedDateToDate.getFullYear()
      ) {
        emptyCart();
        updateMetadata(Metadata.DATE, '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMetadata(Metadata.DATE)]);

  const { status: locationsStatus, data: dataLocation } = useQuery(['availableLocations'], requestLocation.getLocations, {
    onSuccess: (locationsData: Location[]) => { setDeliveryLocations(locationsData); },
    onError: (error: Error) => { handleError(error.message, 'LOCATIONS', 'GET'); },
  });

  const locations = dataLocation && dataLocation?.map(({ id }) => id);

  const { status: datesStatus } = useQuery(['dateData1', getMetadata(Metadata.LOCATION_ID)],
    requestsDates.getDeliveryDates({ params: { locations: locations ? `[${locations}]` : '' } }),
    {
      onSuccess: (datesData: DeliveryDates) => { setDeliveryDates(datesData.availableDates); },
      onError: (error: Error) => { handleError(error.message, 'RESTAURANT_DELIVERY_DATES', 'GET'); },
      enabled: !!locations,
    });

  const { status: resturantsStatus } = useQuery(
    ['restaurantData', `${getMetadata(Metadata.LOCATION_ID)}-${getMetadata(Metadata.DATE)}`],
    requestRestaurants.getRestaurants({
      params: {
        location: getMetadata(Metadata.LOCATION_ID) ? `[${getMetadata(Metadata.LOCATION_ID)}]` : '',
        date: getMetadata(Metadata.DATE) || '',
      },
    }),
    {
      onSuccess: (restaurantsData: Restaurant[]) => { setAvailableRestaurants(restaurantsData); },
      onError: (error: Error) => { handleError(error.message, 'RESTAURANTS', 'GET'); },
    },
  );

  const { status: tagsStatus } = useQuery(['tagsIndex'], tagRequests.getTags, {
    onSuccess: (data) => {
      data.forEach((tag) => {
        if (tag.category === TagCategories.CUISINE) {
          if (!cuisines.includes(tag)) { setCuisines((state) => [...state, tag]); }
        }
      });
    },
    onError: (error: Error) => { handleError(error.message, 'TAGS_INDEX', 'GET'); },
  });

  // eslint-disable-next-line max-len
  const status = useMemo(() => getStatus(datesStatus, resturantsStatus, locationsStatus, tagsStatus), [datesStatus, resturantsStatus, locationsStatus, tagsStatus]);
  const statusLocations = useMemo(() => getStatus(locationsStatus), [locationsStatus]);
  const statusDates = useMemo(() => getStatus(datesStatus), [datesStatus]);
  const statusDeliveryDetails = useMemo(() => getStatus(datesStatus, locationsStatus), [datesStatus, locationsStatus]);
  const statusResturants = useMemo(() => getStatus(resturantsStatus), [resturantsStatus]);
  const statusTags = useMemo(() => getStatus(tagsStatus), [tagsStatus]);

  const shouldDisplayLoadingResturantsList =
    isDeliveryMetadataValid && // delivery data selected - location + date
    statusDeliveryDetails === 'success' && // is delivery data fetched
    (statusResturants === 'loading' || statusTags === 'loading'); // restaurants list data or tags are fetching

  const shouldDisplayResturantsList =
    isDeliveryMetadataValid && // delivery data selected - location + date
    status !== 'loading' && // data finished fetching - location + date + restaurants
    deliveryDates && // at least one date avaliable to select
    deliveryLocations && // at least one location avaliable to select
    availableRestaurants && !!availableRestaurants.length; // at least one restaurant to display

  const shouldDisplayEmptyListPlaceholder =
    isDeliveryMetadataValid && // delivery data selected - location + date
    status !== 'loading' && // data fetching finished - location + date + restaurants
    deliveryDates && // at least one date avaliable to select
    deliveryLocations && // at least one location avaliable to select
    availableRestaurants && !availableRestaurants.length; // no restaurants to display

  const handleCardClick = (restaurantId: string) => () => {
    if (isDeliveryMetadataValid && userId) {
      navigate(RoutesVars.EMPLOYEE_SELECTED_RESTAURANT(userId, restaurantId));
    }
  };

  if (!(statusLocations === 'success' && !!deliveryLocations.length && deliveryDates)) {
    return <LinearProgress data-testid="test-id-EmployeeRestaurantsPage-loader" />;
  }

  return (
    <EmployeeRestaurantsPageContainer disableGutters maxWidth="md" data-testid="Employee-Restaurants-Page__container-testid">
      {statusLocations === 'success' && !!deliveryLocations.length && deliveryDates && (
        <>
          <Typography sx={{ fontSize: 32, fontWeight: 'bold' }}>Restauracje</Typography>

          <DeliveryDetails
            deliveryLocations={deliveryLocations}
            deliveryDates={deliveryDates}
            setIsAlertDialogOpen={setIsAlertDialogOpen}
            status={status}
            statusDates={statusDates}
          />

          <Typography component="h2" sx={{ ml: '5px', fontSize: 20, fontWeight: 'bold' }}>Dostępne restauracje</Typography>
        </>
      )}

      <RestaurantsListContainer>
        {!isDeliveryMetadataValid && status !== 'loading' && !!deliveryLocations.length && deliveryDates && (
          <Alert severity="info" sx={{ fontSize: 12, mt: 1 }}>Wybierz miejsce i godzinę dostawy aby zobaczyć dostępne resturacje</Alert>)}

        {shouldDisplayLoadingResturantsList && (
          <Stack flexDirection="row" justifyContent="center" sx={{ mt: '50px' }}>
            <CircularProgress data-testid="test-id-available-restaurants-loader" />
          </Stack>
        )}

        {shouldDisplayResturantsList && (
          <AvailableRestaurants
            availableRestaurants={availableRestaurants}
            handleCardClick={handleCardClick}
            cuisinesList={cuisines}
          />
        )}

        {shouldDisplayEmptyListPlaceholder && (<EmptyResturantList />)}
      </RestaurantsListContainer>

      <AlertChangeDeliveryDetails
        open={isAlertDialogOpen}
        handleAgree={() => { emptyCart(); setIsAlertDialogOpen(false); }}
        handleRefuse={() => { setIsAlertDialogOpen(false); }}
      />
    </EmployeeRestaurantsPageContainer>
  );
};

export default EmployeeRestaurantsPage;
