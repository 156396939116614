import React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Drawer, List, ListItem, Link, Box, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { AppTheme } from '../../../thema/AppThema';
import { RoutesVars } from '../../../const/constRoutes';

const DrawerListItem = styled(ListItem)(() => ({
  marginRight: 2,
  [AppTheme.breakpoints.up('md')]: {
    marginRight: 20,
  },
}));

type GuestDrawerProps = {
  isOpen?: boolean;
  onCloseCallback?: () => undefined;
  currentPath?: string;
};

const GUEST_DRAWER_OPTIONS = ['O nas', 'Dla firm', 'Dla restauracji', 'Kontakt'];

const GuestDrawer: React.FC<GuestDrawerProps> = ({ isOpen, onCloseCallback, currentPath }) => {
  const navigate = useNavigate();

  const returnToLogin = () => {
    if (currentPath === '/login' && onCloseCallback) onCloseCallback();

    navigate(RoutesVars.LOGIN);
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onCloseCallback}
      id="test-id-drawer"
    >
      <Box role="presentation" display="flex" flexDirection="column">
        <IconButton
          color="secondary"
          aria-label="close drawer"
          onClick={onCloseCallback}
          sx={{
            alignSelf: 'flex-end',
            marginRight: 2,
          }}
        >
          <CloseIcon />
        </IconButton>
        <List>
          {
            GUEST_DRAWER_OPTIONS.map((text) => (
              <DrawerListItem divider key={text}>
                <Link href="#top" underline="none" color="secondary" onClick={onCloseCallback}>
                  <Typography fontSize={27}>
                    {text}
                  </Typography>
                </Link>
              </DrawerListItem>
            ))
          }
        </List>
        <Button
          variant="contained"
          onClick={returnToLogin}
          sx={{
            alignSelf: 'flex-end',
            borderRadius: 40,
            marginRight: 2,
          }}
        >
          Zaloguj się
        </Button>
      </Box>
    </Drawer>
  );
};

GuestDrawer.defaultProps = {
  isOpen: false,
  onCloseCallback: () => undefined,
  currentPath: '',
};

export default GuestDrawer;
