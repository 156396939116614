import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { QueryKeys } from "../../api/services/api/queryKeys";
import { getRawTags } from "../../api/services/api/apiTags";
import { Tag } from "../../interfaces";
import { TagCategories } from "../../const/constShared";

type UseTags = () => {
  cuisines:Tag[],
  ingredients:Tag[],
  allergens:Tag[],
  isLoadingTags: boolean;
  isSuccessTags: boolean;
  statusTags: "error" | "success" | "loading";
  tags: Tag[];
};

const useTags: UseTags = () => {
  const [cuisines, setCuisines] = useState<Tag[]>([]);
  const [ingredients, setIngredients] = useState<Tag[]>([]);
  const [allergens, setAllergens] = useState<Tag[]>([]);

  const {
    data: tags,
    isLoading: isLoadingTags,
    status: statusTags,
    isRefetching,
    isFetched,
    isSuccess: isSuccessTags,
  } = useQuery({
    queryKey: [QueryKeys.TAG_INDEX],
    queryFn: () => getRawTags(),
    meta: { errorMessage: 'Błąd podczas ładowania informacji o tagach.' },
  });

  useEffect(() => {
    if (tags) {
      if (isFetched && isRefetching) return;
      tags.forEach((tag) => {
        if (tag.category === TagCategories.INGREDIENT) {
          if (!ingredients.includes(tag)) { setIngredients((state) => [...state, tag]); }
        } else if (tag.category === TagCategories.ALLERGEN) {
          if (!allergens.includes(tag)) { setAllergens((state) => [...state, tag]); }
        } else if (tag.category === TagCategories.CUISINE) {
          if (!cuisines.includes(tag)) { setCuisines((state) => [...state, tag]); }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, isFetched, isRefetching]);

  return {
    cuisines,
    allergens,
    ingredients,
    isLoadingTags,
    statusTags,
    isSuccessTags,
    tags: tags || [],
  };
};

export default useTags;
