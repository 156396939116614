import React, { useEffect, useMemo, useState } from 'react';
import { Container, Stack, Typography, styled, CardMedia, ButtonGroup, Button, LinearProgress } from '@mui/material';
import { useCart } from 'react-use-cart';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import requestRepository from '../../../api/apiDishes';
import { RoutesVars } from '../../../const/constRoutes';
import { colors } from '../../../thema/AppThema';
import useMetadata from '../../../shared/employeeShared/hooks/useMetadata';
import { Metadata } from '../../../const/constEmployee';
import useMetadataValidation from '../../../shared/employeeShared/hooks/useMetadataValidation';
import IngredientsList from './components/IngredientsList';
import AllergensList from './components/AllergensList';
import Description from './components/Description';
import AlertRedirectToDishList from './components/AlertRedirectToDishList';
import BreadcrumbsComponent from '../../../components/common/BreadcrumbsComponent/BreadcrumbsComponent';
import { CartItem, Dish, Restaurant, Tag } from '../../../interfaces';
import requestRepositoryRestaurant from '../../../api/apiRestaurants';
import { priceToGrosze } from '../../../selectors/cartSelectors';
import { NO_IMAGE_PLACEHOLDER_PIZZA_JPG, TagCategories } from '../../../const/constShared';
import tagRequests from '../../../api/apiTags';
import { getStatus } from '../../../selectors';
import AlertClearCartItems from '../../../components/common/AlertClearCartItems/AlertClearCartItems';
import CartBanner from '../../../components/common/Cart/CartBanner';
import useHandleError from '../../Error/useHandleError';

const EmployeeRestaurantSelectedDishPageConatiner = styled(Container)(() => ({
  padding: 0,
}));

const EmployeeSelectedDishPageWrapper = styled(Container)(() => ({
  padding: '20px',
}));

const EmployeeRestaurantSelectedDishPage: React.FC = () => {
  const navigate = useNavigate();
  const { userId, dishId, restaurantId } = useParams();
  const { addItem, getItem, setItems, totalItems } = useCart();
  const { getMetadata, updateMetadata } = useMetadata();
  const { isDeliveryMetadataValid, isResturantMetadataValid } = useMetadataValidation();
  const { handleError } = useHandleError();

  const [isRedirectDialogOpen, setIsRedirectDialogOpen] = useState(false);
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);

  const dishInCart = getItem(dishId as string);
  const [dish, setDish] = useState<Dish>();
  const [dishNumber, setDishNumber] = useState(1);
  const [restaurantName, setRestaurantName] = useState<string>('');
  const [ingredients, setIngredients] = useState<Tag[]>([]);
  const [allergens, setAllergens] = useState<Tag[]>([]);

  useEffect(() => {
    if (restaurantId !== undefined && dishId === undefined) {
      userId && navigate(RoutesVars.EMPLOYEE_SELECTED_RESTAURANT(userId, restaurantId));
    }

    if (restaurantId === undefined || dishId === undefined) {
      userId && navigate(RoutesVars.EMPLOYEE_RESTAURANTS(userId));
    }
  }, [dishId, navigate, restaurantId, userId]);

  const { status: dishStatus } = useQuery(['dish', dishId], requestRepository.getDish(dishId as string), {
    onSuccess: (dishResult: Dish) => { setDish(dishResult); },
    onError: (error: Error) => { handleError(error.message, 'DISH', 'GET'); },
  });

  const { status: restaurantStatus } = useQuery(['restaurantData', restaurantId], requestRepositoryRestaurant.getRestaurant(restaurantId as string), {
    onSuccess: (restaurantData: Restaurant) => { setRestaurantName(restaurantData.name); },
    onError: (error: Error) => { handleError(error.message, 'RESTAURANTS', 'GET'); },
  });

  const { status: tagsStatus } = useQuery(['tagsIndex'], tagRequests.getTags, {
    onSuccess: (data) => {
      data.forEach((tag) => {
        if (tag.category === TagCategories.INGREDIENT) {
          if (!ingredients.includes(tag)) {
            setIngredients((state) => [...state, tag]);
          }
        } else if (tag.category === TagCategories.ALLERGEN) {
          if (!allergens.includes(tag)) {
            setAllergens((state) => [...state, tag]);
          }
        }
      });
    },
    onError: (error: Error) => { handleError(error.message, 'TAGS_INDEX', 'GET'); },
  });

  const status = useMemo(() => getStatus(dishStatus, restaurantStatus, tagsStatus), [dishStatus, restaurantStatus, tagsStatus]);

  const handleAddToCart = (dish: Dish, ingredients: Tag[], isCalledFromModal?: boolean): void => {
    const ingredientsInDish = ingredients.filter((ingredient) => dish.ingredients.includes(ingredient.internalName));
    const ingredientsToCart = ingredientsInDish.map((ingredient) => ingredient.name);

    const dishToAddToCart: CartItem = {
      id: `${dish.id}`,
      ingredients: ingredientsToCart,
      name: dish.name,
      quantity: dishNumber,
      price: priceToGrosze(dish?.price),
      restaurantId: restaurantId as string,
      locationId: getMetadata(Metadata.LOCATION_ID) as string,
      deliveryDate: getMetadata(Metadata.DATE) as string,
      imgSrc: dish?.img || NO_IMAGE_PLACEHOLDER_PIZZA_JPG,
      // TODO: po implenetacji obrazów na backendzie dodac jakis placeholder jeśli nie ma img src
    };

    // We have items from other resturant, agree to delete them and add new dish from current selected resturant
    if (isCalledFromModal) {
      updateMetadata(Metadata.RESTAURANT_ID, restaurantId);
      isDeliveryMetadataValid && addItem(dishToAddToCart, dishToAddToCart.quantity);
      setIsRedirectDialogOpen(true);
      return;
    }

    // The cart is empty, so we add dish to it
    if (!isResturantMetadataValid) {
      updateMetadata(Metadata.RESTAURANT_ID, restaurantId);
      isDeliveryMetadataValid && addItem(dishToAddToCart, dishToAddToCart.quantity);
      setIsRedirectDialogOpen(true);
      return;
    }

    // We have items in cart
    // 1. Cart items are from current selected resturant
    // 2. Cart items are from different restaurant, so we display "Clear Cart Items" modal for user
    if (isResturantMetadataValid && (getMetadata(Metadata.RESTAURANT_ID) === restaurantId)) {
      isDeliveryMetadataValid && addItem(dishToAddToCart, dishToAddToCart.quantity);
      setIsRedirectDialogOpen(true);
    } else {
      setIsAlertDialogOpen(true);
    }
  };

  const handleRedirectAfterSuccessAdd = () => {
    if (isDeliveryMetadataValid && userId) {
      navigate(RoutesVars.EMPLOYEE_SELECTED_RESTAURANT(userId, restaurantId));
    }
  };

  const handleAgree = () => {
    setItems([]);
    status === 'success' && dish && handleAddToCart(dish, ingredients, true);
    setIsAlertDialogOpen(false);
  };

  return (
    <EmployeeRestaurantSelectedDishPageConatiner disableGutters maxWidth="md" data-testid="Employee-Restaurant-Selected-Dish-Page__container-testid">
      {status === 'loading' && (<LinearProgress data-testid="Employee-Restaurant-Selected-Dish-Page-loading-progressbar__testid" />)}
      {status === 'success' && dish && restaurantName && ingredients && allergens && (
        <>
          <Container sx={{ pl: '16px', pt: '19px', pb: '10px' }}>
            <BreadcrumbsComponent
              ariaLabel="breadcrumb-selected-dish-page"
              breadcrumbContents={[{
                name: 'Restauracje',
                href: `${RoutesVars.EMPLOYEE_RESTAURANTS(userId as string)}`,
                testId: 'Breadcrumbs-powrót-do-listy-resturacji',
              },
              {
                name: `${restaurantName || '---'}`,
                href: `${RoutesVars.EMPLOYEE_SELECTED_RESTAURANT(userId as string, restaurantId as string)}`,
                testId: 'Breadcrumbs-powrót-do-wybranej-restauracji',
              }]}
              breadcrumbCurrentPage={{
                name: `${dish.name || '---'}`,
                testId: 'Breadcrumbs-wybrane-danie',
              }}
            />
          </Container>

          <CardMedia
            component="img"
            src={dish.img || NO_IMAGE_PLACEHOLDER_PIZZA_JPG}
            sx={{ height: '350px', width: '100%' }}
          />

          <EmployeeSelectedDishPageWrapper disableGutters>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ fontSize: 32, fontWeight: 'bold' }}>
                {dish.name}
              </Typography>
              <Stack direction="row">
                <Typography sx={{ fontSize: 22, fontWeight: 'bold', pr: '10px', color: '#FA8D13', minWidth: '80px', mt: '9px' }}>
                  {`${Number(dish.price)} zł`}
                </Typography>
              </Stack>
            </Stack>

            <Typography sx={{ fontSize: 16, color: '#616161' }}>{restaurantName}</Typography>

            <IngredientsList ingredients={dish.ingredients} ingredientsList={ingredients} />

            <AllergensList allergens={dish.allergens} allergensList={allergens} />

            <Description description={dish.description} />

            {dishInCart && dishInCart.quantity ? (
              <Typography
                component="div"
                aria-label="Ilość dań w koszyku"
                data-testid="total-items-this-type-in-cart"
                sx={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  fontSize: 15,
                  fontWeight: 'bold',
                  pt: '55px',
                }}
              >
                {`Ilość dań w koszyku: ${dishInCart?.quantity}`}
              </Typography>
            ) : null}

            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ marginTop: '30px' }}>
              <ButtonGroup
                sx={{
                  backgroundColor: 'rgba(139, 138, 138, 0.199)',
                  borderRadius: '30px',
                  marginRight: '10px',
                  overflow: 'hidden',
                  boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
                  border: `1.4px solid ${colors.primary}`,
                  maxWidth: '105px',
                  minWidth: '105px',
                  maxHeight: '40px',
                }}
              >
                <Button
                  aria-label="Usuń posiłek"
                  variant="contained"
                  disabled={dishNumber <= 1}
                  onClick={() => setDishNumber((preVal) => preVal - 1)}
                  data-testid="decrease-quantity-by-one-button"
                  sx={{
                    backgroundColor: 'transparent',
                    fontSize: 10,
                    boxShadow: 'none',
                    fontWeight: 'bold',
                    '&:hover': {
                      backgroundColor: 'rgba(139, 138, 138, 0.473)',
                      borderRadius: '30px 0 0 30px',
                    },
                  }}
                >
                  -
                </Button>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ boxShadow: 'none', border: 'none' }}
                >
                  <Typography
                    component="span"
                    aria-label="Liczba wybranch dań"
                    data-testid="test-id-number-dishes-to-card"
                    sx={{
                      backgroundColor: 'transparent',
                      textAlign: 'center',
                      border: 'none',
                      fontSize: 15,
                      fontWeight: 'bold',
                      padding: '0 8px',
                    }}
                  >
                    {dishNumber}
                  </Typography>
                </Stack>
                <Button
                  aria-label="Dodaj posłek"
                  variant="contained"
                  onClick={() => setDishNumber((preVal) => preVal + 1)}
                  data-testid="increase-quantity-by-one-button"
                  sx={{
                    backgroundColor: 'transparent',
                    fontSize: 15,
                    boxShadow: 'none',
                    fontWeight: 'bold',
                    '&:hover': {
                      backgroundColor: 'rgba(139, 138, 138, 0.473)',
                      borderRadius: '0 30px 30px 0',
                    },
                  }}
                >
                  +
                </Button>
              </ButtonGroup>

              <Button
                variant="contained"
                color="primary"
                onClick={() => handleAddToCart(dish, ingredients)}
                data-testid="add-to-cart-button-selected-dish-page"
                disabled={!isDeliveryMetadataValid}
                // TODO: add tooltip -> no metadata, cant add to cart
                // TODO: <Tooltip title="Nie wybrano szczegółów dostawy"></Tooltip>
                sx={{
                  flexGrow: 1,
                  maxWidth: '280px',
                  borderRadius: '30px',
                  fontSize: 15,
                  fontWeight: 'bold',
                  textTransform: 'none',
                }}
              >
                Dodaj do zamówienia
              </Button>
            </Stack>

            <AlertRedirectToDishList
              open={isRedirectDialogOpen}
              handleAgree={() => handleRedirectAfterSuccessAdd()}
              handleRefuse={() => setIsRedirectDialogOpen(false)}
            />
            <AlertClearCartItems
              open={isAlertDialogOpen}
              handleAgree={handleAgree}
              handleRefuse={() => { setIsAlertDialogOpen(false); }}
            />
          </EmployeeSelectedDishPageWrapper>
          {!!totalItems && <CartBanner />}
        </>
      )}
    </EmployeeRestaurantSelectedDishPageConatiner>
  );
};
export default EmployeeRestaurantSelectedDishPage;
