import React from 'react';
import { Button, Stack, styled, Typography } from '@mui/material';

import { Tag } from '../../../../../interfaces';
import useDrag from '../../../../../components/common/HorizontalScrollingMenu/useDrag';

const StyledButton = styled(Button)(() => ({
  padding: '5px',
  margin: '5px',
  color: 'black',
}));

interface Props {
  cuisin: Tag;
  itemId: string;
  selected: boolean;
  handleItemClick: (itemId: string) => void;
}

const CategoriesFilter: React.FunctionComponent<Props> = ({ cuisin, handleItemClick, selected, itemId }) => {
  const { dragging } = useDrag();

  const handleClick = (): void => {
    !dragging && handleItemClick(itemId);
  };

  return (
    <StyledButton
      aria-label={`kategoria-${cuisin.name}`}
      onClick={handleClick}
      sx={{
        backgroundColor: selected ? 'rgba(161, 193, 129, 0.374)' : 'none',
        '&:hover': {
          backgroundColor: 'rgba(139, 138, 138, 0.313)',
        },
      }}
      data-testid={`test-id-category-filter-${cuisin.name}`}
    >
      <Stack display="flex" flexDirection="column" alignItems="center">
        <svg width="63px" height="63px" radius="30px">
          <image xlinkHref={`${cuisin.img}`} width="63px" height="63px" radius="30px" />
        </svg>
        <Typography component="div" sx={{ fontSize: 16 }}>{cuisin.name}</Typography>
      </Stack>
    </StyledButton>
  );
};
export default CategoriesFilter;
