import RestaurantInfo from "../../../interfaces/RestaurantInfo";
import { axiosApi } from "../../axios";
import { routes } from "../../routes";

const getRestaurantInfo = async (id: string): Promise<RestaurantInfo> => {
  const response = await axiosApi.get<RestaurantInfo>(routes.RESTAURANTS_INFO(id));
  return response.data;
};

export {
  getRestaurantInfo,
};
