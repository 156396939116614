import React, { Dispatch } from 'react';
import actions, { Action } from './actions';
import { reducer, initialState, State, ReducerAction } from './reducer';

type EnchancedAction = () => Promise<void>;
type UserContextProps = {
  state: State;
  actions: {
    fetchUser: EnchancedAction,
    logoutUser: () => void,
  }
};

export const enchanceAction = (disptach: Dispatch<ReducerAction>, action: Action): EnchancedAction => () => action(disptach);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const nullFn = () => Promise.resolve();
export const initialActions = {
  fetchUser: nullFn,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  logoutUser: () => null,
};

export const UserContext = React.createContext<UserContextProps>({ state: initialState, actions: initialActions });
UserContext.displayName = 'UserContext';

type Props = { children?: React.ReactNode };

export const UserContextProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const fetchUser: EnchancedAction = enchanceAction(dispatch, actions.fetchUser);
  const logoutUser = () => actions.logoutUser(dispatch);
  const enchancedActions = {
    fetchUser,
    logoutUser,
  };

  return (
    <UserContext.Provider value={{ state, actions: enchancedActions }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
