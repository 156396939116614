import React, { ReactElement } from 'react';
import { Typography, CardContent, Card } from '@mui/material';
import { OrdersSummaryTableData } from './OrdersSummaryTable/OrdersSummaryTable';

interface Props {
  order: OrdersSummaryTableData;
}

export default function PrintableOrdersSummary({ order }: Props): ReactElement {
  return (
    <Card variant="outlined" sx={{ position: 'relative', margin: 1, padding: 1 }}>
      <CardContent sx={{ marginTop: 1, marginBottom: 1 }}>
        <Typography sx={{ fontSize: '1rem' }}>
          {`ID Pracownika: ${order.user}`}
        </Typography>
        <Typography sx={{ fontSize: '1rem' }}>
          {`Pracownik: ${order.employeeName}`}
        </Typography>
        <Typography sx={{ fontSize: '1rem' }}>
          {`Nr zamówienia: ${order.id}`}
        </Typography>
        <Typography sx={{ fontSize: '1rem' }}>
          {`Data zamówienia: ${order.dateCreated}`}
        </Typography>
        <Typography sx={{ fontSize: '1rem' }}>
          {`Data dostarczenia: ${order.deliveryDate}`}
        </Typography>
        <Typography sx={{ fontSize: '1rem' }}>
          {`Miejsce dostawy: ${order.locationName}`}
        </Typography>
        <Typography sx={{ fontSize: '1rem' }}>
          {`Cena zamówienia: ${order.dueAmount}`}
        </Typography>
      </CardContent>
    </Card>
  );
}
