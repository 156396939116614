import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import {
  Checkbox, Container, FormControl, FormControlLabel, FormGroup, InputLabel,
  MenuItem, Select, SelectChangeEvent, TextField, Typography,
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import { Company, HistoryOrder, Location } from '../../../../interfaces';
import { OrderStatus } from '../../../../interfaces/Order';
import { filterHistoryOrders } from '../../selectors';

const Transition = React.forwardRef((
  props: TransitionProps & { children: React.ReactElement<unknown, string>; },
  ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

interface Props {
  open: boolean;
  items: HistoryOrder[];
  companies: Company[];
  locations: Location[];
  handleClose: () => void;
  handleReset: () => void;
  handleFilter: (orders: HistoryOrder[]) => void;
}

export default function FilterDialog({ open, handleClose, handleReset, handleFilter, items, companies, locations }: Props): React.ReactElement {
  const [orderStatuses, setOrderStatuses] = React.useState<string[]>([]);
  const [selectedCompany, setSelectedCompany] = React.useState<number>(-1);
  const [selectedLocation, setSelectedLocation] = React.useState<number>(-1);
  const [dateFrom, setDateFrom] = React.useState<Date>();
  const [dateTo, setDateTo] = React.useState<Date>();

  const handleDateFromChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateFrom(() => new Date(Date.parse(event.target.value)));
  };

  const handleDateToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateTo(() => new Date(Date.parse(event.target.value)));
  };

  const handleStateCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name) {
      setOrderStatuses(
        () => {
          if (event.target.checked) {
            return Array.from(new Set([...orderStatuses, event.target.name]));
          }

          const set = new Set(orderStatuses);
          set.delete(event.target.name);

          return Array.from(set);
        },
      );
    }
  };

  const onFilterClick = () => {
    const filtered = filterHistoryOrders(items, orderStatuses, selectedCompany, selectedLocation, dateFrom, dateTo);
    handleFilter(filtered);
  };

  const onResetClick = () => {
    setOrderStatuses([]);
    setSelectedCompany(-1);
    setDateFrom(undefined);
    setDateTo(undefined);
    handleReset();
  };

  const handleSelectedCompaniesChange = (event: SelectChangeEvent<number>) => {
    setSelectedCompany(Number(event.target.value));
  };

  const handleSelectedLocationChange = (event: SelectChangeEvent<number>) => {
    setSelectedLocation(Number(event.target.value));
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{ '.MuiDialog-paper': { alignSelf: 'center' } }}
    >
      <DialogTitle>Filtry</DialogTitle>
      <DialogContent>
        <Container>
          <Typography variant="h4" sx={{ pt: '28px', pb: '14px' }}>Przedział czasowy</Typography>
          <TextField
            id="date-from"
            name="date-from"
            label="Data od"
            type="date"
            InputLabelProps={{ shrink: true }}
            onChange={handleDateFromChange}
            sx={{ mt: '14px', pr: '32px' }}
          />
          <TextField
            id="date-to"
            name="date-to"
            label="Data do"
            type="date"
            InputLabelProps={{ shrink: true }}
            onChange={handleDateToChange}
            sx={{ mt: '14px' }}
          />
        </Container>
        <Container>
          <Typography variant="h4" sx={{ pt: '28px', pb: '28px' }}>Firma</Typography>
          <FormControl fullWidth>
            <InputLabel id="company-select-label">Firma</InputLabel>
            <Select
              labelId="company-select-label"
              id="company-select"
              name="company-select"
              label="Firma"
              sx={{ mb: '12px' }}
              value={selectedCompany}
              onChange={handleSelectedCompaniesChange}
            >
              <MenuItem key={-1} value={-1}>Bez zaznaczenia</MenuItem>
              {companies.map(
                (item) => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>,
              )}
            </Select>
          </FormControl>
        </Container>
        <Container>
          <Typography variant="h4" sx={{ pt: '28px', pb: '28px' }}>Lokalizacja</Typography>
          <FormControl fullWidth>
            <InputLabel id="location-select-label">Lokalizacja</InputLabel>
            <Select
              labelId="location-select-label"
              id="location-select"
              name="location-select"
              label="Lokalizacja"
              sx={{ marginBottom: '12px' }}
              value={selectedLocation}
              onChange={handleSelectedLocationChange}
            >
              <MenuItem key={-1} value={-1}>Bez zaznaczenia</MenuItem>
              {locations.map(
                (item) => <MenuItem key={item.id} value={item.id}>{`${item.name} - ${item.time} - ${item.address.street}`}</MenuItem>,
              )}
            </Select>
          </FormControl>
        </Container>
        <Container>
          <Typography variant="h4" sx={{ pt: '28px', pb: '28px' }}>Status</Typography>
          <FormGroup onChange={handleStateCheckbox}>
            <FormControlLabel control={<Checkbox name={OrderStatus.PLACED} />} label="Złożono" />
            <FormControlLabel control={<Checkbox name={OrderStatus.RECEIVED} />} label="Odebrano" />
            <FormControlLabel control={<Checkbox name={OrderStatus.REJECTED} />} label="Odrzucono" />
            <FormControlLabel control={<Checkbox name={OrderStatus.IN_PROGRESS} />} label="W przygotowaniu" />
            <FormControlLabel control={<Checkbox name={OrderStatus.IN_TRANSIT} />} label="W dostawie" />
            <FormControlLabel control={<Checkbox name={OrderStatus.DELIVERED} />} label="Dostarczono" />
          </FormGroup>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={onResetClick}>Zresetuj</Button>
        <Button onClick={onFilterClick}>Zastosuj</Button>
        <Button onClick={handleClose}>Zamknij</Button>
      </DialogActions>
    </Dialog>
  );
}
