import React, { FC } from 'react';
import { CardActionArea, Stack, Typography } from '@mui/material';
import { useCart } from 'react-use-cart';
import { useNavigate } from 'react-router-dom';
import ShoppingBagRoundedIcon from '@mui/icons-material/ShoppingBagRounded';

import { RoutesVars } from '../../../const/constRoutes';
import { UserContext } from '../../../contexts/userContext/userContext';
import { groszeToPrice } from '../../../selectors/cartSelectors';

const CartBanner: FC = () => {
  const { state: { user } } = React.useContext(UserContext);
  const { totalItems, cartTotal } = useCart();
  const navigate = useNavigate();

  if (!user) return null;
  if (!user.isEmployee) return null;

  const handleNavigate = () => (totalItems > 0 ? navigate(RoutesVars.EMPLOYEE_ORDER_SUMMARY(user.employeeId)) : null);

  return (
    <CardActionArea onClick={handleNavigate}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          position: 'fixed',
          bottom: '0px',
          left: '0px',
          height: '60px',
          width: '100%',
          backgroundColor: '#125FC7',
        }}
      >
        <Stack direction="row" sx={{ position: 'relative', mr: '10px' }}>
          <ShoppingBagRoundedIcon sx={{ mr: '15px', height: '30px', width: '28px', color: 'white' }} />
          <Stack
            sx={{
              position: 'absolute',
              right: '12px',
              bottom: '-3px',
              zIndex: '1',
              backgroundColor: '#F96702',
              width: '17px',
              height: '17px',
              borderRadius: '10px',
            }}
          >
            <Typography
              sx={{
                position: 'absolute',
                top: '52%',
                left: '49%',
                transform: 'translate(-50%, -50%)',
                color: 'white',
                fontWeight: 'bold',
              }}
            >
              {totalItems}
            </Typography>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          sx={{ color: 'white', fontSize: '17px', fontWeight: 'bold', mt: '4px' }}
          data-testid="test-id-go-to-cart-banner"
        >
          {`Przejdź do koszyka | (${groszeToPrice(cartTotal)} zł)`}
        </Stack>
      </Stack>
    </CardActionArea>
  );
};

export default CartBanner;
