import React from 'react';
import { Typography, styled } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { Tag } from '../../../../interfaces';

const Title = styled(Typography)(() => ({
  fontSize: 20,
  margin: '28px 0 5px',
  fontWeight: 'bold',
}));

const ListContaner = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
}));

interface Props {
  ingredients: string[];
  ingredientsList: Tag[];
}

const IngredientsList: React.FC<Props> = ({ ingredients, ingredientsList }) => {
  const ingredientsToDisplay = ingredientsList.filter((ingredient) => ingredients.includes(ingredient.internalName));

  return (
    <>
      <Title>Składniki</Title>

      <ListContaner>
        {ingredientsToDisplay.map(((ingredient, index) => (
          <Typography key={ingredient.internalName} component="span" sx={{ fontSize: 16 }}>
            {index === 0 ? null : <FiberManualRecordIcon sx={{ width: '7px', height: '7px', m: '2px 5px' }} />}
            {ingredient.name}
          </Typography>
        )))}

        {!ingredientsToDisplay.length && <Typography component="span" sx={{ fontSize: 16 }}>Brak składników</Typography>}
      </ListContaner>
    </>

  );
};
export default IngredientsList;
