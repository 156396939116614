import React from 'react';
import { Typography, styled, Link, Stack, Breadcrumbs } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const BreadcrumbsWrapper = styled('div')(() => ({
  fontSize: 20,
}));

interface BreadcrumbContent {
  name: string;
  href: string;
  testId: string;
}

interface BreadcrumbCurrentPage {
  name: string;
  testId: string;
}

interface ReturnToPageContent {
  name: string;
  href: string;
  testId: string;
}

interface Props {
  breadcrumbContents: BreadcrumbContent[];
  breadcrumbCurrentPage: BreadcrumbCurrentPage;
  returnToPageContent?: ReturnToPageContent;
  ariaLabel: string;
}

const BreadcrumbsComponent: React.FC<Props> = ({ breadcrumbContents, breadcrumbCurrentPage, returnToPageContent, ariaLabel }) => (
  <BreadcrumbsWrapper>
    <Breadcrumbs aria-label={ariaLabel} sx={{ fontSize: 12, color: 'black' }}>
      {breadcrumbContents.map((breadcrumbContent) => (
        <Link
          key={breadcrumbContent.testId}
          data-testid={breadcrumbContent.testId}
          href={breadcrumbContent.href}
          underline="hover"
          sx={{ color: 'black' }}
        >
          {breadcrumbContent.name}
        </Link>
      ))}
      <Typography sx={{ fontSize: 12, color: 'black' }}>
        {breadcrumbCurrentPage.name}
      </Typography>
    </Breadcrumbs>

    {
      returnToPageContent && (
        <Stack sx={{ fontSize: 13, color: 'black', pt: '5px' }} direction="row" alignItems="center">
          <NavigateBeforeIcon sx={{ width: 20, height: 20 }} />
          <Link
            data-testid={returnToPageContent.testId}
            href={returnToPageContent.href}
            underline="hover"
            sx={{ fontSize: 13, color: 'black' }}
          >
            {returnToPageContent.name}
          </Link>
        </Stack>
      )
    }
  </BreadcrumbsWrapper>
);

export default BreadcrumbsComponent;
