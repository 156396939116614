import axios, { AxiosResponse } from 'axios';
import apiUrl, { baseUrl } from './routes';
import { refreshAccessToken, LoginResult } from './apiLogin';
import {
  TOKEN_EXPIRED_MESSAGE,
  getToken,
  refreshToken,
} from '../contexts/AuthContext/utils';

const tokenExpired = (response: AxiosResponse): boolean => {
  if (!response) return false;
  if (response.status !== 401) return false;
  if (response.data && response.data.detail === TOKEN_EXPIRED_MESSAGE) return true;

  return false;
};

const axiosApi = axios.create({
  baseURL: apiUrl,
  headers: { 'Content-Type': 'application/json' },
});

const axiosBase = axios.create({
  baseURL: baseUrl,
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
});

axiosApi.interceptors.request.use((request) => {
  const token = getToken();

  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }

  return request;
});

axiosApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (!originalRequest) return Promise.reject(error);
    if (!originalRequest.request_retried && tokenExpired(error.response)) {
      originalRequest.request_retried = true;

      const result = await refreshAccessToken();
      if (LoginResult.isSuccess(result)) {
        refreshToken(result.tokenInfo());
      }
      return axiosApi(originalRequest);
    }

    return Promise.reject(error);
  },
);

interface Result {
  success: boolean;
}

class ErrorResult implements Result {
  message: string;

  status: number;

  success = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  constructor(error: any) {
    if (error.response) {
      this.status = error.response.status;
      this.message = error.response.data;
    } else {
      this.status = 500;
      this.message = error.message;
    }
  }

  unauthorized = (): boolean => this.status === 401;

  forbidden = (): boolean => this.status === 403;

  internalServerError = (): boolean => this.status === 500;
}

const isSuccess = (result: Result | ErrorResult): result is Result => (result.success);
const isError = (result: Result | ErrorResult): result is ErrorResult => (!result.success);

class GetResource<Type> implements Result {
  success = true;

  response: AxiosResponse;

  resource: Type;

  static isSuccess = <Type extends Result>(result: Type | ErrorResult): result is Type => result.success;

  constructor(response: AxiosResponse) {
    this.response = response;
    this.resource = this.response.data;
  }
}

class GetResources<Type> implements Result {
  success = true;

  response: AxiosResponse;

  resources: Type[];

  static isSuccess = <Type extends Result>(result: Type | ErrorResult): result is Type => result.success;

  constructor(response: AxiosResponse) {
    this.response = response;
    this.resources = this.response.data;
  }
}

const createResource = <Type>(route: string) => async (body: Type): Promise<Type> => {
  const response = await axiosApi.post(route, body);
  // this will have to include a whole object or new id of the object
  const resourceResponse = new GetResource<Type>(response);
  return resourceResponse.resource;
};

const patchResource = async <Type>(route: string, body: Partial<Type>): Promise<Result> => {
  await axiosApi.patch(route, body);

  return { success: true };
};

const deleteResource = async (route: string): Promise<Result> => {
  await axiosApi.delete(route);
  return { success: true };
};

const getResource = <Type>(route: string) => async (): Promise<Type> => {
  const response = await axiosApi.get(route);

  const resourceResponse = new GetResource<Type>(response);

  return resourceResponse.resource;
};

const getResources = <Type>(route: string, params?: unknown) => async (): Promise<Type[]> => {
  const response = params ? await axiosApi.get(route, { params }) : await axiosApi.get(route, { params });

  return new GetResources<Type>(response).resources;
};

export {
  axiosApi,
  axiosBase,
  getResource,
  getResources,
  createResource,
  patchResource,
  deleteResource,
  isSuccess,
  isError,
  ErrorResult,
  GetResources,
  GetResource,
};

export type {
  Result,
};
