import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import ProductHero from './components/ProductHero';
import MainAppBar from '../../../components/common/MainAppBar';
import GuestDrawer from '../../../components/common/Drawer/GuestDrawer';
import ProductValues from './components/ProductValues';
import ProductHowItWorks from './components/ProductHowItWorks';
import ProductSmokingHero from './components/ProductSmokingHero';
import Footer from '../../../components/common/Footer';
import theme from './theme';
import BenefitsForCompany from './components/BenefitsForCompany';

const GuestForCompanyPage = (): JSX.Element => (
  <>
    <MainAppBar>
      <GuestDrawer />
    </MainAppBar>

    <ThemeProvider theme={theme}>
      <>
        <ProductHero />
        <ProductValues />
        <ProductSmokingHero />
        <ProductHowItWorks />
        <BenefitsForCompany />
      </>
    </ThemeProvider>

    <Footer />
  </>
);

export default GuestForCompanyPage;
