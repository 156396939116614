export interface DishOrder {
  dishId: string,
  dishName: string,
  quantity: number,
  comment: string,
}

export enum OrderStatus {
  PLACED = 'Złożono',
  RECEIVED = 'Odebrano',
  REJECTED = 'Odrzucono',
  IN_PROGRESS = 'W przygotowaniu',
  IN_TRANSIT = 'W dostawie',
  DELIVERED = 'Dostarczono',
}

export enum RawOrderStatus {
  PLACED = 'PLACED',
  RECEIVED = 'RECEIVED',
  REJECTED = 'REJECTED',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_TRANSIT = 'IN_TRANSIT',
  DELIVERED = 'DELIVERED',
}

export interface Order {
  id?: string;
  user: string;
  dishes: DishOrder[];
  dueAmount: string;
  locationId: number;
  deliveryDate: string;
  restaurant: number;
}
export interface OrderApiResponse extends Order {
  id: string;
  dateCreated: string;
  status: OrderStatus;
  company: number;
}

export interface HistoryOrder extends OrderApiResponse {
  companyName: string;
  locationName: string;
}

export const currentOrderStatuses = [
  'PLACED',
  'RECEIVED',
  'IN_PROGRESS',
  'IN_TRANSIT',
  'REJECTED',
];
export const historyOrderStatuses = [
  'DELIVERED',
];
