import React, { FC } from 'react';
import { CardMedia, Stack, Typography } from '@mui/material';

import errorImage from '../../../../assets/noActiveOrders.png';

const EmptyResturantList: FC = () => (
  <Stack direction="column" alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
    <CardMedia
      component="img"
      src={errorImage}
      sx={{ height: '137px', width: '104px', m: '40px 0 10px' }}
    />
    <Typography sx={{ fontSize: '15px', fontStyle: 'italic', fontWeight: 'bold', textAlign: 'center', m: '10px 20px 5px' }}>
      Brak dostępnych resturacji dla wybranych szczegółów dostawy.
    </Typography>
  </Stack>
);

export default EmptyResturantList;

