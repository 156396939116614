// baseUrl has to have slash at the end
const baseUrl: string = process.env.REACT_APP_AUTH_URL as string;

const apiUrl = `${baseUrl}api`;

// When update routes remeber to update error handler
const routes = {
  ACCOUNT: (id: number | string = ''): string => `accounts/${id}`,
  ACCOUNTS: 'accounts.json',

  COMPANIES_INFO: (id: number | string): string => `companies/${id}/info/`,
  COMPANIES: (id: number | string = ''): string => `companies/${id}.json`,
  COMPANIES_INDEX: 'companies.json',

  DISH: (id: number | string = ''): string => `dishes/${id}/`,
  DISHES: (id?: number | string): string => {
    if (id) {
      return `dishes/${id}.json`;
    }
    return `dishes/`;
  },
  DISH_INDEX: 'dishes.json',

  LOCATIONS: (id: number | string = ''): string => `locations/${id}`,

  ORDER: (id: number | string = ''): string => `orders/${id}`,
  ORDERS: (id?: number | string): string => {
    if (id) {
      return `orders/${id}.json`;
    }
    return `orders/`;
  },

  RESTAURANT_DISHES_LIST: (id: number | string = '1'): string => `restaurants/${id}/dishes/`,
  RESTAURANT_DELIVERY_DATES: (): string => `restaurants/availableDatesForLocation/`,
  RESTAURANTS_INDEX: 'restaurants.json',
  RESTAURANTS: (id?: number | string): string => {
    if (id) {
      return `restaurants/${id}.json`;
    }
    return `restaurants/`;
  },
  RESTAURANTS_INFO: (id: number | string): string => `restaurants/${id}/info/`,
  PAYMENTS: 'payments/',
  TAGS_INDEX: 'tags/',
  TOKEN: 'o/token/',
  EMPLOYEES_INDEX: (id?: number | string): string => {
    if (id) {
      return `employees/${id}.json`;
    }
    return 'employees/';
  },
};

export { routes, baseUrl };

export default apiUrl;

