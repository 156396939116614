import React, { useState } from 'react';
import { Button, TextField, Box, Typography, Container, Collapse, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import { sendPasswordReset } from '../../../api/apiPassword';
import CopyrightComponent from '../../../components/common/CopyrightComponent';
import { isSuccess } from '../../../api/axios';
import MainAppBar from '../../../components/common/MainAppBar';
import GuestDrawer from '../../../components/common/Drawer/GuestDrawer';

const SubmitButton = styled(Button)(() => ({
  borderRadius: 40,
  height: 36,
  marginTop: 4,
}));

const Form = styled('form')(() => ({
  minWidth: '-webkit-fill-available',
}));

const SUCCESS_TEXT = 'Jeżeli podany email istnieje, otrzymasz wiadomość e-mail z instrukcjami postępowania.';
const ERROR_TEXT = 'Wystąpił błąd. Spróbuj jeszcze raz.';

const PasswordReset: React.FunctionComponent = () => {
  const [email, setEmail] = useState('');
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const formSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();

    const result = await sendPasswordReset();
    const resultSuccess = isSuccess(result);

    setShowSuccessAlert(resultSuccess);
    setShowErrorAlert(resultSuccess);
  };

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.currentTarget.value);
  };
  const submitDisabled = email.length === 0;

  return (
    <div data-testid="account-page__container-testid">
      <MainAppBar>
        <GuestDrawer />
      </MainAppBar>
      <Container component="main" maxWidth="xs">
        <Stack alignItems="flex-start" marginTop={7} spacing={2}>
          <Typography component="div" variant="h2" fontWeight="bold">
            Zapomniałeś hasła?
          </Typography>

          <Typography component="div" variant="h5">
            Uzupełnij poniższe pole, wpisując swój adres e-mail. Otrzymasz wiadomość z linkiem umożliwiającym zmianę hasła.
          </Typography>

          <Collapse in={showSuccessAlert || showErrorAlert}>
            <Alert severity={showSuccessAlert ? 'success' : 'error'}>{ showSuccessAlert ? SUCCESS_TEXT : ERROR_TEXT }</Alert>
          </Collapse>

          <Form noValidate onSubmit={formSubmit}>
            <TextField
              color="primary"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-mail"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={onEmailChange}
              inputProps={{ 'data-testid': 'email-input' }}
            />
            <SubmitButton
              type="submit"
              name="submit-button"
              fullWidth
              variant="contained"
              color="primary"
              disabled={submitDisabled}
              data-testid="submit-input"
            >
              Przypomnij hasło
            </SubmitButton>
          </Form>
        </Stack>
        <Box mt={8}>
          <CopyrightComponent />
        </Box>
      </Container>
    </div>
  );
};

export default PasswordReset;
