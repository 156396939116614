import React from 'react';
import { Typography, styled, Container, Grid, CardMedia, Paper } from '@mui/material';

import { fontColors } from '../../../../../thema/AppThema';
import wyroznia1 from '../../../../../assets/wyroznia1.png';
import zadowoleni from '../../../../../assets/zadowoleni.png';
import wyroznia3 from '../../../../../assets/wyroznia3.png';

const OurArtifactsContainer = styled(Container)(({ theme }) => ({
  minHeight: '250px',
  padding: theme.spacing(0, 3),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '250px',
  boxShadow: 'none',
  border: '1px solid rgba(0, 0, 0, 0.151)',
}));

const OurArtifacts: React.FunctionComponent = () => {
  const ourArtifactsData = [
    {
      name: 'Niska cena',
      description: 'Każdy posiłek dofinansowany jest przez pracodawcę, co obniża jego cenę',
      src: wyroznia1,
      height: '94px',
      width: '78px',
    },
    {
      name: 'Zadowoleni pracownicy',
      description: 'Mniej czasu na przygotowywanie posiłku to więcej czasu dla Ciebie',
      src: zadowoleni,
      height: '65px',
      width: '118px',
    },
    {
      name: 'Najlepsze restauracje',
      description: 'Wyselekcjonowana lista cateringów, dbających o jakość Twojego posiłku',
      src: wyroznia3,
      height: '95px',
      width: '73px',
    },
  ];

  return (
    <OurArtifactsContainer>
      <Typography
        variant="body1"
        component="header"
        sx={{
          marginBottom: '15px',
          textAlign: 'center',
          fontSize: '2.8rem',
          fontWeight: 'bold',
          color: fontColors.lightDark,
        }}
      >
        To nas wyróżnia
      </Typography>

      <Grid container spacing={2}>
        {ourArtifactsData.map((item) => (
          <Grid item xs={12} sm={12} md={4} lg={4} key={item.name}>
            <Item>
              <CardMedia
                component="img"
                src={item.src}
                sx={{
                  height: `${item.height}`,
                  width: `${item.width}`,
                  mt: '10px',
                }}
              />
              <Typography
                variant="body1"
                component="header"
                sx={{
                  marginTop: '20px',
                  fontSize: '2rem',
                  fontWeight: 'bold',
                  color: fontColors.lightDark,
                }}
              >
                {item.name}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                sx={{
                  textAlign: 'center',
                  marginTop: '5px',
                  fontSize: '1.5rem',
                  padding: '0 20px',
                  color: fontColors.lightDark,
                }}
              >
                {item.description}
              </Typography>
            </Item>
          </Grid>
        ))}
      </Grid>
    </OurArtifactsContainer>
  );
};

export default OurArtifacts;
