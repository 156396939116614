import React from 'react';
import { Typography, styled } from '@mui/material';

const Title = styled(Typography)(() => ({
  fontSize: 20,
  margin: '28px 0 5px',
  fontWeight: 'bold',
}));

interface Props {
  description: string;
}

const Description: React.FC<Props> = ({ description }) => (
  <>
    <Title>
      Opis
    </Title>

    <Typography sx={{ fontSize: 16 }}>
      {description.length ? description : 'Brak opisu'}
    </Typography>
  </>
);
export default Description;
