import { useNavigate } from 'react-router-dom';

import { RoutesVars } from '../../const/constRoutes';

interface returnValues {
  handleError: (errorMessage: string, endpoint: string, requestMethod: string) => void
}

const useHandleError = (): returnValues => {
  const navigate = useNavigate();

  const handleError = (errorMessage: string, endpoint: string, requestMethod: string) => {
    navigate(RoutesVars.ERROR, { state: { errorMessage, endpoint, requestMethod } });
  };

  return { handleError };
};

export default useHandleError;
