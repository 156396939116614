import React from 'react';
import { Container } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Footer from '../../components/common/Footer';
import LayoutContainer from '../components/AppBarComponent/LayoutContainer';
import MainAppBar from '../../components/common/MainAppBar';
import UserDrawer from '../../components/common/Drawer/UserDrawer';

const AccountLayout: React.FunctionComponent = () => (
  <LayoutContainer>
    <MainAppBar>
      <UserDrawer />
    </MainAppBar>
    <Container
      sx={{
        padding: 0,
        flexGrow: '1',
      }}
    >
      <Outlet />
    </Container>
    <Footer />
  </LayoutContainer>
);

export default AccountLayout;
