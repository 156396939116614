import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../../components/Typography';

import productCurvyLines from '../assets/productCurvyLines.png';
import productValues1 from '../assets/productValues1.svg';
import productValues2 from '../assets/productValues2.svg';
import productValues3 from '../assets/productValues3.svg';

const item: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 1,
  mb: { xs: 5, md: 0 },
};

const title: SxProps<Theme> = {
  mt: { xs: 3, md: 5 },
  mb: { xs: 1, md: 1 },
  textAlign: 'center',
};

function ProductValues(): JSX.Element {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: '#f3eeef' }}
    >
      <Container sx={{ mt: 12, mb: { xs: 5, md: 20 }, display: 'flex', position: 'relative' }}>
        <Box
          component="img"
          src={productCurvyLines}
          alt="curvy lines"
          sx={{ pointerEvents: 'none', position: 'absolute', top: -180 }}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Box sx={item}>
              <Box
                component="img"
                src={productValues1}
                alt="suitcase"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={title}>
                Partnerstwo
              </Typography>
              <Typography variant="h5" sx={{ textAlign: 'center' }}>
                Wspólnie zwiększymy liczbę twoich zamówień
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={item}>
              <Box
                component="img"
                src={productValues2}
                alt="graph"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={title}>
                Serwis
              </Typography>
              <Typography variant="h5" sx={{ textAlign: 'center' }}>
                Zarządzaj lokalami, menu i zamówieniami w jednym miejscu
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={item}>
              <Box
                component="img"
                src={productValues3}
                alt="clock"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={title}>
                Rozwój
              </Typography>
              <Typography variant="h5" sx={{ textAlign: 'center' }}>
                Zbuduj lepszą markę i pozyskuj nowych klientów
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={item}>
              <Box
                component="img"
                src={productValues1}
                alt="suitcase"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={title}>
                Korzyści
              </Typography>
              <Typography variant="h5" sx={{ textAlign: 'center' }}>
                Podnieś atrakcyjność swojej oferty, zwiększ sprzedaż i rozwiń firmę
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
