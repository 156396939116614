/* eslint-disable max-len */
import React from 'react';
import { Typography, Link, CardMedia, styled, Container, Button } from '@mui/material';

import { fontColors, AppTheme } from '../../../../../thema/AppThema';
import oferta1 from '../../../../../assets/oferta1.png';
import oferta2 from '../../../../../assets/oferta2.png';
import { MAIN_PAGE_BUTTONS_BG_COLOR, MAIN_PAGE_BUTTONS_COLOR } from '../../GuestMainPage';

const OurOfferContainer = styled(Container)(({ theme }) => ({
  minHeight: '450px',
  padding: theme.spacing(0),
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(7),
  marginBottom: AppTheme.spacing(5),

  [AppTheme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const OurOfferElement = styled(Container)(({ theme }) => ({
  minheight: '360px',
  margin: theme.spacing(2, 0),
}));

const OurOfferMobile: React.FunctionComponent = () => (
  <OurOfferContainer>
    <Typography
      component="header"
      sx={{
        textAlign: 'center',
        fontSize: '2.8rem',
        fontWeight: 'bold',
        color: fontColors.lightDark,
      }}
    >
      Poznaj naszą ofertę
    </Typography>

    <OurOfferElement>
      <CardMedia
        component="img"
        src={oferta1}
      />
      <Typography
        component="header"
        sx={{
          fontSize: '2rem',
          fontWeight: 'bold',
          color: fontColors.lightDark,
          textTransform: 'uppercase',
          padding: AppTheme.spacing(1.875, 2.5),
        }}
      >
        DLA RESTAURACJI
      </Typography>
      <Typography
        component="p"
        sx={{
          fontSize: '1.6rem',
          color: fontColors.lightDark,
          padding: AppTheme.spacing(0, 2.5),
        }}
      >
        Oferujemy Ci narzędzie do obsługi zamówień od firm z twojej okolicy.
        Zyskujesz możliwość poszerzenia działalności o nowych klientów dzięki
        serwisowi MójPosiłek.
      </Typography>
      <Link
        href="/"
        sx={{
          textDecoration: 'none',
          padding: AppTheme.spacing(1.875, 2.5),
        }}
        data-testid="Dowiedz-się-więcej-pracownik-mobile-test-id"
      >
        <Button
          variant="contained"
          color="primary"
          sx={{
            borderRadius: '18px',
            fontSize: '1rem',
            textTransform: 'none',
            backgroundColor: MAIN_PAGE_BUTTONS_BG_COLOR,
            color: MAIN_PAGE_BUTTONS_COLOR,
            mt: '15px',
          }}
        >
          Dowiedz się więcej
        </Button>
      </Link>
    </OurOfferElement>
    <OurOfferElement>
      <CardMedia
        component="img"
        src={oferta2}
      />
      <Typography
        component="header"
        sx={{
          fontSize: '2rem',
          fontWeight: 'bold',
          color: fontColors.lightDark,
          textTransform: 'uppercase',
          padding: AppTheme.spacing(0.2, 2.5, 1.875),
        }}
      >
        DLA FIRM I PRACOWNIKÓW
      </Typography>
      <Typography
        component="p"
        sx={{
          fontSize: '1.6rem',
          color: fontColors.lightDark,
          padding: AppTheme.spacing(0, 2.5),
        }}
      >
        Jako firma płać mniej za posiłki i jednocześnie dbaj o swoich pracowników
        oferując im wygodny dostęp do ich ulubionych lokali.
        Oferujemy Ci szeroki wybór lokali z których możesz zamówić jedzenie.
        To Ty decydujesz co i skąd zamawiasz.
      </Typography>
      <Link
        href="/"
        sx={{
          textDecoration: 'none',
          padding: AppTheme.spacing(1.875, 2.5),
        }}
        data-testid="Dowiedz-się-więcej-pracodawca-mobile-test-id"
      >
        <Button
          variant="contained"
          color="primary"
          sx={{
            borderRadius: '18px',
            fontSize: '1rem',
            textTransform: 'none',
            backgroundColor: MAIN_PAGE_BUTTONS_BG_COLOR,
            color: MAIN_PAGE_BUTTONS_COLOR,
            mt: '15px',
          }}
        >
          Dowiedz się więcej
        </Button>
      </Link>
    </OurOfferElement>
  </OurOfferContainer>
);

export default OurOfferMobile;
