import { Dish, RawDish } from "../../../interfaces";
import { axiosApi } from "../../axios";
import { routes } from "../../routes";

const getRestaurantDishNEW = async (id: string): Promise<Dish> => {
  const response = await axiosApi.get<Dish>(routes.DISHES(id));
  return response.data;
};

const getRestaurantDishesNEW = async (id: string): Promise<Dish[]> => {
  const response = await axiosApi.get<Dish[]>(routes.RESTAURANT_DISHES_LIST(id));
  return response.data;
};

const deleteRestaurantDishesNEW = async (id: string): Promise<unknown> => {
  const response = await axiosApi.delete(routes.DISHES(id));
  return response.data;
};

const updateRestaurantDishNEW = async (id: string, body: RawDish): Promise<RawDish> => {
  const response = await axiosApi.patch<RawDish>(routes.DISHES(id), body);
  return response.data;
};

const createRestaurantDishNEW = async (body: RawDish): Promise<RawDish> => {
  const response = await axiosApi.post<RawDish>(routes.DISH_INDEX, body);
  return response.data;
};

export {
  getRestaurantDishNEW,
  getRestaurantDishesNEW,
  deleteRestaurantDishesNEW,
  updateRestaurantDishNEW,
  createRestaurantDishNEW,
};
