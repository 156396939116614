/* eslint-disable max-len */
import React from 'react';
import { Typography, styled, Container } from '@mui/material';

import { fontColors, AppTheme } from '../../../../../thema/AppThema';

const HowWorkContainer = styled(Container)(({ theme }) => ({
  minHeight: '250px',
  padding: theme.spacing(0, 3),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  [AppTheme.breakpoints.up('sm')]: {
    maxWidth: '640px',
    justifyContent: 'flex-end',
  },

  [AppTheme.breakpoints.up('md')]: {
    maxWidth: '715px',
  },

  [AppTheme.breakpoints.up('lg')]: {
    maxWidth: '830px',
  },

  [AppTheme.breakpoints.up('xl')]: {
    maxWidth: '1070px',
  },
}));

const HowWeWork: React.FunctionComponent = () => {
  const howWeWorkData =
  {
    title: 'Jak działamy?',
    description: 'Umożliwiamy pracownikom zamawianie posiłków z dofinansowaniem pracodawcy. Dzięki eliminacji zbędnych kosztów Twoi pracownicy zyskują dodatek, który pozwala im jeść co chcą i skąd chcą.',
  };

  return (
    <HowWorkContainer sx={{ mb: '50px' }}>
      <Typography
        variant="body1"
        component="header"
        sx={{
          textAlign: 'center',
          fontSize: '2.8rem',
          fontWeight: 'bold',
          color: fontColors.lightDark,
        }}
      >
        {howWeWorkData.title}
      </Typography>
      <Typography
        variant="body1"
        component="p"
        sx={{
          textAlign: 'center',
          fontSize: '1.6rem',
          color: fontColors.lightDark,
        }}
      >
        {howWeWorkData.description}
      </Typography>
    </HowWorkContainer>

  );
};

export default HowWeWork;
