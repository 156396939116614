import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../../components/Typography';

import productCurvyLines from '../assets/productCurvyLines.png';
import productHowItWorks1 from '../assets/productHowItWorks1.svg';
import productHowItWorks2 from '../assets/productHowItWorks2.svg';
import productHowItWorks3 from '../assets/productHowItWorks3.svg';

const item: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  px: 2,
  mb: { xs: 6, md: 8 },
};

const itemTextContent: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  px: 2,
};

const title: SxProps<Theme> = {
  mb: 1,
};

const image = {
  height: 55,
  mb: { xs: 3, md: 4 },
};

function BenefitsForCompany(): JSX.Element {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', bgcolor: 'white', overflow: 'hidden' }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          src={productCurvyLines}
          alt="curvy lines"
          sx={{
            pointerEvents: 'none',
            position: 'absolute',
            top: -180,
            opacity: 0.7,
          }}
        />
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 10, textAlign: 'center', maxWidth: 600 }}>
          Co Twoja firma zyskuje
        </Typography>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box
                  component="img"
                  src={productHowItWorks1}
                  alt="suitcase"
                  sx={image}
                />
                <Box sx={itemTextContent}>
                  <Typography variant="h6" sx={title}>
                    Pozytywny wizerunek
                  </Typography>
                  <Typography variant="body2">
                    Buduj lojalność, twórz wizerunek pracodawcy dbającego o swoich pracowników
                    i przyciągnij nowych
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box
                  component="img"
                  src={productHowItWorks2}
                  alt="graph"
                  sx={image}
                />
                <Box sx={itemTextContent}>
                  <Typography variant="h6" sx={title}>
                    Zwiększenie wydajności
                  </Typography>
                  <Typography variant="body2">
                    Integracja pracowników podczas wspólych posiłków, zwiększa motywację
                    oraz poprawia ich zadowolenie
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box
                  component="img"
                  src={productHowItWorks3}
                  alt="clock"
                  sx={image}
                />
                <Box sx={itemTextContent}>
                  <Typography variant="h6" sx={title}>
                    Transparentne zamówienia
                  </Typography>
                  <Typography variant="body2">
                    Jednolity, zintegrowany sposób zamawiania posiłków dla wszystkich pracowników
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Box>
  );
}

export default BenefitsForCompany;
