import React from 'react';
import { Container, Typography, styled, Stack, CardMedia, Button } from '@mui/material';
import { useCart } from 'react-use-cart';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import OrderSummaryDishItem from './components/OrderSummaryDishItem/OrderSummaryDishItem';
import requests from '../../../api';
import { Order, Payment } from '../../../interfaces';
import useMetadataValidation from '../../../shared/employeeShared/hooks/useMetadataValidation';
import useMetadata from '../../../shared/employeeShared/hooks/useMetadata';
import { Metadata } from '../../../const/constEmployee';
import OrderSummaryDetails from './components/OrderSummaryDetails/OrderSummaryDetails';
import { RoutesVars } from '../../../const/constRoutes';
import { groszeToPrice, priceToGrosze } from '../../../selectors/cartSelectors';
import noActiveOrders from '../../../assets/noActiveOrders.png';

const EmployeeOrderSummaryConatiner = styled(Container)(() => ({
  padding: 15,
}));

const ListContainer = styled(Container)(() => ({
  margin: '15px 0',
  padding: 0,
}));

// TODO: payment nie zawsze jest potrzebny
interface Props {
  paymentNeeded?: boolean;
}

const EmployeeOrderSummary: React.FC<Props> = ({ paymentNeeded = false }) => {
  const { cartTotal, items, emptyCart } = useCart();
  const { userId } = useParams();
  const { isMetadataValid } = useMetadataValidation();
  const { getMetadata } = useMetadata();
  const navigate = useNavigate();
  const itemsPresent = items.length > 0;
  const createPayment = useMutation(
    (data: Partial<Payment>) => requests.payments.createPayment(data),
    {
      onSuccess: (response: Payment) => {
        window.location.assign(response.redirectUrl);
      },
    },
  );

  const createOrder = useMutation(
    (data: Order) => (requests.orders.createOrder(data)),
    {
      onSuccess: (order) => {
        // TODO: Dodać popup zamiast alertu
        const amount = priceToGrosze(order.dueAmount);
        if (paymentNeeded) {
          createPayment.mutate({ order: order.id, amount });
        } else {
          emptyCart();
          navigate(RoutesVars.EMPLOYEE_ORDERS(userId as string));
        }
      },
      onError: () => {
        alert('Ooops coś poszło nie tak przy składaniu zamówienia');
      },
      // TODO: Do dodania error order handle, modal z informacja o errorze
    },
  );

  const handleSendOrder = () => {
    const order: Order = {
      user: userId as string,
      dueAmount: `${groszeToPrice(cartTotal)}`,
      dishes: items.map((dish) => ({
        dishId: dish.id,
        quantity: dish.quantity as number,
        comment: '',
        dishName: '',
      })),
      locationId: Number(getMetadata(Metadata.LOCATION_ID)),
      deliveryDate: getMetadata(Metadata.DATE) as string,
      restaurant: Number(getMetadata(Metadata.RESTAURANT_ID)),
    };

    if (itemsPresent && isMetadataValid) createOrder.mutate(order);

    return null;
  };

  const handleRedirectRestaurantsList = () => { userId && navigate(RoutesVars.EMPLOYEE_RESTAURANTS(userId)); };

  return (
    <EmployeeOrderSummaryConatiner disableGutters maxWidth="sm" data-testid="Employee-Order-Summary__container-testid">
      <Typography sx={{ fontSize: 32, fontWeight: 'bold' }}>Podsumowanie</Typography>

      {
        itemsPresent && (
          <>
            <Typography sx={{ m: '30px 0 5px', fontSize: 20, fontWeight: 'bold' }}>Twoje zamówienie</Typography>

            <ListContainer disableGutters>
              {items.map((cartItem) => {
                if (cartItem.quantity === undefined) return null;
                return (
                  <OrderSummaryDishItem
                    data-testid="Restaurant-Dish-List-Item__testid"
                    id={cartItem.id}
                    ingredients={cartItem.ingredients}
                    name={cartItem.name}
                    key={cartItem.id}
                    price={groszeToPrice(cartItem.price)}
                    quantity={cartItem.quantity}
                  />
                );
              })}
            </ListContainer>

            <Stack display="row" justifyContent="center" sx={{ width: '100%' }} data-testid="test">
              <OrderSummaryDetails handleSendOrder={handleSendOrder} />
            </Stack>
          </>
        )
      }
      {
        !itemsPresent && (
          <Stack flexDirection="column" alignItems="center" sx={{ mt: '50px' }}>
            <CardMedia
              component="img"
              src={noActiveOrders}
              sx={{ height: '110px', width: '85px', mb: '10px' }}
            />
            <Typography sx={{ mt: '20px', fontSize: 20, fontWeight: 'bold' }}>Twój koszyk jest pusty...</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRedirectRestaurantsList}
              sx={{
                p: '8px 40px',
                mt: '20px',
                maxWidth: '280px',
                borderRadius: '30px',
                fontSize: 15,
                fontWeight: 'bold',
                textTransform: 'none',
              }}
            >
              Złóż nowe zamówienie
            </Button>
          </Stack>
        )
      }
    </EmployeeOrderSummaryConatiner>
  );
};

EmployeeOrderSummary.defaultProps = {
  paymentNeeded: false,
};

export default EmployeeOrderSummary;
