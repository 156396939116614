import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import ProductHero from './components/ProductHero';
import MainAppBar from '../../../components/common/MainAppBar';
import GuestDrawer from '../../../components/common/Drawer/GuestDrawer';
import ProductHowItWorks from './components/ProductHowItWorks';
import ProductSmokingHero from './components/ProductSmokingHero';
import Footer from '../../../components/common/Footer';
import theme from './theme';
import BenefitInNumbers from './components/BenefitInNumbers';
import BenefitDetails from './components/BenefitDetails';

const GuestBenefitPage = (): JSX.Element => (
  <>
    <MainAppBar>
      <GuestDrawer />
    </MainAppBar>

    <ThemeProvider theme={theme}>
      <>
        <ProductHero />
        <BenefitInNumbers />
        <ProductSmokingHero />
        <ProductHowItWorks />
        <BenefitDetails />
      </>
    </ThemeProvider>

    <Footer />
  </>
);

export default GuestBenefitPage;
