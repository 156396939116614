import * as React from 'react';
import { Box, Checkbox, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { SortOrder, StyledTableCell, TableData } from './CompanyInvoicesTable';

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof TableData) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sortOrder: SortOrder;
  orderBy: string;
  rowCount: number;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof TableData;
  label: string;
  numeric: boolean;
  width: string;
}

const tableHead: readonly HeadCell[] = [
  {
    id: 'restaurantName',
    numeric: false,
    disablePadding: true,
    label: 'Resturacja',
    width: '150px',
  },
  {
    id: 'invoinceId',
    numeric: true,
    disablePadding: false,
    label: 'Nr Faktury',
    width: '100px',
  },
  {
    id: 'summaryOrdersCount',
    numeric: true,
    disablePadding: false,
    label: 'Liczba zamówień',
    width: '100px',
  },
  {
    id: 'summaryAmount',
    numeric: false,
    disablePadding: false,
    label: 'Kwota zamówień',
    width: '120px',
  },
  {
    id: 'ordersDateRange',
    numeric: false,
    disablePadding: false,
    label: 'Data zamówień',
    width: '200x',
  },
  {
    id: 'paymentDueDate',
    numeric: false,
    disablePadding: false,
    label: 'Data płatności',
    width: '80px',
  },
  {
    id: 'paymentStatus',
    numeric: false,
    disablePadding: false,
    label: 'Status płatności',
    width: '200px',
  },
];

export default function EnhancedTableHead(props: EnhancedTableProps): JSX.Element {
  const { onSelectAllClick, sortOrder, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof TableData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox">
          <Checkbox
            color="secondary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </StyledTableCell>
        {tableHead.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? sortOrder : false}
            width={headCell.width}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? sortOrder : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
