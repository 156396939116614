import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface Props {
  open: boolean;
  handleAgree: () => void;
  handleRefuse: () => void;
}
const AlertRedirectToDishList: React.FC<Props> = ({ open, handleAgree, handleRefuse }) => (
  <Dialog
    open={open}
    onClose={handleRefuse}
    aria-labelledby="alert-success-add-to-cart-dialog-title"
    aria-describedby="alert-success-add-to-car-dialog-description"
  >
    <DialogTitle id="alert-success-add-to-cart-dialog-title" sx={{ fontSize: 20, minWidth: 300 }}>
      Dodano danie
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-success-add-to-car-dialog-description" sx={{ fontSize: 15 }}>
        Dodałeś danie do koszyka
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button data-testid="test-id-return-to-resturant-page" onClick={handleAgree} autoFocus sx={{ fontSize: 12 }}>Powrót do listy dań</Button>
      <Button data-testid="test-id-stay-on-page" onClick={handleRefuse} sx={{ fontSize: 12 }}>Zostań</Button>
    </DialogActions>
  </Dialog>
);

export default AlertRedirectToDishList;
