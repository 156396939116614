import React from 'react';
import { Container, Stack, Typography, styled, Button, Alert, useMediaQuery } from '@mui/material';
import { useCart } from 'react-use-cart';
import useMetadata from '../../../../../shared/employeeShared/hooks/useMetadata';
import { Metadata } from '../../../../../const/constEmployee';
import useMetadataValidation from '../../../../../shared/employeeShared/hooks/useMetadataValidation';
import { AppTheme } from '../../../../../thema/AppThema';
import { groszeToPrice } from '../../../../../selectors/cartSelectors';

const OrderSummaryDetailsConatiner = styled(Container)(() => ({
  padding: 20,
  maxWidth: '600px',
}));

interface Props {
  handleSendOrder: () => void;
}

const OrderSummaryDetails: React.FC<Props> = ({ handleSendOrder }) => {
  const { cartTotal } = useCart();
  const { getMetadata } = useMetadata();
  const { isDeliveryMetadataValid } = useMetadataValidation();
  const isMoblie = useMediaQuery(AppTheme.breakpoints.down('sm'));

  const getDate = (): string => getMetadata(Metadata.DATE) || '-----';
  const getLocation = (): string => getMetadata(Metadata.LOCATION) || '-----';
  const getTime = (): string => getMetadata(Metadata.TIME) || '-----';
  const fontSize = isMoblie ? 14 : 16;

  return (
    <OrderSummaryDetailsConatiner disableGutters data-testid="Order-Summary-Details-Conatiner__container-testid">
      <Typography
        sx={{
          margin: '30px 0 5px',
          fontSize: 20,
          fontWeight: 'bold',
        }}
      >
        Podsumowanie
      </Typography>

      <Stack direction="row" justifyContent="space-between" sx={{ margin: '30px 0 10px' }}>
        <Typography sx={{ fontSize }}>Termin dostawy</Typography>

        <Typography data-testid="test-id-delivery-details" sx={{ fontSize, fontWeight: 'bold' }}>
          {`${getDate()}, ${getTime()}`}
        </Typography>
      </Stack>

      <Stack direction="row" justifyContent="space-between" sx={{ margin: '15px 0 10px' }}>
        <Typography sx={{ fontSize }}>Adres dostawy</Typography>
        <Typography data-testid="test-id-delivery-adress" sx={{ fontSize, fontWeight: 'bold' }}>{getLocation()}</Typography>
      </Stack>

      <Stack direction="row" justifyContent="space-between" sx={{ margin: '15px 0 10px' }}>
        <Typography sx={{ fontSize }}>Cena wyjściowa</Typography>
        <Typography data-testid="test-id-baseTotal" sx={{ fontSize, fontWeight: 'bold' }}>{`${groszeToPrice(cartTotal)} zł`}</Typography>
        {/* // TODO: Change discount price */}
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          margin: '15px 0 10px',
          paddingBottom: '23px',
          borderBottom: '1px solid black',
          maxWidth: '600px',
        }}
      >
        <Typography sx={{ fontSize }}>Kwota dofinansowania</Typography>
        <Typography sx={{ fontSize, fontWeight: 'bold' }} data-testid="test-id-bonus-total">{`${0} zł`}</Typography>
        {/* // TODO: Change discount price */}
      </Stack>

      <Stack direction="row" justifyContent="space-between" sx={{ margin: '30px 0 10px' }}>
        <Typography sx={{ fontSize, fontWeight: 'bold' }}>Do zapłaty</Typography>
        <Typography sx={{ fontSize, fontWeight: 'bold' }} data-testid="cartTotal">{`${groszeToPrice(cartTotal)} zł`}</Typography>
        {/* // TODO: Change discount price */}
      </Stack>

      <Stack direction="row" justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSendOrder()}
          sx={{
            width: '320px',
            borderRadius: '30px',
            fontSize: 15,
            fontWeight: 'bold',
            textTransform: 'none',
            margin: '20px 0 50px',
          }}
          data-testid="test-id-confirm-order-and-pay-button"
        >
          Zamawiam i płacę
        </Button>
      </Stack>

      {!isDeliveryMetadataValid && (
        <Alert severity="info" sx={{ fontSize: 12 }}>
          Nie wybrano wszystkich szczegółów dotyczących dostawy. Uzupełni barkujące dane aby złożyć zamówienie.
        </Alert>
      )}
    </OrderSummaryDetailsConatiner>
  );
};
export default OrderSummaryDetails;
