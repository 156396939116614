import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

import Typography from '../../../components/Typography';
import productHowItWorks1 from '../../assets/productHowItWorks1.svg';
import productHowItWorks2 from '../../assets/productHowItWorks2.svg';
import { categoryImg, categoryTitle, image, title, titleAndImg } from '../BenefitDetails';

export const itemDefault: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  px: 2,
  mb: { xs: 2, md: 12 },
  border: '2px solid #02061062',
  borderRadius: '24px',
  boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
};

export const itemEmployee: SxProps<Theme> = {
  ...itemDefault,
  height: { xs: 290, md: 370 },
};

export const itemCompany: SxProps<Theme> = {
  ...itemDefault,
  height: { xs: 450, md: 370 },
};

function EmployeeCareBenefit(): JSX.Element {
  return (
    <>
      <Box sx={categoryTitle}>
        <Box
          component="img"
          src={productHowItWorks2}
          alt="graph"
          sx={categoryImg}
        />
        <Box>
          <Typography variant="h4" component="h2" sx={{ px: 2 }}>
            Samopoczucie pracowników
          </Typography>
        </Box>
      </Box>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box sx={itemEmployee}>
              <Box sx={titleAndImg}>
                <Box
                  component="img"
                  src={productHowItWorks1}
                  alt="suitcase"
                  sx={image}
                />
                <Typography variant="h6" sx={title}>
                  Pracownicy
                </Typography>
              </Box>
              <Typography variant="body1">
                - czuje się dostrzeżony
              </Typography>
              <Typography variant="body1">
                - ma świadomość, że ktoś niego dba
              </Typography>
              <Typography variant="body1">
                - integruje się ze współpracownikami podczas posiłków
              </Typography>
              <Typography variant="body1">
                - benefit z którego chętnie się korzysta
              </Typography>
              <Typography variant="body1">
                - odciążenie budżetu domowego
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={itemCompany}>
              <Box sx={titleAndImg}>
                <Box
                  component="img"
                  src={productHowItWorks1}
                  alt="suitcase"
                  sx={image}
                />
                <Typography variant="h6" sx={title}>
                  Twoja firma
                </Typography>
              </Box>
              <Typography variant="body1">
                - zwiekszenie lojalności pracowników
              </Typography>
              <Typography variant="body1">
                - dodatkowy silny argument przy pozyskiwaniu pracowników
              </Typography>
              <Typography variant="body1">
                - zwiększenie morali pracownika
              </Typography>
              <Typography variant="body1">
                - budowanie swojej marki wewnątrz firmy
              </Typography>
              <Typography variant="body1">
                - ogranicza rotacje pracowników
              </Typography>
              <Typography variant="body1">
                - lepsze relacje pracowników
              </Typography>
              <Typography variant="body1">
                - nowoczesny i atrakcyjny charakter benefitu
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default EmployeeCareBenefit;
