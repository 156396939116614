import { Metadata, useCart } from 'react-use-cart';

interface returnValues {
  isDeliveryMetadataValid: boolean;
  isResturantMetadataValid: boolean;
  isMetadataValid: boolean;
}

const useMetadataValidation = (): returnValues => {
  const { metadata } = useCart();

  const deliveryMetadataValid: (metadata: Metadata | undefined) => boolean = (metadata: Metadata | undefined) => {
    if (!metadata) return false;
    if (metadata.date === undefined || metadata.date === '') return false;
    if (metadata.location === undefined || metadata.location === '') return false;
    if (metadata.time === undefined || metadata.time === '') return false;
    if (metadata.locationId === undefined || metadata.locationId === '') return false;
    return true;
  };

  const resturantMetadataValid: (metadata: Metadata | undefined) => boolean = (metadata: Metadata | undefined) => {
    if (!metadata) return false;
    if (metadata.restaurantId === undefined || metadata.restaurantId === '') return false;
    return true;
  };

  const isDeliveryMetadataValid = deliveryMetadataValid(metadata);
  const isResturantMetadataValid = resturantMetadataValid(metadata);
  const isMetadataValid = isResturantMetadataValid && isDeliveryMetadataValid;
  return {
    isDeliveryMetadataValid,
    isResturantMetadataValid,
    isMetadataValid,
  };
};

export default useMetadataValidation;
