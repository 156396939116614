import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { CircularProgress, Container, Typography } from '@mui/material';
import requests from '../../../api/apiOrders';
import { getLocations } from '../../../api/apiLocations';
import { getEmployees } from '../../../api/apiEmployees';
import CompanyOrderCard from '../../../components/common/Card/CompanyOrderCard';
import { CompanyOrder, companyOrdersSelector } from '../../Restaurant/selectors';
import { getStatus } from '../../../selectors';
import MainContainer from '../../../components/common/MainContainer';

const CompanyOrdersPage: React.FunctionComponent = () => {
  const params = useParams();
  const companyId = Number(params.id as unknown as string);
  const { data: orders, status: orderStatus } = useQuery(['orderIndex'], requests.getOrders({ params: { company: companyId } }));
  const { data: locations, status: locationStatus } = useQuery(['locationsIndex'], getLocations);
  const { data: employees, status: employeeStatus } = useQuery(['employeesIndex'], getEmployees({ params: { company: companyId } }));
  const [allOrders, setAllOrders] = React.useState<CompanyOrder[]>([]);
  const status = getStatus(orderStatus, locationStatus, employeeStatus);

  useEffect(
    () => {
      if (orders && locations && employees) {
        const historyOrders = companyOrdersSelector(orders, locations, employees);
        setAllOrders(() => historyOrders);
      }
    },
    [orders, locations, employees],
  );

  return (
    <MainContainer data-testid="company-employee-orders-page__container-testid">
      {
        status === 'loading' && (
          <Container sx={{ paddingBottom: '30px', display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Container>
        )
      }
      {
        status === 'success' && (
          <>
            <Container sx={{ paddingBottom: '30px' }}>
              <Typography variant="h2" component="h1">
                Zamówienia pracowników
              </Typography>
            </Container>
            <Container>
              <div data-testid="Company-Orders-Page__container-testid">
                {
                  allOrders.map((order) => <CompanyOrderCard key={order.id} order={order} />)
                }
              </div>
            </Container>
          </>
        )
      }
    </MainContainer>
  );
};

export default CompanyOrdersPage;

