import * as React from 'react';
import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

import MobileContent from './MobileContent/MobileContent';
import DesktopContent from './DesktopContent/DesktopContent';
import { AppTheme } from '../../../thema/AppThema';

const FooterContainer = styled('footer')(() => ({
  padding: 20,
  backgroundColor: '#F3F3F3',
}));

const MobileContentWrapper = styled(Stack)(() => ({
  [AppTheme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const DesktopContentWrapper = styled(Stack)(() => ({
  [AppTheme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const Footer: React.FunctionComponent = () => (
  <FooterContainer>
    <MobileContentWrapper>
      <MobileContent />
    </MobileContentWrapper>

    <DesktopContentWrapper>
      <DesktopContent />
    </DesktopContentWrapper>
  </FooterContainer>
);

export default Footer;

