import React from 'react';
import { Container } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Footer from '../../components/common/Footer';
import LayoutContainer from '../components/AppBarComponent/LayoutContainer';
import MainAppBar from '../../components/common/MainAppBar';
import CompanyDrawer from '../../components/common/Drawer/CompanyDrawer';
import { backgroundColors } from '../../thema/AppThema';

const CompanyLayout: React.FunctionComponent = () => (
  <LayoutContainer>
    <MainAppBar>
      <CompanyDrawer />
    </MainAppBar>
    <Container
      maxWidth="lg"
      disableGutters
      sx={{ padding: 0, flexGrow: '1', minHeight: 500, bgcolor: backgroundColors.main }}
    >
      <Outlet />
    </Container>
    <Footer />
  </LayoutContainer>
);

export default CompanyLayout;
