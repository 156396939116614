import { routes } from './routes';
import { getResource, getResources } from './axios';
import { Location } from '../interfaces';

const getLocation = getResource<Location>(routes.LOCATIONS());
export const getLocations = getResources<Location>(routes.LOCATIONS());

const requestRepositoryLocation = {
  getLocation,
  getLocations,
};

export default requestRepositoryLocation;
