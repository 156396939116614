import * as React from 'react';
import { Typography, CardContent, Card } from '@mui/material';
import { HistoryOrder } from '../../../interfaces';

interface Props {
  order: HistoryOrder;
}

export default function PrintableOrderCard({ order }: Props): React.ReactElement {
  const shouldDisplayCommentsPlaceholder = !order.dishes.filter((dish) => dish.comment !== "").length;

  return (
    <Card variant="outlined" sx={{ position: 'relative', m: 1, p: 1 }}>
      <CardContent sx={{ marginTop: 1, mb: 1 }}>
        <Typography sx={{ fontSize: 16 }}>
          {`Numer zamówienia: ${order.id}`}
        </Typography>
        <Typography sx={{ fontSize: 16 }}>
          {`Termin dostawy: ${order.deliveryDate}`}
        </Typography>
        <Typography sx={{ fontSize: 16 }}>
          {`Firma zamawiająca: ${order.companyName}`}
        </Typography>
        <Typography sx={{ fontSize: 16 }}>
          {`Miejsce: ${order.locationName}`}
        </Typography>
        <Typography sx={{ fontSize: 16 }}>
          {`Cena zamówienia: ${order.dueAmount} zł`}
        </Typography>
        <Typography sx={{ fontSize: 16 }}>
          Posiłki:
          {order.dishes.map((dish) => (
            <>{` ${dish.quantity}x ${dish.dishName}, `}</>
          ))}
        </Typography>
        <Typography sx={{ fontSize: 16 }}>
          Komentarz:
          {order.dishes.map((dish) => (
            dish.comment ? (
              <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                {` ${dish.dishName}: ${dish.comment}, `}
              </Typography>
            ) : null
          ))}
          {shouldDisplayCommentsPlaceholder && " Brak komentarza."}
        </Typography>
      </CardContent>
    </Card>
  );
}
