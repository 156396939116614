import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Container, Typography, styled, LinearProgress, Alert, Snackbar } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useCart } from 'react-use-cart';
import { useLocation } from 'react-router-dom';

import OrderItem from './components/OrderItem/OrderItem';
import MakeOrders from './components/MakeOrders/MakeOrders';
import { OrderApiResponse } from '../../../interfaces';
import { getStatus } from '../../../selectors';
import { currentOrderStatuses, historyOrderStatuses } from '../../../interfaces/Order';
import { UserContext } from '../../../contexts/userContext/userContext';
import { QueryKeys } from '../../../api/services/api/queryKeys';
import { getOrdersNEW } from '../../../api/services/api/apiOrders';
import ErrorPage from '../../Error/ErrorPage';

const EmployeeMyOrdersPageConatiner = styled(Container)(() => ({ padding: 20 }));

const EmployeeMyOrdersPage: FC = () => {
  const location = useLocation();
  const { emptyCart } = useCart();
  const { state: { user } } = useContext(UserContext);

  const [alertOpen, setAlertOpen] = useState(false);
  const [ordersHistory, setOrdersHistory] = useState<OrderApiResponse[]>([]);
  const [ordersActive, setOrdersActive] = useState<OrderApiResponse[]>([]);

  const { data: ordersActiveData, status: ordersActiveStatus } = useQuery({
    queryKey: [QueryKeys.ORDER_INDEX_CURRENT, `user-${user?.id}`],
    queryFn: () => getOrdersNEW({ status__in: currentOrderStatuses.join(',') }),
    meta: { errorMessage: 'Błąd podczas ładowania aktywnych zamówień' },
    retry: 2,
  });

  const { data: ordersHistoryData, status: ordersHistoryStatus } = useQuery({
    queryKey: [QueryKeys.ORDER_INDEX_HISTORY, `user-${user}`],
    queryFn: () => getOrdersNEW({ status__in: historyOrderStatuses.join(',') }),
    meta: { errorMessage: 'Błąd podczas ładowania historii zamówień' },
    retry: 2,
  });

  useEffect(() => { if (ordersActiveData) { setOrdersActive(ordersActiveData); } }, [ordersActiveData]);
  useEffect(() => { if (ordersHistoryData) { setOrdersHistory(ordersHistoryData); } }, [ordersHistoryData]);

  const status = useMemo(() => getStatus(ordersHistoryStatus, ordersActiveStatus), [ordersHistoryStatus, ordersActiveStatus]);

  useEffect(() => {
    if (location.search.includes("paymentStatus=CONFIRMED")) {
      setAlertOpen(true);
      emptyCart();
    }
  }, [location.search, emptyCart]);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;

    setAlertOpen(false);
  };

  return (
    <EmployeeMyOrdersPageConatiner disableGutters maxWidth="md" data-testid="Employee-My-Orders-Page__container-testid">
      {status === "error" && <Container sx={{ mt: 8 }}><ErrorPage /></Container>}
      {status === 'loading' && (<LinearProgress />)}
      {status === 'success' && ordersHistory && ordersActive && (
        <>
          <Snackbar
            open={alertOpen}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            sx={{ width: '100%' }}
          >
            <Alert severity="success" sx={{ width: '100%' }}>
              Twój posiłek został opłacony
            </Alert>
          </Snackbar>

          <Typography sx={{ fontSize: 32, fontWeight: 'bold', mb: '10px' }}>
            Moje zamówienia
          </Typography>

          {user && <MakeOrders hasActiveOrders={!!ordersActive} userId={user.id} />}

          <Typography sx={{ fontSize: 20, fontWeight: 'bold', m: '25px 0 15px' }}>
            Aktywne zamówienia
          </Typography>

          <Container sx={{ p: 0 }}>
            {ordersActive && ordersActive.map((order) => (
              <OrderItem key={order.id} order={order} />
            ))}
          </Container>

          <Typography sx={{ fontSize: 20, fontWeight: 'bold', m: '25px 0 15px' }}>
            Historia zamówień
          </Typography>

          <Container sx={{ p: 0 }}>
            {ordersHistory && ordersHistory.map((order) => (
              <OrderItem key={order.id} order={order} />
            ))}
          </Container>
        </>
      )}

    </EmployeeMyOrdersPageConatiner>
  );
};
export default EmployeeMyOrdersPage;
