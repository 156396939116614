import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import HistoryOrderCard from '../components/HistoryOrderCard';
import { HistoryOrder } from '../../../interfaces';
import { historyOrdersSelector } from '../selectors';
import { getStatus } from '../../../selectors';
import OrdersFilterer from '../components/OrdersFilterer';
import MainContainer from '../../../components/common/MainContainer';
import { QueryKeys } from '../../../api/services/api/queryKeys';
import { getOrdersNEW } from '../../../api/services/api/apiOrders';
import SearchInput from '../../../components/common/SearchInput';
import { OrderStatus, historyOrderStatuses } from '../../../interfaces/Order';
import ErrorPage from '../../Error/ErrorPage';
import { getLocationsNEW } from '../../../api/services/api/apiLocations';
import { getCompaniesNEW } from '../../../api/services/api/apiCompany';

const RestaurantOrdersHistoryPage: FC = () => {
  const params = useParams();
  const restaurantId = params.id;

  const [searchResult, setSearchResult] = useState<HistoryOrder[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<HistoryOrder[]>([]);
  const [allOrders, setAllOrders] = useState<HistoryOrder[]>([]);

  const { data: orders, status: orderStatus } = useQuery({
    queryKey: [QueryKeys.ORDER_INDEX_HISTORY, `restaurant-${restaurantId}`],
    queryFn: () => getOrdersNEW({ status__in: historyOrderStatuses.join(',') }),
    meta: { errorMessage: 'Błąd podczas ładowania historii zamówień' },
    retry: 2,
  });

  const { data: locations, status: locationStatus } = useQuery({
    queryKey: [QueryKeys.LOCATIONS_INDEX, `restaurant-${restaurantId}`],
    queryFn: () => getLocationsNEW(),
    meta: { errorMessage: 'Błąd podczas ładowania lokalizacji' },
    retry: 2,
  });

  const { data: companies, status: companyStatus } = useQuery({
    queryKey: [QueryKeys.COMPANY_INDEX, `restaurant-${restaurantId}`],
    queryFn: () => getCompaniesNEW(),
    meta: { errorMessage: 'Błąd podczas ładowania firm' },
    retry: 2,
  });

  useEffect(() => {
    if (orders && locations && companies) {
      const filteredOrders = orders.filter((order) => (order.status !== OrderStatus.PLACED &&
        order.status !== OrderStatus.RECEIVED &&
        order.status !== OrderStatus.IN_PROGRESS &&
        order.status !== OrderStatus.IN_TRANSIT));
      const historyOrders = historyOrdersSelector(filteredOrders, locations, companies);

      setFilteredOrders(historyOrders);
      setAllOrders(historyOrders);
    }
  }, [orders, locations, companies]);

  const setVisibleOrders = (orders: HistoryOrder[]) => {
    if (locations && companies) {
      const historyOrders = historyOrdersSelector(orders, locations, companies);
      setFilteredOrders(historyOrders);
    }
  };

  const searchKeys = useMemo(() => ['id'], []);
  const searchCallback = useCallback(
    (results: HistoryOrder[]) => {
      setSearchResult(results);
    }, [],
  );

  const status = getStatus(orderStatus, locationStatus, companyStatus);
  const dataReady = orders && companies && locations;

  return (
    <MainContainer data-testid="restaurant-menu-page__container-testid">
      {status === "error" && <Container sx={{ mt: 8 }}><ErrorPage /></Container>}
      {status === 'loading' && <CircularProgress />}
      {status === 'success' && dataReady && (
        <>
          <Container sx={{ pb: 2.5 }}>
            <Typography component="h1" sx={{ fontSize: 32, fontWeight: 500 }}>
              Historia zamówień
            </Typography>
          </Container>
          <Container sx={{ display: "flex", justifyConten: "space-between", width: "100%", pb: 1 }}>
            <Box sx={{ minWidth: 200, flexGrow: '3' }}>
              <SearchInput
                list={filteredOrders}
                keys={searchKeys}
                callback={searchCallback}
                label="Wyszukaj..."
                isDisabled={status !== 'success'}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: 'center' }}>
              <OrdersFilterer
                orders={allOrders}
                companies={companies}
                locations={locations}
                setVisibleOrders={setVisibleOrders}
              />
            </Box>
          </Container>
          <Container>
            {searchResult.map((order) => (
              <HistoryOrderCard key={order.id} order={order} />
            ))}
          </Container>
        </>
      )}
    </MainContainer>
  );
};

export default RestaurantOrdersHistoryPage;
