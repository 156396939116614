import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import './App.css';
import { RoutesVars } from './const/constRoutes';
import GuestMainPage from './pages/Guest/GuestMainPage/GuestMainPage';
import LoginPage from './pages/Login/LoginPage';
import AccountLayout from './layouts/AccountLayout/AccountLayout';
import PasswordReset from './pages/Guest/PasswordReset';
import GuestAboutUs from './pages/Guest/GuestAboutUsPage/GuestAboutUs';
import AccountMainPage from './pages/Account/AccountMainPage/AccountMainPage';
import CompanyLayout from './layouts/CompanyLayout/CompanyLayout';
import CompanyMainPage from './pages/Company/CompanyMainPage/CompanyMainPage';
import CompanyEmployeesPage from './pages/Company/CompanyEmployeesPage/CompanyEmployeesPage';
import CompanyOrdersPage from './pages/Company/CompanyOrdersPage/CompanyOrdersPage';
import RestaurantLayout from './layouts/RestaurantLayout/RestaurantLayout';
import RestaurantMainPage from './pages/Restaurant/RestaurantMainPage/RestaurantMainPage';
import RestaurantMenuPage from './pages/Restaurant/RestaurantMenuPage/RestaurantMenuPage';
import EmployeeLayout from './layouts/EmployeeLayout/EmployeeLayout';
import AuthContext, { useAuthContext } from './contexts/AuthContext/AuthContext';
import PrivateRoute from './components/common/PrivateRoute';
import EmployeeRestaurantsPage from './pages/Employee/EmployeeRestaurantsPage/EmployeeRestaurantsPage';
import EmployeeSelectedRestaurantPage from './pages/Employee/EmployeeSelectedRestaurantPage/EmployeeSelectedRestaurantPage';
import EmployeeRestaurantSelectedDishPage from './pages/Employee/EmployeeRestaurantSelectedDishPage/EmployeeRestaurantSelectedDishPage';
import EmployeeOrderSummary from './pages/Employee/EmployeeOrderSummary/EmployeeOrderSummary';
import EmployeeMyOrdersPage from './pages/Employee/EmployeeMyOrdersPage/EmployeeMyOrdersPage';
import UserContextProvider from './contexts/userContext/userContext';
import RestaurantNewDishPage from './pages/Restaurant/RestaurantNewDishPage/RestaurantNewDishPage';
import RestaurantEditDishPage from './pages/Restaurant/RestaurantEditDishPage/RestaurantEditDishPage';
import RestaurantOrdersHistoryPage from './pages/Restaurant/RestuarantOrdersHistoryPage';
import ContactPage from './pages/Guest/GuestContactPage/Contact';
import RestaurantCurrentOrdersPage from './pages/Restaurant/RestaurantCurrentOrdersPage';
import ErrorPage from './pages/Error/ErrorPage';
import CompanyOrdersSummaryPage from './pages/Company/CompanyOrdersSummaryPage/CompanyOrdersSummaryPage';
import GuestForCompanyPage from './pages/Guest/GuestForCompanyPage/GuestForCompanyPage';
import GuestForRestaurantPage from './pages/Guest/GuestForRestaurantPage/GuestForRestaurantPage';
import GuestBenefitPage from './pages/Guest/GuestBenefitPage/GuestBenefitPage';
import CompanyInvoices from './pages/Company/CompanyInvoices/CompanyInvoices';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // refetchOnMount: false, //?
      // refetchOnReconnect: false, //?
      retry: 2,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (error instanceof AxiosError) {
        if (!query.meta?.hideDefaultToast) {
          if (query.meta && query.meta.errorMessage) {
            const message = query.meta.errorMessage as string;
            toast.error(message);
          } else if (query.state.data === undefined) {
            toast.error(`Ooops coś poszło nie tak: ${error.message}`);
          }
        }
      }
    },
    // onSuccess: (data, query) => {
    //   console.log('QueryCache-onSuccess', data, query);
    //   toast.success('Poprawnie wczytano dane.');
    // },
    // onSettled: (data, error) => {
    //   console.log('QueryCache-onSettled', data, error);
    // },
  }),
});

const App = (): JSX.Element => {
  const authContext = useAuthContext();

  return (
    <div className="app" data-testid="app__container">
      <AuthContext.Provider value={authContext}>
        <QueryClientProvider client={queryClient}>
          <UserContextProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<GuestMainPage />} />
                <Route path={RoutesVars.ABOUT_US} element={<GuestAboutUs />} />
                <Route path={RoutesVars.FOR_COMPANY} element={<GuestForCompanyPage />} />
                <Route path={RoutesVars.FOR_RESTAURANT} element={<GuestForRestaurantPage />} />
                <Route path={RoutesVars.BENEFIT} element={<GuestBenefitPage />} />
                <Route path={RoutesVars.CONTACT} element={<ContactPage />} />
                <Route path={RoutesVars.LOGIN} element={<LoginPage />} />
                <Route path={RoutesVars.PASSWORD_RESET} element={<PasswordReset />} />
                <Route path={RoutesVars.ERROR} element={<ErrorPage />} />
                <Route path="/account/*" element={<PrivateRoute><AccountLayout /></PrivateRoute>}>
                  <Route index element={<AccountMainPage />} />
                </Route>

                <Route path="/companies/*" element={<PrivateRoute><CompanyLayout /></PrivateRoute>}>
                  <Route path=":id" element={<CompanyMainPage />} />
                  <Route path=":id/employees" element={<CompanyEmployeesPage />} />
                  <Route path=":id/employees/new" element={<div />} />
                  <Route path=":id/orders" element={<CompanyOrdersPage />} />
                  <Route path=":id/orders-summary" element={<CompanyOrdersSummaryPage />} />
                  <Route path=":id/invoices" element={<CompanyInvoices />} />
                </Route>

                <Route path="/restaurants/*" element={<PrivateRoute><RestaurantLayout /></PrivateRoute>}>
                  <Route path=":id" element={<RestaurantMainPage />} />
                  <Route path=":id/menu" element={<RestaurantMenuPage />} />
                  <Route path=":id/dish/new" element={<RestaurantNewDishPage />} />
                  <Route path=":id/dish/:dishId/edit" element={<RestaurantEditDishPage />} />
                  <Route path=":id/orders-history" element={<RestaurantOrdersHistoryPage />} />
                  <Route path=":id/current-orders" element={<RestaurantCurrentOrdersPage />} />
                </Route>

                <Route path="/employees/*" element={<PrivateRoute><EmployeeLayout /></PrivateRoute>}>
                  <Route path=":userId/restaurants" element={<EmployeeRestaurantsPage />} />
                  <Route path=":userId/restaurants/:restaurantId" element={<EmployeeSelectedRestaurantPage />} />
                  <Route path=":userId/restaurants/:restaurantId/dish/:dishId" element={<EmployeeRestaurantSelectedDishPage />} />
                  <Route path=":userId/restaurants/order-summary" element={<EmployeeOrderSummary paymentNeeded={false} />} />
                  <Route path=":userId/orders" element={<EmployeeMyOrdersPage />} />
                  <Route path=":userId/my-profile" element={<div>Profile Page</div>} />
                  <Route path="orders/success" element={<EmployeeMyOrdersPage />} />
                </Route>
              </Routes>
            </BrowserRouter>
            <ReactQueryDevtools />
          </UserContextProvider>
        </QueryClientProvider>
      </AuthContext.Provider>
    </div>
  );
};

export default App;
