import React, { ReactElement } from 'react';
import IconButton from '@mui/material/IconButton';
import { Drawer, ListItem, Link, Box } from '@mui/material';
import { styled } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Link as RouterLink } from 'react-router-dom';

export const DrawerListItem = styled(ListItem)(() => ({
  marginLeft: 20,
  marginRight: 20,
  paddingLeft: 0,
  paddingRight: 0,
  width: '90%',
}));

export type BaseDrawerProps = {
  isOpen?: boolean;
  onCloseCallback?: () => undefined;
  children?: ReactElement | ReactElement[];
};

type DraweLinkProps = {
  path: string;
  children: ReactElement | string;
  onClick?: () => unknown;
};

export const DrawerLink: React.FC<DraweLinkProps> = ({ path, onClick, children }) => (
  <Link to={path} component={RouterLink} underline="none" color="secondary" onClick={onClick}>
    {children}
  </Link>
);

const BaseDrawer: React.FC<BaseDrawerProps> = ({ isOpen, onCloseCallback, children }) => (
  <Drawer
    anchor="right"
    open={isOpen}
    onClose={onCloseCallback}
    id="test-id-drawer"
  >
    <Box role="presentation" display="flex" flexDirection="column">
      <IconButton
        color="secondary"
        aria-label="close drawer"
        onClick={onCloseCallback}
        size="large"
        sx={{
          alignSelf: 'flex-end',
          marginRight: 2,
        }}
      >
        <CloseIcon sx={{ fontSize: 40 }} />
      </IconButton>
      {children}
    </Box>
  </Drawer>
);

export default BaseDrawer;
