import * as React from 'react';

import Button from '../../components/Button';
import Typography from '../../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import introDesktop from '../../../../assets/introDesktop.png';

export default function ProductHero(): JSX.Element {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${introDesktop})`,
        backgroundColor: 'rgba(250, 242, 242, 0.151)',
        backgroundPosition: 'center',
      }}
    >
      <img
        style={{ display: 'none' }}
        src={introDesktop}
        alt="increase priority"
      />
      <Typography color="inherit" align="center" variant="h2" marked="center">
        DLA FIRM
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: { xs: 4, sm: 10 }, maxWidth: 900, fontSize: 20 }}
      >
        Skorzystaj z dofinansowań posiłków w miejscu pracy. Ustal dogodny termin i miejsce dostaw.
        Uzyskasz wiele korzyści takich jak skrócenie czasu poszukiwania posiłków przez pracowników
        w czasie pracy, integracja pracowników czy serwis który pozwoli Ci na zarządzanie i
        kontrolę wykorzystanych benefitów.
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        component="a"
        href="/benefit"
        sx={{ minWidth: 200, fontSize: 14 }}
      >
        Dowiedz się więcej o beneficie
      </Button>
    </ProductHeroLayout>
  );
}
