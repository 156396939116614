import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import productCurvyLines from '../assets/productCurvyLines.png';
import FoodBenefit from './BenefitDetailsComponents/FoodBenefit';
import AmenitiesBenefit from './BenefitDetailsComponents/AmenitiesBenefit';
import ConvenienceBenefit from './BenefitDetailsComponents/ConvenienceBenefit';
import TimeSavingsBenefit from './BenefitDetailsComponents/TimeSavingsBenefit';
import EmployeeCareBenefit from './BenefitDetailsComponents/EmployeeCareBenefit';
import MoneySavingsBenefit from './BenefitDetailsComponents/MoneySavingsBenefit';
import Typography from '../../components/Typography';

export const categoryTitle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  mb: { xs: 2, md: 3 },
  mt: 6,
};

export const titleAndImg: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  pt: 2,
};

export const categoryImg: SxProps<Theme> = {
  height: 55,
  mb: { xs: 1, md: 2 },
};

export const title: SxProps<Theme> = {
  mb: 1,
  textAlign: 'center',
};

export const image = {
  height: 55,
  mb: { xs: 3, md: 4 },
};

function BenefitDetails(): JSX.Element {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', bgcolor: '#white', overflow: 'hidden' }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          src={productCurvyLines}
          alt="curvy lines"
          sx={{
            pointerEvents: 'none',
            position: 'absolute',
            top: -180,
            opacity: 0.7,
          }}
        />
        <Typography variant="h2" marked="center" component="h2" sx={{ px: 2, mb: 5 }}>
          Zyski dla całej firmy
        </Typography>
        <FoodBenefit />
        <EmployeeCareBenefit />
        <MoneySavingsBenefit />
        <TimeSavingsBenefit />
        <ConvenienceBenefit />
        <AmenitiesBenefit />
      </Container>
    </Box>
  );
}

export default BenefitDetails;
