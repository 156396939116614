import * as React from 'react';

import Typography from '../../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import introDesktop from '../../../../assets/introDesktop.png';

export default function ProductHero(): JSX.Element {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${introDesktop})`,
        backgroundColor: 'rgba(250, 242, 242, 0.151)',
        backgroundPosition: 'center',
      }}
    >
      <img
        style={{ display: 'none' }}
        src={introDesktop}
        alt="increase priority"
      />
      <Typography color="inherit" align="center" variant="h2" marked="center">
        Dla restauracji
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: { xs: 4, sm: 10 }, maxWidth: 800, fontSize: 20 }}
      >
        Serwis MójPosiłek powstał z myślą o restauratorach,
        którzy chcą rozszerzyć swoją oferte o nowych odbiorców.
        Zapewniamy stałe źródło zleceń, przyjmowanie zamówień i kontakt z klientem.
      </Typography>
    </ProductHeroLayout>
  );
}
