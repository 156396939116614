import { useCart } from 'react-use-cart';
import { Metadata } from '../../../const/constEmployee';

interface ReturnTypes {
  updateMetadata: (type: Metadata, value: string | undefined) => void;
  getMetadata: (type: Metadata) => string | undefined;
}

const useMetadata = (): ReturnTypes => {
  const { metadata, updateCartMetadata, setCartMetadata } = useCart();

  const updateMetadata = (type: Metadata, value: string | undefined) => {
    if (metadata === undefined) {
      setCartMetadata({});
    }

    if (type === Metadata.LOCATION) {
      updateCartMetadata({ location: value });
    }

    if (type === Metadata.DATE) {
      updateCartMetadata({ date: value });
    }

    if (type === Metadata.TIME) {
      updateCartMetadata({ time: value });
    }

    if (type === Metadata.LOCATION_ID) {
      updateCartMetadata({ locationId: value });
    }

    if (type === Metadata.RESTAURANT_ID) {
      updateCartMetadata({ restaurantId: value });
    }
  };

  const getMetadata = (type: Metadata): string | undefined => {
    if (metadata === undefined) return undefined;

    if (type === Metadata.LOCATION) return metadata.location;

    if (type === Metadata.DATE) return metadata.date;

    if (type === Metadata.TIME) return metadata.time;

    if (type === Metadata.LOCATION_ID) return metadata.locationId;

    if (type === Metadata.RESTAURANT_ID) return metadata.restaurantId;

    return undefined;
  };

  return { updateMetadata, getMetadata };
};

export default useMetadata;
