import React, { FC } from 'react';
import { Divider, Stack, Typography } from '@mui/material';

import { OrderApiResponse } from '../../../../interfaces';
import OrderItemDishes from './OrderItemDishes/OrderItemDishes';

interface Props {
  order: OrderApiResponse;
}

const WrapperOrderItemDishes: FC<Props> = ({ order }) => (
  <>
    <Typography
      sx={{ fontSize: 14, mb: '3px', fontWeight: 400 }}
      color="text.secondary"
    >
      Posiłki:
    </Typography>

    <Stack sx={{ ml: '5px' }}>
      {order.dishes.map((dish) => (
        <OrderItemDishes dish={dish} key={dish.dishId} />
      ))}

      {!order.dishes.length && <Typography sx={{ fontSize: 15 }}>Brak posiłków.</Typography>}

    </Stack>

    <Divider sx={{ m: '13px 0' }} />

    <Stack direction="row" justifyContent="flex-end" sx={{ mr: '30px' }}>
      <Typography sx={{ fontSize: 16, mr: '10px' }}>Opłacono:</Typography>
      <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>{`${order.dueAmount} zł`}</Typography>
    </Stack>
  </>
);

export default WrapperOrderItemDishes;
