import { Dispatch } from 'react';
import accountRepository from '../../api/apiAccounts';
import { deleteToken } from '../AuthContext/utils';
import { FETCH_USER, LOGOUT_USER, FETCH_USER_ERROR, ReducerAction } from './reducer';

export type Action = (dispatch: Dispatch<ReducerAction>) => Promise<void>;

export const fetchUser: Action = async (disptach: Dispatch<ReducerAction>) => {
  try {
    const result = await accountRepository.getAccounts();
    const [payload] = result;

    disptach({ type: FETCH_USER, payload });
  } catch {
    disptach({ type: FETCH_USER_ERROR, payload: null });
  }
};

export const logoutUser = (dispatch: Dispatch<ReducerAction>): void => {
  deleteToken();
  dispatch({
    type: LOGOUT_USER,
  });
};

const actions = {
  fetchUser,
  logoutUser,
};

export default actions;
