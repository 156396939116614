import * as React from 'react';
import { Typography, CardContent, Card, styled, IconButtonProps, IconButton, Collapse, CardHeader } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { OrderApiResponse } from '../../../interfaces';
// import ExpandMore from './ExpandMore';

export type SectionProps = {
  title: string;
  children?: React.ReactElement | string;
};

const Section: React.FC<SectionProps> = ({ title, children }) => (
  <>
    <Typography sx={{ fontSize: '0.9rem' }} color="text.secondary" gutterBottom>
      {title}
    </Typography>
    {children}
  </>
);

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})((props) => ({
  transform: !props.expand ? 'rotate(0deg)' : 'rotate(180deg)',
  margin: 'auto',
  transition: props.theme.transitions.create('transform', {
    duration: props.theme.transitions.duration.shortest,
  }),
}));

interface Props {
  order: OrderApiResponse & { employeeName: string, locationName: string };
}

export default function CompanyOrderCard({ order }: Props): React.ReactElement {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = (): void => { setExpanded(!expanded); };

  return (
    <Card variant="outlined" sx={{ position: 'relative', marginBottom: '6px' }}>
      <CardHeader
        action={(
          <ExpandMore
            expand={expanded}
            aria-label="show more"
            onClick={handleExpandClick}
            data-testid={`expand-more-${order.id}`}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        )}
        title={order.id}
        subheader="Numer zamówienia"
        sx={{
          '& .MuiCardHeader-content': {
            display: 'flex',
            flexDirection: 'column-reverse',
          },
        }}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Section title="Zamawiający">
            <Typography sx={{ fontSize: '1rem' }}>
              { order.employeeName }
            </Typography>
          </Section>
          <Section title="Data zamówienia">
            <Typography sx={{ fontSize: '1rem' }}>
              {order.dateCreated}
            </Typography>
          </Section>
          <Section title="Data dostarczenia">
            <Typography sx={{ fontSize: '1rem' }}>
              {order.deliveryDate}
            </Typography>
          </Section>
          <Section title="Miejsce">
            <Typography sx={{ fontSize: '1rem' }}>
              { order.locationName }
            </Typography>
          </Section>
          <Section title="Cena zamówienia">
            <Typography sx={{ fontSize: '1rem' }}>
              {order.dueAmount}
            </Typography>
          </Section>
        </CardContent>
      </Collapse>
    </Card>
  );
}
