import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface Props {
  open: boolean;
  handleAgree: () => void;
  handleRefuse: () => void;
}
const AlertChangeDeliveryDetails: React.FC<Props> = ({ open, handleAgree, handleRefuse }) => (
  <Dialog
    open={open}
    onClose={handleRefuse}
    aria-labelledby="alert-change-delivery-details-dialog-title"
    aria-describedby="alert-change-delivery-details-dialog-description"
  >
    <DialogTitle id="alert-success-add-to-cart-dialog-title" sx={{ fontSize: 20, minWidth: 300 }}>
      Masz dodane posiłki do zamówienia!
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-change-delivery-details-dialog-description" sx={{ fontSize: 15 }}>
        Czy napewno chcesz zmienić szczegóły dostawy? Spowoduje to usunięcie dodanych posiłków z koszyka!
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleRefuse} color="secondary" sx={{ fontSize: 12 }}>Anuluj</Button>
      <Button onClick={handleAgree} color="error" autoFocus sx={{ fontSize: 12 }}>Usuń posiłki</Button>
    </DialogActions>
  </Dialog>
);

export default AlertChangeDeliveryDetails;
