import * as React from 'react';
import { Typography, Button, CardContent, CardActions, Card, styled, IconButtonProps, IconButton, Collapse, CardHeader, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Dish, Tag } from '../../../interfaces';

export type SectionProps = {
  title: string;
  children?: React.ReactElement | string;
};

const Section: React.FC<SectionProps> = ({ title, children }) => (
  <Stack sx={{ mt: '5px' }}>
    <Typography sx={{ fontSize: 17, fontWeight: 'bold', mt: '5px' }} color="text.secondary" gutterBottom>
      {title}
    </Typography>
    {children}
  </Stack>
);

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})((props) => ({
  transform: !props.expand ? 'rotate(0deg)' : 'rotate(180deg)',
  margin: 'auto',
  transition: props.theme.transitions.create('transform', {
    duration: props.theme.transitions.duration.shortest,
  }),
}));

interface Props {
  dish: Dish;
  deleteCallback: () => void;
  hideCallback: () => void;
  editCallback: (dish: Dish) => () => void;
  cuisinesList: Tag[];
  ingredientsList: Tag[];
  allergensList: Tag[];
}

const DishCard = ({ dish, deleteCallback, hideCallback, editCallback, cuisinesList, ingredientsList, allergensList }: Props): React.ReactElement => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = (): void => { setExpanded(!expanded); };

  const cuisinesToDisplay = cuisinesList.filter((cuisine) => dish.cuisine.includes(cuisine.internalName));
  const allergensToDisplay = allergensList.filter((allergen) => dish.allergens.includes(allergen.internalName));
  const ingredientsToDisplay = ingredientsList.filter((ingredient) => dish.ingredients.includes(ingredient.internalName));

  return (
    <Card variant="outlined" sx={{ position: 'relative', marginBottom: '6px', bgcolor: dish.isHidden ? 'lightGray' : 'transparent' }}>
      <CardHeader
        action={(
          <ExpandMore
            expand={expanded}
            aria-label="show more"
            onClick={handleExpandClick}
            data-testid={`expand-more-${dish.id}`}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        )}
        title={dish.name}
        subheader="Nazwa dania"
        sx={{
          '& .MuiCardHeader-content': { display: 'flex', flexDirection: 'column-reverse' },
          '& .MuiCardHeader-subheader': { fontSize: 17, fontWeight: 'bold' },
          '& .MuiCardHeader-title': { fontSize: 15 },
        }}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Section title="Alergeny">
            <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
              {allergensToDisplay.map(((allergen) => (
                <Stack sx={{ p: '0 10px 5px 0' }} key={allergen.internalName} direction="row" alignItems="center">
                  <svg width="22px" height="22px" radius="30px">
                    <image xlinkHref={`${allergen.img}`} width="22px" height="22px" radius="30px" />
                  </svg>
                  <Typography component="span" sx={{ ml: '3px', fontSize: 14 }}>
                    {allergen.name}
                  </Typography>
                </Stack>
              )))}
              {!allergensToDisplay.length && <Typography component="span" sx={{ fontSize: 14 }}>Brak alergenów do wyświetlenia</Typography>}
            </Stack>
          </Section>

          <Section title="Składniki">
            <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
              {ingredientsToDisplay.map(((ingredient) => (
                <Stack sx={{ p: '0 10px 5px 0' }} key={ingredient.internalName} direction="row" alignItems="center">
                  <svg width="22px" height="22px" radius="30px">
                    <image xlinkHref={`${ingredient.img}`} width="22px" height="22px" radius="30px" />
                  </svg>
                  <Typography component="span" sx={{ ml: '3px', fontSize: 14 }}>
                    {ingredient.name}
                  </Typography>
                </Stack>
              )))}
              {!ingredientsToDisplay.length && <Typography component="span" sx={{ fontSize: 14 }}>Brak składników do wyświetlenia</Typography>}
            </Stack>
          </Section>

          <Section title="Kuchnie">
            <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
              {cuisinesToDisplay.map(((cuisines) => (
                <Stack sx={{ p: '0 10px 5px 0' }} key={cuisines.internalName} direction="row" alignItems="center">
                  <svg width="22px" height="22px" radius="30px">
                    <image xlinkHref={`${cuisines.img}`} width="22px" height="22px" radius="30px" />
                  </svg>
                  <Typography component="span" sx={{ ml: '3px', fontSize: 14 }}>
                    {cuisines.name}
                  </Typography>
                </Stack>
              )))}
              {!cuisinesToDisplay.length && <Typography component="span" sx={{ fontSize: 14 }}>Brak kuchni do wyświetlenia</Typography>}
            </Stack>
          </Section>

          <Section title="Cena dania">
            <Typography sx={{ fontSize: 15, fontWeight: 'bold' }}>
              {dish.price}
            </Typography>
          </Section>
        </CardContent>

        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="text" onClick={hideCallback}>
            <Typography sx={{ textDecoration: 'underline', fontSize: 14 }}>
              {dish.isHidden ? 'Pokaż w menu' : 'Ukryj z menu'}
            </Typography>
          </Button>
          <Button variant="text" onClick={editCallback(dish)}>
            <Typography sx={{ textDecoration: 'underline', fontSize: 14 }}>Edytuj</Typography>
          </Button>
          <Button variant="text" startIcon={<DeleteIcon />} onClick={deleteCallback}>
            <Typography sx={{ textDecoration: 'underline', fontSize: 14 }}>Usuń</Typography>
          </Button>
        </CardActions>
      </Collapse>
    </Card>
  );
};

export default DishCard;
