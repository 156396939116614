import React, { FC, useEffect, useState } from 'react';
import { Box, Container, Divider, Grid, LinearProgress, Paper, styled, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { backgroundColors, fontColors } from '../../../thema/AppThema';
import strings from '../../../assets/strings/companiesStrings.json';
import { CompanyInfo } from '../../../interfaces';
import { getCompanyInfo } from '../../../api/services/api/apiCompanyInfo';
import ErrorPage from '../../Error/ErrorPage';

export const GridItem = styled(Paper)(({ theme }) => ({
  backgroundColor: backgroundColors.card,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
}));

const CompanyMainPage: FC = () => {
  const params = useParams();

  const companyId = params.id as unknown as string;

  const [companyInfo, setCompanyInfo] = useState<CompanyInfo>();

  const query = useQuery({
    queryKey: ['getCompanyInfo', companyId],
    queryFn: () => getCompanyInfo(companyId),
    meta: { errorMessage: 'Błąd podczas ładowania informacji o firmie.' },
    retry: false,
  });

  useEffect(() => {
    if (query.data) { setCompanyInfo(query.data); }
  }, [query.data]);

  const items = [
    { title: strings.mainPage.cartItem1, value: companyInfo?.employeeCount },
    { title: strings.mainPage.cartItem2, value: companyInfo?.orderedDishesCount },
    { title: strings.mainPage.cartItem3, value: `${companyInfo?.currentInvoiceAmount} zł` },
    { title: strings.mainPage.cartItem4, value: companyInfo?.nextInvoiceDeadline },
  ];

  return (
    <Container disableGutters sx={{ px: 2 }}>
      {query.isError && <Container sx={{ mt: 8 }}><ErrorPage /></Container>}
      {query.isLoading && <LinearProgress />}
      {query.isSuccess && companyInfo && (
        <>
          <Typography variant="h2" component="h1" sx={{ pb: 3, pt: 4, fontWeight: 'bold' }}>
            Moja firma
          </Typography>

          <Box display="flex" justifyContent="center" sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              {items.map((item) => (
                <Grid item xs={4} sm={4} md={3} key={item.title}>
                  <GridItem sx={{ height: { xs: 120, sm: 130, md: 160, lg: 140 } }}>
                    <Typography sx={{ fontSize: 22, flexGrow: 1, color: fontColors.lightDark }}>
                      {item.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 28,
                        fontWeight: 'bold',
                        flexGrow: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {item.value}
                    </Typography>
                  </GridItem>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box sx={{ my: 5 }}>
            <Typography sx={{ fontSize: 25, fontWeight: 'bold' }}>
              {strings.mainPage.deliveryHoursTile.replace('{{companyName}}', companyInfo.name)}
            </Typography>
            <Typography sx={{ fontSize: 16 }}>{strings.mainPage.deliveryHoursText}</Typography>

            <Box sx={{ pt: 1, pl: 2 }}>
              {companyInfo.locations.map((location, index) => (
                <Box key={location.id}>
                  <Typography variant="h3" sx={{ fontSize: 20, fontWeight: 'bold', mt: '10px' }}>
                    {location.time}
                  </Typography>
                  <Typography component="li" sx={{ listStyle: 'none', fontSize: 16, pt: '2px' }}>
                    {`${location.name}, ${location.address.street} `}
                  </Typography>
                  <Typography component="li" sx={{ listStyle: 'none', fontSize: 16, fontStyle: 'italic' }}>
                    {`${location.address.city}, ${location.address.postcode}`}
                  </Typography>
                  {index !== (companyInfo.locations.length - 1) && <Divider sx={{ m: '10px 0 15px' }} />}
                </Box>
              ))}
            </Box>
          </Box>
        </>
      )}

    </Container>
  );
};

export default CompanyMainPage;

