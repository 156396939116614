import * as React from 'react';
import { IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import { alpha } from '@mui/material/styles';
import IosShareIcon from '@mui/icons-material/IosShare';
import FilterListIcon from '@mui/icons-material/FilterList';

interface EnhancedTableToolbarProps {
  numSelected: number;
  printStuff: () => void;
}

export default function EnhancedTableToolbar(props: EnhancedTableToolbarProps): JSX.Element {
  const { numSelected, printStuff } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%', fontSize: 17, fontWeight: 'bold' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          Wybrano:
          {' '}
          {numSelected}
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%', fontSize: 17 }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Lista zamówień pracowników
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Drukuj wybrane">
          <IconButton onClick={printStuff}><IosShareIcon /></IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filtruj">
          <IconButton><FilterListIcon /></IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

