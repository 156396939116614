import React from 'react';
import { Stack, Typography, styled } from '@mui/material';

import { Tag } from '../../../../interfaces';

const Title = styled(Typography)(() => ({
  fontSize: 20,
  margin: '28px 0 5px',
  fontWeight: 'bold',
}));

interface Props {
  allergens: string[];
  allergensList: Tag[];
}

const AllergensList: React.FC<Props> = ({ allergens, allergensList }) => {
  const allergensToDisplay = allergensList.filter((allergen) => allergens.includes(allergen.internalName));

  return (
    <>
      <Title>Alergeny</Title>

      <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
        {allergensToDisplay.map(((allergen) => (
          <Stack
            key={allergen.internalName}
            direction="row"
            alignItems="center"
            sx={{ padding: '0 25px 10px 0' }}
          >
            <svg width="22px" height="22px" radius="30px">
              <image xlinkHref={`${allergen.img}`} width="22px" height="22px" radius="30px" />
            </svg>
            <Typography component="span" sx={{ marginLeft: '7px', fontWeight: 'bold', fontSize: 16 }}>
              {allergen.name}
            </Typography>
          </Stack>
        )))}

        {!allergensToDisplay.length && <Typography component="span" sx={{ fontSize: 16 }}>Brak alergenów do wyświetlenia</Typography>}
      </Stack>
    </>

  );
};
export default AllergensList;
