import React from 'react';
import { CartProvider } from 'react-use-cart';
import { Container } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Footer from '../../components/common/Footer';
import LayoutContainer from '../components/AppBarComponent/LayoutContainer';
import MainAppBar from '../../components/common/MainAppBar';
import UserDrawer from '../../components/common/Drawer/UserDrawer';
import { backgroundColors } from '../../thema/AppThema';

const EmployeeLayout: React.FunctionComponent = () => (
  <CartProvider>
    <LayoutContainer>
      <MainAppBar>
        <UserDrawer />
      </MainAppBar>
      <Container
        data-testid="EmployeeLayout__container-testid"
        maxWidth="md"
        disableGutters
        sx={{ padding: 0, flexGrow: '1', bgcolor: backgroundColors.main }}
      >
        <Outlet />
      </Container>
      <Footer />
    </LayoutContainer>
  </CartProvider>
);
export default EmployeeLayout;
