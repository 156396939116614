
import { AxiosResponse } from 'axios';
import { getRefreshToken } from '../contexts/AuthContext/utils';
import { axiosBase, Result, ErrorResult } from './axios';
import { routes } from './routes';

type PostTokenResponse = {
  accessToken: string
  expiresIn: number
  refreshToken: string
  scope: string
  tokenType: 'Bearer'
};

class LoginResult implements Result {
  success = true;

  response: AxiosResponse;

  static isSuccess = (result: LoginResult | ErrorResult): result is LoginResult => result.success;

  constructor(response: AxiosResponse) {
    this.response = response;
  }

  tokenInfo = (): PostTokenResponse => ({
    accessToken: this.response.data.access_token,
    expiresIn: this.response.data.expires_in,
    refreshToken: this.response.data.refresh_token,
    scope: this.response.data.scope,
    tokenType: this.response.data.token_type,
  });
}

const postToken = async (username: string, password: string): Promise<AxiosResponse> => {
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
  const grantType = process.env.REACT_APP_GRANT_TYPE;
  const credentials = `client_id=${clientId}&client_secret=${clientSecret}&grant_type=${grantType}&username=${username}&password=${password}`;

  return axiosBase.post(routes.TOKEN, credentials);
};

const refreshAccessToken = async (): Promise<LoginResult | ErrorResult> => {
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
  const refreshToken = getRefreshToken();
  const credentials = `grant_type=refresh_token&refresh_token=${refreshToken}&client_id=${clientId}&client_secret=${clientSecret}`;

  try {
    const response = await axiosBase.post(routes.TOKEN, credentials);

    return new LoginResult(response);
  } catch (error) {
    return new ErrorResult(error);
  }
};

const loginUser = async (username: string, password: string): Promise<LoginResult | ErrorResult> => {
  try {
    const response = await postToken(username, password);

    return new LoginResult(response);
  } catch (error) {
    return new ErrorResult(error);
  }
};

const requestRepository = {
  loginUser,
};

export default requestRepository;
export { LoginResult, loginUser, refreshAccessToken };
export type { PostTokenResponse };
