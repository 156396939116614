import React from 'react';
import { CardActionArea, CardMedia, Container, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { useCart } from 'react-use-cart';
import { useNavigate, useParams } from 'react-router-dom';

import { AppTheme } from '../../../../../thema/AppThema';
import useMetadata from '../../../../../shared/employeeShared/hooks/useMetadata';
import { Metadata } from '../../../../../const/constEmployee';
import { RoutesVars } from '../../../../../const/constRoutes';

interface Props {
  id: string;
  ingredients: string[]
  name: string;
  price: number;
  quantity: number;
}

const OrderSummaryDishItemContainer = styled(Container)(() => ({
  padding: '18px 0',
  borderBottom: '1px solid rgba(139, 138, 138, 0.178)',
  minHeight: '120px',
}));

const OrderSummaryDishItem: React.FC<Props> = ({ id, ingredients, name, price, quantity }) => {
  const { updateItemQuantity, removeItem, getItem, items } = useCart();
  const { getMetadata, updateMetadata } = useMetadata();
  const navigate = useNavigate();
  const { userId } = useParams();

  const isMoblie = useMediaQuery(AppTheme.breakpoints.down('sm'));
  const dish = getItem(id);
  const restaurantId = getMetadata(Metadata.RESTAURANT_ID);

  const handleRemoveItem = (id: string) => {
    removeItem(id);
    // if last item removed form cart we clear resturatnId
    if (items.length === 1) {
      updateMetadata(Metadata.RESTAURANT_ID, '');
    }
  };

  const handleCardClick = () => { userId && restaurantId && navigate(RoutesVars.EMPLOYEE_RESTAURANT_SELECTED_DISH(userId, restaurantId, id)); };

  return (
    <OrderSummaryDishItemContainer disableGutters maxWidth="sm">
      <Grid container direction="row">
        <Grid item container alignItems="center" xs={4} sx={{ maxWidth: '120px' }}>
          <CardActionArea onClick={handleCardClick}>
            <CardMedia
              component="img"
              src={dish && dish.imgSrc}
              sx={{ height: '110px', width: '110px', borderRadius: '18px', mr: '5px' }}
            />
          </CardActionArea>
        </Grid>

        <Grid item container direction="column" xs>
          <Grid container item direction="row" justifyContent="space-between" flexWrap="nowrap">
            <Grid item>
              <CardActionArea onClick={handleCardClick}>
                <Typography gutterBottom sx={{ fontWeight: 'bold', fontSize: 18 }}>{name}</Typography>
              </CardActionArea>
            </Grid>
            <IconButton
              aria-label="delete dish - order summary"
              color="secondary"
              disabled={quantity < 1}
              onClick={() => handleRemoveItem(id)}
              sx={{ fontSize: isMoblie ? 12 : 16, padding: 0, textDecoration: 'underline' }}
              data-testid={`test-id-delete-dish-from-cart-${id}`}
            >
              <DeleteIcon sx={{ fontSize: isMoblie ? 16 : 20 }} />
              Usuń
            </IconButton>
          </Grid>

          <Grid item>
            <CardActionArea onClick={handleCardClick}>
              <Typography component="div" gutterBottom sx={{ display: 'flex', flexWrap: 'wrap', pr: '50px' }}>
                {ingredients.length ? ingredients.map(((ingredient, index) => (
                  <Typography component="span" key={ingredient} sx={{ px: '2px', fontSize: 12 }}>
                    {`${ingredient}${index === ingredients.length - 1 ? '' : ','}`}
                  </Typography>
                ))) : (
                  <Typography component="span" sx={{ px: '2px', fontSize: 12 }}>Nie dodano listy składników.</Typography>
                )}
              </Typography>
            </CardActionArea>
          </Grid>

          <Grid item container direction="row" alignItems="center" justifyContent="space-between">
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              xs={7}
              sx={{
                maxWidth: '136px',
                [AppTheme.breakpoints.up('sm')]: {
                  maxWidth: '180px',
                },
              }}
            >
              <Grid item xs>
                <CardActionArea onClick={handleCardClick}>
                  <Typography
                    component="div"
                    sx={{
                      px: isMoblie ? '1px' : '10px',
                      fontSize: isMoblie ? 16 : 22,
                      fontWeight: 'bold',
                    }}
                  >
                    {`${price.toFixed(2)} zł`}
                  </Typography>
                </CardActionArea>
              </Grid>
            </Grid>

            <Grid item container direction="row" alignItems="center" justifyContent="flex-end" xs={5}>
              <IconButton
                aria-label={`Usuń jeden posiłek: ${name} z zamówienia`}
                color="primary"
                disabled={quantity <= 1}
                onClick={() => updateItemQuantity(id, quantity - 1)}
                data-testid={`test-id-remove-one-dish-from-cart-${id}`}
              >
                <RemoveCircleIcon sx={{ fontSize: isMoblie ? 20 : 25 }} />
              </IconButton>
              <Typography
                data-testid={`test-id-number-dishes-in-cart-${id}`}
                aria-label={`Liczba posiłku: ${name} w koszyku`}
                component="div"
                sx={{ fontSize: isMoblie ? 15 : 20, fontWeight: 'bold' }}
              >
                {`${quantity}`}
              </Typography>
              <IconButton
                aria-label={`Dodaj jeden posiłek: ${name} do zamówienia`}
                color="primary"
                onClick={() => updateItemQuantity(id, quantity + 1)}
                data-testid={`test-id-add-one-dish-to-cart-${id}`}
              >
                <AddCircleIcon sx={{ fontSize: isMoblie ? 20 : 25 }} />
              </IconButton>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </OrderSummaryDishItemContainer>
  );
};

export default OrderSummaryDishItem;
