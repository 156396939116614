import React from 'react';
import { Button, Container, Link, Typography, styled } from '@mui/material';

import { fontColors } from '../../../../../thema/AppThema';
import { MAIN_PAGE_BUTTONS_BG_COLOR, MAIN_PAGE_BUTTONS_COLOR } from '../../GuestMainPage';

const IntroductionContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(0, 3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const MobileHeader: React.FunctionComponent = () => {
  const headerMobileData =
  {
    title: 'Catering dla twoich pracowników',
    description: 'Oferuj swoim pracownikom wygodny dostęp do posiłków z ich ulubionych restauracji bez wychodzenia z biura.',
    buttonText: 'Poznaj nas',
  };

  return (
    <IntroductionContainer>
      <Typography
        variant="body1"
        component="header"
        sx={{
          textAlign: 'center',
          fontSize: '3.4rem',
          fontWeight: 'bold',
          color: fontColors.lightDark,
          mt: '15px',
          maxWidth: '320px',
        }}
      >
        {headerMobileData.title}
      </Typography>
      <Typography
        variant="body1"
        component="p"
        sx={{
          textAlign: 'center',
          fontSize: '1.6rem',
          mt: '15px',
          maxWidth: '325px',
        }}
      >
        {headerMobileData.description}
      </Typography>
      <Link
        href="/"
        sx={{
          textDecoration: 'none',
        }}
        data-testid="Poznaj-nas-mobile-test-id"
      >
        <Button
          variant="contained"
          color="primary"
          sx={{
            borderRadius: '18px',
            fontSize: '1.4rem',
            textTransform: 'none',
            backgroundColor: MAIN_PAGE_BUTTONS_BG_COLOR,
            color: MAIN_PAGE_BUTTONS_COLOR,
            mt: '40px',
          }}
        >
          {headerMobileData.buttonText}
        </Button>
      </Link>
    </IntroductionContainer>
  );
};

export default MobileHeader;
