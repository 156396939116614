import React, { useState, FC } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { OrderStatus } from '../../../../interfaces/Order';

interface Props {
  initialStatus: OrderStatus;
  callback: (status: OrderStatus) => void;
}

const OrderStatusSelect: FC<Props> = ({ initialStatus, callback }) => {
  const [status, setStatus] = useState(initialStatus);

  const onChange = (event: SelectChangeEvent) => {
    const indexOfStatus = Object.values(OrderStatus).indexOf(event.target.value as unknown as OrderStatus);
    const statusKey = Object.keys(OrderStatus)[indexOfStatus];

    setStatus(() => event.target.value as OrderStatus);
    callback(statusKey as OrderStatus);
  };

  return (
    <FormControl sx={{ m: 0, p: 0 }} fullWidth>
      <Select
        id="order-status-select"
        data-testid="order-status-select"
        defaultValue={initialStatus}
        value={status}
        onChange={onChange}
        fullWidth
        inputProps={{ 'data-testid': 'order-status-select-input' }}
        sx={{ fontSize: 13, mt: 0.5, fontWeight: 500 }}
      >
        {Object.entries(OrderStatus).map(([key, val]) => (
          <MenuItem
            key={key}
            value={val}
            sx={{ fontSize: 13, fontWeight: 500 }}
          >
            {val}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default OrderStatusSelect;
