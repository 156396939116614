import { routes } from './routes';
import { createResource, patchResource, deleteResource, getResource, getResources, ErrorResult, Result } from './axios';
import { Order, OrderApiResponse } from '../interfaces';

type GetOrderPromise = () => Promise<OrderApiResponse>;
type UpdateOrderPromise = Promise<Result | ErrorResult>;
type GetOrdersPromise = () => Promise<OrderApiResponse[]>;

const getOrder = (id: string | number): GetOrderPromise => getResource<OrderApiResponse>(routes.ORDERS(id));
const getOrders =
  ({ id, params }: { id?: string | number, params?: unknown } = {}): GetOrdersPromise => getResources<OrderApiResponse>(routes.ORDERS(id), params);

const createOrder = createResource<Order>(routes.ORDERS());
const updateOrder =
  (id: string | number, body: Partial<OrderApiResponse>): UpdateOrderPromise => patchResource<OrderApiResponse>(routes.ORDERS(id), body);
const deleteOrder = (id: string | number): Promise<Result> => deleteResource(routes.ORDERS(id));

export default {
  getOrder,
  getOrders,
  createOrder,
  updateOrder,
  deleteOrder,
};
