import * as React from 'react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '../../components/Typography';

function ProductSmokingHero(): JSX.Element {
  return (
    <Container
      component="section"
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: { xs: 5, md: 9 }, overflow: 'hidden' }}
    >
      <Typography color="inherit" align="center" variant="h2" marked="center">
        Masz jakieś pytania?
      </Typography>
      <Button
        color="secondary"
        size="large"
        variant="contained"
        component="a"
        href="/contact"
        sx={{ mt: 8, p: 2, fontSize: 14 }}
      >
        Skontaktuj się z nami
      </Button>
    </Container>
  );
}

export default ProductSmokingHero;
