import React, { FC, useEffect, useState } from 'react';
import { CardMedia, Stack, Typography } from '@mui/material';

import { useLocation } from 'react-router-dom';
import errorImage from '../../assets/noActiveOrders.png';

import {
  getErrorCode,
  translateErrorEnpointKey,
  translateErrorMessage,
  translateErrorRequestType,
} from './utils';

interface ErrorPageType {
  errorMessage: string;
  endpoint: string;
  requestMethod: string;
}

const ErrorPage: FC = () => {
  const location = useLocation();

  const [message, setMessage] = useState<string>('');
  const [endpoint, setEndpoint] = useState<string>('');
  const [requestMethod, setRequestMethod] = useState<string>('');

  function isErrorData(state: ErrorPageType | unknown): state is ErrorPageType {
    return (
      (state as ErrorPageType).errorMessage !== undefined &&
      (state as ErrorPageType).endpoint !== undefined &&
      (state as ErrorPageType).requestMethod !== undefined
    );
  }

  useEffect(() => {
    if (location.state && isErrorData(location.state)) {
      setMessage(location.state.errorMessage);
      setEndpoint(location.state.endpoint);
      setRequestMethod(location.state.requestMethod);
    }
  }, [location.state]);

  const pureStatusCode = getErrorCode(message);
  const errorCode = translateErrorMessage(`ERROR_MESSAGES_${pureStatusCode}`);
  const endpointKey = translateErrorEnpointKey(endpoint);
  const requestType = translateErrorRequestType(requestMethod);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(location.state);
  }, [location.state]);

  return (
    <Stack direction="column" alignItems="center" justifyContent="center" sx={{ width: '100%', height: '50vh' }}>
      <CardMedia
        component="img"
        src={errorImage}
        sx={{ height: '191px', width: '145px', m: '10px 0' }}
      />
      <Typography sx={{ fontSize: '22px', fontWeight: 'bold', textAlign: 'center', m: '25px 20px 5px' }}>
        Whoops, coś poszło nie tak...
      </Typography>
      <Typography sx={{ fontSize: '15px', textAlign: 'center', m: '5px 20px', maxWidth: '350px' }}>
        Wystąpił błąd wczytywania danych. Skontaktuj się z administratorem i podaj kod błędu.
      </Typography>
      <Stack direction="row" justifyContent="center" sx={{ m: '20px 0' }}>
        <Typography sx={{ fontSize: '18px' }}>
          Kod błędu:
        </Typography>
        <Typography sx={{ fontSize: '18px', fontWeight: 'bold', ml: '7px' }}>
          {`${errorCode}-${endpointKey}-${requestType}`}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ErrorPage;

