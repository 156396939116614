import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Grid, Paper, Typography, styled } from '@mui/material';
import { RoutesVars } from '../../../const/constRoutes';
import apiCompany from '../../../api/apiCompany';
import apiRestaurant from '../../../api/apiRestaurants';
import { UserContext } from '../../../contexts/userContext/userContext';

const Stat = styled(Typography)(() => ({
  fontSize: '1.6rem',
  paddingBottom: '9px',
}));

const Title = styled(Typography)(() => ({
  fontSize: '2rem',
  fontWeight: '500',
}));

const Subtitle = styled(Typography)(() => ({
  fontSize: '1.6rem',
  paddingBottom: '27px',
}));

const AccountMainPage: React.FunctionComponent = () => {
  const { data: companiesResult } = useQuery(['companiesIndex'], apiCompany.getCompanies);
  const { data: restaurantsResult } = useQuery(['restaurantsIndex'], apiRestaurant.getRestaurants());
  const { state: { user } } = React.useContext(UserContext);

  if (user?.isEmployee) {
    return <div />; // employee page
  }

  if (user?.isRestaurantOwner) {
    return <div />; // available restaurant list
  }

  if (user?.isCompanyOwner) {
    return <div />; // available companies list
  }

  return (
    <>
      {
        restaurantsResult &&
        (
          <Grid container spacing={2} justifyContent="space-between">
            {
              restaurantsResult.map((element) => (
                <Grid item sm={12} md={6} lg={3} key={element.id}>
                  <Paper key={element.id} sx={{ width: '38rem', height: '22rem', borderRadius: '18px' }} variant="outlined">
                    <Grid container item xs={12} direction="column" alignItems="center" justifyContent="center" sx={{ padding: '15px 0 0 20px' }}>
                      <Grid container direction="column" item xs={4}>
                        <Link to={RoutesVars.RESTAURANT(element.id)}>
                          <Title>{element.name}</Title>
                        </Link>
                        <Subtitle>{`ID: ${element.id}`}</Subtitle>
                      </Grid>
                      <Grid container direction="column" item xs={8}>
                        <Stat>Liczba pracowników: 9000</Stat>
                        <Stat>Do zapłaty w tym miesiącu: 10000 zł</Stat>
                        <Stat>Liczba posiłków na następny dzień: 15</Stat>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ))
            }
          </Grid>
        )
      }
      {
        companiesResult &&
        (
          <Grid container spacing={2} justifyContent="space-between">
            {
              companiesResult.map((element) => (
                <Grid item sm={12} md={6} lg={3} key={element.id}>
                  <Paper key={element.id} sx={{ width: '38rem', height: '22rem', borderRadius: '18px' }} variant="outlined">
                    <Grid container item xs={12} direction="column" alignItems="center" justifyContent="center" sx={{ padding: '15px 0 0 20px' }}>
                      <Grid container direction="column" item xs={4}>
                        <Link to={RoutesVars.COMPANY(element.id)}>
                          <Title>{element.name}</Title>
                        </Link>
                        <Subtitle>{`ID: ${element.id}`}</Subtitle>
                      </Grid>
                      <Grid container direction="column" item xs={8}>
                        <Stat>Liczba pracowników: 9000</Stat>
                        <Stat>Do zapłaty w tym miesiącu: 10000 zł</Stat>
                        <Stat>Liczba posiłków na następny dzień: 15</Stat>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ))
            }
          </Grid>
        )
      }
    </>

  );
};

export default AccountMainPage;
