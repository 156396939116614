// #### RESTAURANT ####

export interface IdishCreateForm {
  temperature: {
    HOT: string;
    COLD: string;
    OTHER: string;
  };
  meatType: {
    MEAT: string;
    VEGAN: string;
    VEGTARIAN: string;
    OTHER: string;
  };

  categories: {
    SOUP: string;
    PASTA: string;
    DUMPLINGS: string;
    DESSERT: string;
    SINGLE: string;
    ROLL: string;
    OTHER: string;
  };
}

export const dishCreateForm: IdishCreateForm = {
  temperature: {
    HOT: 'Ciepłe',
    COLD: 'Zimne',
    OTHER: 'Inne',
  },
  meatType: {
    MEAT: 'Mięsne',
    VEGAN: 'Wegańskie',
    VEGTARIAN: 'Wegetariańskie',
    OTHER: 'Inne',
  },
  categories: {
    SOUP: 'Zupa',
    PASTA: 'Makaron',
    DUMPLINGS: 'Pierogi',
    DESSERT: 'Deser',
    SINGLE: 'Jednogarnkowe',
    ROLL: 'Bułka',
    OTHER: 'Inne',
  },
};

export const ACCESS_TOKEN = 'ohcat_access_token';
export const REFRESH_TOKEN = 'ohcat_refresh_token';
export const EXPIRES_AT = 'ohcat_access_token_expires_at';
export const TOKEN_EXPIRED_MESSAGE = 'Authentication credentials were not provided.';
