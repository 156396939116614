import React from 'react';
import { Button } from '@mui/material';
import { useCart } from 'react-use-cart';

import useMetadata from '../../../../../shared/employeeShared/hooks/useMetadata';
import { Metadata } from '../../../../../const/constEmployee';
import useDrag from '../../../../../components/common/HorizontalScrollingMenu/useDrag';

export interface DateFilterProps {
  date: string;
  itemId: string;
  setIsAlertDialogOpen: (state: boolean) => void,
}

const DateFilter: React.FunctionComponent<DateFilterProps> = ({ date, setIsAlertDialogOpen }) => {
  const { getMetadata, updateMetadata } = useMetadata();
  const { dragging } = useDrag();
  const { isEmpty } = useCart();

  const handleChangeDate = (): void => {
    !isEmpty && getMetadata(Metadata.DATE) !== date && setIsAlertDialogOpen(true);

    if (!dragging && isEmpty) {
      updateMetadata(Metadata.DATE, date);
    }
  };

  return (
    <Button
      variant={getMetadata(Metadata.DATE) === date ? 'contained' : 'outlined'}
      color="primary"
      aria-label="data dostawy"
      onClick={handleChangeDate}
      sx={{
        margin: '5px',
        borderRadius: 18,
        fontWeight: 'bold',
        minWidth: '90px',
        bgcolor: 'white',
      }}
    >
      {date}
    </Button>
  );
};
export default DateFilter;
