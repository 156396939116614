import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import AuthContext from '../../../contexts/AuthContext/AuthContext';
import { UserContext } from '../../../contexts/userContext/userContext';

type PrivateRouteProps = { children: JSX.Element };

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { actions: { fetchUser }, state: { user } } = React.useContext(UserContext);
  const auth = React.useContext(AuthContext);
  const location = useLocation();
  const isAuthenticated = auth.isAuthenticated();

  useEffect(() => {
    if (isAuthenticated && !user) fetchUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user]);

  return isAuthenticated ? children : <Navigate to="/login" state={{ from: location }} />;
};

export default PrivateRoute;
