import { styled } from '@mui/material';

const MainContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  padding: '20px',
  minHeight: '70vh',
}));

export default MainContainer;
