import React, { FC, useContext } from 'react';
import { List, Link, Typography, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { RoutesVars } from '../../../const/constRoutes';
import BaseDrawer, { DrawerLink, DrawerListItem, BaseDrawerProps } from './BaseDrawer';
import { UserContext } from '../../../contexts/userContext/userContext';

const CompanyDrawer: FC<BaseDrawerProps> = ({ isOpen, onCloseCallback }) => {
  const { state: { user }, actions } = useContext(UserContext);
  const { id } = useParams();
  const navigate = useNavigate();

  if (!id) return null;

  const handleLogoutClick = () => {
    actions.logoutUser();
    navigate(RoutesVars.LOGIN);
  };

  return (
    <BaseDrawer isOpen={isOpen} onCloseCallback={onCloseCallback}>
      <List>
        {user && user.isCompanyOwner && (
          <>
            <DrawerListItem divider key="1">
              <DrawerLink path={RoutesVars.COMPANY(id)} onClick={onCloseCallback}>
                <Typography fontSize={27}>
                  Profil firmy
                </Typography>
              </DrawerLink>
            </DrawerListItem>
            <DrawerListItem divider key="2">
              <DrawerLink path={RoutesVars.COMPANY_EMPLOYEES(id)} onClick={onCloseCallback}>
                <Typography fontSize={27}>
                  Pracownicy
                </Typography>
              </DrawerLink>
            </DrawerListItem>
            <DrawerListItem divider key="3">
              <DrawerLink path={RoutesVars.COMPANY_ORDERS(id)} onClick={onCloseCallback}>
                <Typography fontSize={27}>
                  Zamówienia
                </Typography>
              </DrawerLink>
            </DrawerListItem>
            <DrawerListItem divider key="4">
              <DrawerLink path={RoutesVars.COMPANY_ORDERS_SUMMARY(id)} onClick={onCloseCallback}>
                <Typography fontSize={27}>
                  Podsumowanie zamówień
                </Typography>
              </DrawerLink>
            </DrawerListItem>
            <DrawerListItem divider key="5">
              <DrawerLink path={RoutesVars.COMPANY_INVOICES(id)} onClick={onCloseCallback}>
                <Typography fontSize={27}>
                  Faktury rozliczeniowe
                </Typography>
              </DrawerLink>
            </DrawerListItem>
          </>
        )}
        <DrawerListItem divider key="6">
          <Link href="#top" underline="none" color="secondary" onClick={onCloseCallback}>
            <Typography fontSize={27}>
              Pomoc
            </Typography>
          </Link>
        </DrawerListItem>
      </List>
      <Button
        variant="contained"
        onClick={handleLogoutClick}
        sx={{ alignSelf: 'flex-end', borderRadius: 40, mr: 2 }}
      >
        Wyloguj się
      </Button>
    </BaseDrawer>
  );
};

export default CompanyDrawer;
