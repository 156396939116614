import React, { MouseEvent, ChangeEvent } from 'react';
import { Box, Checkbox, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { SortOrder, StyledTableCell, OrdersSummaryTableData } from './OrdersSummaryTable';

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: MouseEvent<unknown>, property: keyof OrdersSummaryTableData) => void;
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
  sortOrder: SortOrder;
  orderBy: string;
  rowCount: number;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof OrdersSummaryTableData;
  label: string;
  numeric: boolean;
}

const tableHead: readonly HeadCell[] = [
  {
    id: 'user',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'employeeName',
    numeric: false,
    disablePadding: false,
    label: 'Pracownik',
  },
  {
    id: 'id',
    numeric: true,
    disablePadding: false,
    label: 'Nr zamówienia',
  },
  {
    id: 'dateCreated',
    numeric: false,
    disablePadding: false,
    label: 'Data zamówienia',
  },
  {
    id: 'deliveryDate',
    numeric: false,
    disablePadding: false,
    label: 'Data dostarczenia',
  },
  {
    id: 'locationName',
    numeric: false,
    disablePadding: false,
    label: 'Miejsce dostawy',
  },
  {
    id: 'dueAmount',
    numeric: false,
    disablePadding: false,
    label: 'Cena zamówienia',
  },
];

export default function EnhancedTableHead(props: EnhancedTableProps): JSX.Element {
  const { onSelectAllClick, sortOrder, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof OrdersSummaryTableData) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox">
          <Checkbox
            color="secondary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </StyledTableCell>
        {tableHead.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? sortOrder : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? sortOrder : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
