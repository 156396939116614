import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { EMAIL, HELP_LINK, PHONE_NUMBER, PRIVACY_POLICY_LINK, TERMS_AND_CONDITIONS_LINK } from '../../../../const/constFooter';
import CopyrightComponent from '../../CopyrightComponent';
import { fontColors } from '../../../../thema/AppThema';
import { RoutesVars } from '../../../../const/constRoutes';

const FooterLink = styled(Link)(() => ({
  fontSize: '1.6rem',
  padding: 1,
  color: fontColors.gray,
  textDecoration: 'none',
}));

const FooterLinkUnderline = styled(Link)(() => ({
  fontSize: '1.6rem',
  padding: 1,
  color: fontColors.gray,
}));

const DesktopContent: React.FunctionComponent = () => (
  <Stack sx={{ maxWidth: '1000px', margin: 'auto', width: '100%', p: '20px 50px' }}>
    <Stack direction="column" flexGrow="1">
      <Stack>
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
          <Stack direction="column" sx={{ margin: '10px' }}>
            <Typography
              aria-label="Kontakt"
              sx={{ fontSize: '1.8rem', fontWeight: 'bold', mb: '25px' }}
            >
              Kontakt
            </Typography>

            <Stack sx={{ mb: '20px' }}>
              <Link to={RoutesVars.CONTACT} component={RouterLink} underline="none" color="secondary">
                <Typography fontSize="1.6rem" fontWeight="bold">
                  Formularz kontaktowy
                </Typography>
              </Link>
            </Stack>
            <Stack sx={{ mb: '20px' }}>
              <Typography
                aria-label="Numer Telefonu"
                sx={{ fontSize: '1.6rem', fontWeight: 'bold' }}
              >
                Telefon
              </Typography>
              <FooterLink
                href={`tel:${PHONE_NUMBER}`}
                aria-label="Kliknij aby zadzonić do nas"
              >
                +48&nbsp;725&nbsp;363&nbsp;439
              </FooterLink>
            </Stack>

            <Stack sx={{ mb: '20px' }}>
              <Typography
                aria-label="Adres e-mail"
                sx={{ fontSize: '1.6rem', fontWeight: 'bold' }}
              >
                Adres e-mail
              </Typography>
              <FooterLink
                href={`mailto:${EMAIL}`}
                aria-label="Kliknij aby wysłać do nas email"
              >
                {EMAIL}
              </FooterLink>
            </Stack>
          </Stack>

          <Stack direction="column" sx={{ margin: '10px' }}>
            <Typography
              aria-label="Centrum pomocy"
              sx={{ fontSize: '1.8rem', fontWeight: 'bold', mb: '25px' }}
            >
              Centrum pomocy
            </Typography>

            <FooterLinkUnderline
              href={HELP_LINK}
              aria-label="Przejdź do pomocy"
            >
              Pomoc
            </FooterLinkUnderline>

            <FooterLinkUnderline
              href={TERMS_AND_CONDITIONS_LINK}
              aria-label="Przejdź do regulaminu"
            >
              Regulamin
            </FooterLinkUnderline>

            <FooterLinkUnderline
              href={PRIVACY_POLICY_LINK}
              aria-label="Przejdź do polityki prywatności"
            >
              Polityka prywatności
            </FooterLinkUnderline>
          </Stack>
        </Stack>

        <Stack sx={{ mt: '20px' }}>
          <CopyrightComponent />
        </Stack>
      </Stack>
    </Stack>
  </Stack>
);

export default DesktopContent;
