import React, { FC } from 'react';
import { CardMedia, Container, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { NO_IMAGE_PLACEHOLDER_PIZZA_JPG } from '../../../../../const/constShared';
import { Tag } from '../../../../../interfaces';

interface Props {
  name: string;
  availableCuisines: string[];
  imgSrc: string;
  cuisinesList: Tag[];
}

const RestaurantItemContainer = styled(Container)(() => ({
  padding: '18px 0',
  borderBottom: '1px solid rgba(114, 113, 113, 0.178)',
  minHeight: '120px',
}));

const ListContaner = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
}));

const RestaurantItem: FC<Props> = ({ name, availableCuisines, imgSrc, cuisinesList }) => {
  const cuisinesToDisplay = cuisinesList.filter((cuisine) => availableCuisines.includes(cuisine.internalName));

  return (
    <RestaurantItemContainer>
      <Grid container direction="column" alignItems="center">
        <Grid item container xs={12}>
          <CardMedia
            component="img"
            src={imgSrc || NO_IMAGE_PLACEHOLDER_PIZZA_JPG}
            // TODO: Wprowadzić src obrazka resturacji -- placeholder jakis jak nie bedzie obrazka
            sx={{ height: '185px', width: '100%', borderRadius: '18px', backgroundColor: 'rgba(139, 138, 138, 0.158)' }}
          />
        </Grid>

        <Grid item container direction="column" xs={12}>
          <Grid item alignItems="flex-start">
            <Typography gutterBottom sx={{ fontWeight: 'bold', fontSize: 18, mt: '10px' }}>
              {name}
            </Typography>
          </Grid>

          <Grid item>
            <ListContaner>
              {cuisinesToDisplay.map(((cuisine, index) => (
                <Typography key={cuisine.internalName} component="span" sx={{ fontSize: 16 }}>
                  {index === 0 ? null : <FiberManualRecordIcon sx={{ width: '7px', height: '7px', m: '2px 5px' }} />}
                  {cuisine.name}
                </Typography>
              )))}

              {!cuisinesToDisplay.length && <Typography component="span" sx={{ fontSize: 16 }}>Brak kategorii</Typography>}
            </ListContaner>
          </Grid>
        </Grid>
      </Grid>
    </RestaurantItemContainer>
  );
};
export default RestaurantItem;
