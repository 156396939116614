import { routes } from './routes';
import { createResource, deleteResource, getResource, getResources, Result } from './axios';
import { Dish, DishInfo } from '../interfaces';

type GetDishPromise = () => Promise<Dish>;
type GetDishesPromise =() => Promise<Dish[]>;

const getDish = (id: string | number): GetDishPromise => getResource<Dish>(routes.DISH(id));
const getDishes =
    ({ id, params }: { id?: string | number, params?: unknown } = {}): GetDishesPromise => getResources<Dish>(routes.DISHES(id), params);
const createDish = createResource<Dish>(routes.DISHES());
const deleteDish = (id: string | number): Promise<Result> => deleteResource(routes.DISHES(id));

type DishOldPromise = () => Promise<DishInfo>;
const getDishOld = (id: string | number): DishOldPromise => getResource<DishInfo>(routes.DISH(id));
const getDishesOld = getResources<DishInfo>(routes.DISHES());
const createDishOld = createResource<DishInfo>(routes.DISHES());
const deleteDishOld = (id: string | number): Promise<Result> => deleteResource(routes.DISHES(id));

const requestRepository = {
  getDishes,
  getDish,
  createDish,
  deleteDish,

  getDishesOld,
  getDishOld,
  createDishOld,
  // updateDish: (dishData: IdishInfo, id: number): Promise<IdishInfo> => requests.put(`dishes/${id}/`, dishData),
  deleteDishOld,
};

export default requestRepository;
