import { Dish } from '../interfaces';

export const dish: Dish = {
  allergens: [],
  cuisine: [],
  description: '',
  id: 0,
  img: '',
  ingredients: [],
  isHidden: false,
  name: '',
  price: '',
  restaurant: 0,
};

export enum DishOperation {
  ADD = 'add',
  CREATE = 'create',
  DELETE = 'delete',
  UPDATE = 'update'
}

export enum TagCategoryMap {
  ingredient = 1,
  allergen,
  cuisine,
}

export enum TagCategories {
  INGREDIENT = 'ingredient',
  ALLERGEN = 'allergen',
  CUISINE = 'cuisine',
}

export const NO_IMAGE_PLACEHOLDER_SVG = "https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg";
export const NO_IMAGE_PLACEHOLDER_PIZZA_JPG = "https://cdn.pixabay.com/photo/2017/12/10/14/47/pizza-3010062_960_720.jpg";
